const mutations = {
  GET_PRODUCTS_REQUEST: (state) => {
    state.loading = true;
    state.success = false;
    state.error = false;
    state.productList = [];
    state.product = {};
  },
  GET_PRODUCTS_SUCCESS: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
    state.productList = data;
    state.product = {};
  },
  GET_PRODUCTS_FAILED: (state) => {
    state.loading = false;
    state.success = false;
    state.error = true;
    state.productList = [];
    state.product = {};
  },
  SET_PRODUCT_SUCCESS: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
    state.product = data;
  },
  RESET_DATA: (state) => {
    state.loading = false;
    state.success = false;
    state.error = false;
    state.productList = [];
    state.product = {};
  },
};

export default mutations;
