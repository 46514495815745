import Vue from 'vue';
import { getPackages, getPackageByID, createPackage, updatePackage, deletePackage } from '@/api/request/contentProvider';
import { uploadFile } from '@/api/request/tools';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment';

Vue.use(Cookies);

const actions = {
  GET_PACKAGES: ({ commit, dispatch }, companyID = null) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_PACKAGE_REQUEST');
    return new Promise((resolve, reject) => {
      return getPackages(token, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let packageSource = resp.data.data;

              let packages = packageSource.map((value) => {
                let data = {
                  packageID: value._id,
                  packageName: value.package_name,
                  description: value.benefits_description,
                  companyID: value.companyID || 'All',
                  dateCreated: moment(new Date(value.dateCreated)).format('YYYY-MM-DD HH:mm:SS'),
                  actions: value._id,
                };
                return data;
              });

              commit('SET_PACKAGE_SUCCESS', packages);
              resolve(packages);
            }
          }
        })
        .catch((err) => {
          commit('GET_PACKAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_PACKAGE_BY_ID: ({ commit, dispatch }, packageID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_PACKAGE_BY_ID_REQUEST');
    return new Promise((resolve, reject) => {
      return getPackageByID(token, packageID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_PACKAGE_BY_ID', resp.data.data);
              resolve(resp.data.data);
            } else {
              commit('GET_PACKAGE_BY_ID_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_PACKAGE_BY_ID_FAILED');
          reject(errors(err));
        });
    });
  },
  CREATE_PACKAGE: ({ commit, dispatch }, cpData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_PACKAGE_REQUEST');
    return new Promise((resolve, reject) => {
      return createPackage(token, cpData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_PACKAGE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_PACKAGE_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_PACKAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_PACKAGE: ({ commit, dispatch }, { formData, packageID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_PACKAGE_REQUEST');
    return new Promise((resolve, reject) => {
      return updatePackage(token, formData, packageID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_PACKAGE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_PACKAGE_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_PACKAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_PACKAGE: ({ commit, dispatch }, userID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_PACKAGE_REQUEST');
    return new Promise((resolve, reject) => {
      return deletePackage(token, userID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_PACKAGE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_PACKAGE_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_PACKAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  UPLOAD_IMAGE: ({ commit, dispatch }, imageData) => {
    const token = Cookies.get('user-token') || globalStore.token;

    return new Promise((resolve, reject) => {
      return uploadFile(token, imageData)
        .then((resp) => {})
        .catch((err) => {
          reject(errors(err));
        });
    });
  },
};

export default actions;
