const state = {
  loading: false,
  loadedOnce: false,
  usersLoadedOnce: false,
  groupLoading: false,
  success: false,
  error: false,
  groupSuccess: false,
  groupError: false,
  errorUser: false,
  errorGroup: false,
  userList: [],
  totalUsers: 0,
  documentsReturned: 0,
  groupList: [],
  selectedUser: {},
  selectedGroup: {},
  selectedUserSuccess: false,
  selectedUserError: false,
  selectedGroupSuccess: false,
  selectedGroupError: false,
  userRecoveredList: [],
  singleUserloading: false,
  singleUserSuccess: false,
  singleUserFailed: false,
};

export default state;
