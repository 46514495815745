import Vue from 'vue';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);

const state = {
  status: '',
  profileLoaded: false,
  user: {},
  fullName: '',
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  userID: '',
  companyID: '',
  company: {},
  userGroup: 0,
  contentProviderID: '',
  cpName: '',
  image: '',
  billingInfo: {},
  isChannelSubAdmin: 0,
  links: [],
  featuredLinks: false,
  loading: false,
  updateProfileLoading: false,
  registerLoading: false,
  resetPasswordStatus: false,
  resetPasswordToken: false,
  hasLoadedOnce: false,
  dashboardErrors: false,
  dashboardSuccess: false,
  getDashboardStatus: false,
  dashboard: {
    androidSubscribers: false,
    companies: false,
    coopFunds: false,
    iosSubscribers: false,
    paidSubscribers: false,
    subscribers: false,
    recoveredUsers: false,
    dashboardUsers: [],
    dashboardUsersYear: [],
  },
  userEmail: '',
  forgotPasswordToken: '',
  forgotPasswordStatus: '',
  confirmPasswordTokenStatus: '',
  menuFeatureLink: {},
  enablePrivateVideo: false,
  unregisteredMemberMessaging: false,
  inviteID: '',
  allowPushcastEmail: false,
  confirmLinkRegisterLoading: false,
  confirmLinkRegisterSuccess: false,
  confirmLinkRegisterError: false,
};

export default state;
