import Vue from 'vue';
const mutations = {
  FORGOT_PASSWORD_REQUEST: (state) => {
    state.status = 'loading';
  },
  FORGOT_PASSWORD_SUCCESS: (state, resp) => {
    state.forgotPasswordStatus = 'success';
    state.forgotPasswordToken = resp.data;
    state.hasLoadedOnce = true;
  },
  FORGOT_PASSWORD_ERROR: (state) => {
    state.forgotPasswordStatus = 'error';
    state.hasLoadedOnce = true;
  },
  USER_PROFILE_REQUEST: (state) => {
    state.status = 'loading';
    state.loading = true;
  },
  USER_PROFILE_SUCCESS: (state, data) => {
    state.status = 'success';
    state.loading = false;
    state.profileLoaded = true;
    state.fullName = data.fullName;
    state.firstname = typeof data.profile !== 'undefined' ? data.profile.firstname : '';
    state.lastname = typeof data.profile !== 'undefined' ? data.profile.lastname : '';
    state.email = data.email;
    state.stripePortal = data.stripePortal || '';
    state.phone = typeof data.phone === 'undefined' ? data.profile.phone : data.phone;
    state.userID = data._id;
    state.companyID = data.companyID || '';
    state.pricePlan = data.pricePlan || undefined;
    state.company = data.company;
    state.userGroup = data.userGroup;
    state.contentProviderID = data.contentProviderID;
    state.cpName = data.cpName || '';
    state.image = data.image || '';
    state.billingInfo = data.billingInfo || {};
    state.links = data.links || [];
    state.featuredLinks = data.featuredLinks;
    state.isChannelSubAdmin = data.isChannelSubAdmin || 0;
    state.menuFeatureLink = data.menuFeatureLink || {};
    state.enablePrivateVideo = data.enablePrivateVideo || false;
    state.unregisteredMemberMessaging = data.unregisteredMemberMessaging || false;
    state.inviteID = data.inviteID || '';
    state.allowPushcastEmail = data.allowPushcastEmail || false;
    state.purchased_off_app_text_credits_remaining = data.purchased_off_app_text_credits_remaining || 0;
    state.off_app_text_credits_remaining = data.off_app_text_credits_remaining || 0;
    state.purchased_app_users = data.purchased_app_users || 0;
    state.off_app_text_credits_month = data.off_app_text_credits_month || 0;
    state.off_app_text_credits_used_current_month = data.off_app_text_credits_used_current_month || 0;
    state.auto_app_user_bundles = data.auto_app_user_bundles || false;
    state.auto_off_app_text_credit_bundles = data.auto_off_app_text_credit_bundles || false;
    state.adminTwoFactorAuth = data.adminTwoFactorAuth || false;
    state.adminPermissions = data.adminPermissions || {};
    Vue.set(state, 'user', data);
  },
  USER_PROFILE_ERROR: (state) => {
    state.status = 'error';
    state.loading = false;
  },
  RESET_PROFILE_STATE_DATA: (state) => {
    state.status = '';
    state.user = {};
    state.fullName = '';
    state.firstname = '';
    state.lastname = '';
    state.email = '';
    state.phone = '';
    state.userID = '';
    state.companyID = '';
    state.userGroup = '';
    state.contentProviderID = '';
    state.cpName = '';
    state.image = '';
    state.billingInfo = {};
    state.links = [];
    state.featuredLinks = false;
    state.isChannelSubAdmin = 0;
    state.menuFeatureLink = {};
    state.enablePrivateVideo = false;
    state.unregisteredMemberMessaging = false;
    state.inviteID = '';
    state.allowPushcastEmail = '';
  },
  UPDATE_USER_PROFILE: (state) => {
    state.updateProfileLoading = true;
  },
  UPDATE_USER_PROFILE_SUCCESS: (state, updatedUser) => {
    state.user = updatedUser;
    state.updateProfileLoading = false;
  },
  UPDATE_USER_PROFILE_ERROR: (state, update) => {
    state.updateProfileLoading = false;
  },
  CHANGE_PASSWORD_REQUEST: (state) => {
    state.passwordChangesStatus = 'loading';
  },
  CHANGE_PASSWORD_SUCCESS: (state, resp) => {
    state.passwordChangesStatus = 'success';
    state.passwordChangesStatusToken = resp.data;
  },
  CHANGE_PASSWORD_ERROR: (state) => {
    state.passwordChangesStatus = 'error';
  },
  CONFIRM_PASSWORD_TOKEN_REQUEST: (state) => {
    state.confirmPasswordTokenStatus = 'loading';
  },
  CONFIRM_PASSWORD_TOKEN_SUCCESS: (state, resp) => {
    state.confirmPasswordTokenStatus = 'success';
  },
  CONFIRM_PASSWORD_TOKEN_ERROR: (state) => {
    state.confirmPasswordTokenStatus = 'error';
  },
  REGISTER_USER_REQUEST: (state) => {
    state.registerLoading = true;
  },
  REGISTER_USER_SUCCESS: (state) => {
    state.registerLoading = false;
  },
  REGISTER_USER_FAILED: (state) => {
    state.registerLoading = false;
  },
  RESET_PASSWORD_REQUEST: (state) => {
    state.status = 'loading';
  },
  RESET_PASSWORD_SUCCESS: (state, resp) => {
    state.resetPasswordStatus = 'success';
    state.resetPasswordToken = resp.data;
    state.hasLoadedOnce = true;
  },
  RESET_PASSWORD_ERROR: (state) => {
    state.resetPasswordStatus = 'error';
    state.hasLoadedOnce = true;
  },
  GET_USER_DASHBOARD: (state) => {
    state.getDashboardStatus = 'loading';
    state.loading = true;
    state.dashboardSuccess = true;
    state.dashboardErrors = false;
  },
  GET_USER_DASHBOARD_SUCCESS: (state, resp) => {
    state.getDashboardStatus = 'success';
    state.loading = false;
    state.hasLoadedOnce = true;
    state.dashboardSuccess = true;
    state.dashboardErrors = false;
    state.dashboard = resp;
  },
  GET_USER_DASHBOARD_ERROR: (state) => {
    state.getDashboardStatus = 'error';
    state.loading = false;
    state.hasLoadedOnce = true;
    state.dashboardSuccess = false;
    state.dashboardErrors = true;
    state.dashboard = [];
  },
  USER_UPDATE_CP: (state, cp) => {
    state.contentProviderID = cp;
  },
  CONFIRM_REGISTER_LINK_REQUEST: (state) => {
    state.confirmLinkRegisterLoading = true;
    state.confirmLinkRegisterSuccess = false;
    state.confirmLinkRegisterError = false;
  },
  CONFIRM_REGISTER_LINK_SUCCESS: (state, resp) => {
    state.confirmLinkRegisterLoading = false;
    state.confirmLinkRegisterSuccess = true;
    state.confirmLinkRegisterError = false;
  },
  CONFIRM_REGISTER_LINK_ERROR: (state) => {
    state.confirmLinkRegisterLoading = false;
    state.confirmLinkRegisterSuccess = false;
    state.confirmLinkRegisterError = true;
  },
  UPDATE_ALLOW_PUSHCAST: (state, value) => {
    state.allowPushcastEmail = value;
  },
  USER_RESET_STATE_DATA: (state) => {
    state.status = '';
    state.profileLoaded = false;
    state.user = {};
    state.fullName = '';
    state.firstname = '';
    state.lastname = '';
    state.email = '';
    state.phone = '';
    state.userID = '';
    state.companyID = '';
    state.company = {};
    state.userGroup = '';
    state.contentProviderID = '';
    state.cpName = '';
    state.image = '';
    state.billingInfo = {};
    state.links = [];
    state.featuredLinks = false;
    state.isChannelSubAdmin = 0;
    state.loading = false;
    state.updateProfileLoading = false;
    state.registerLoading = false;
    state.resetPasswordStatus = false;
    state.resetPasswordToken = false;
    state.hasLoadedOnce = false;
    state.dashboardErrors = false;
    state.dashboardSuccess = false;
    state.getDashboardStatus = false;
    state.menuFeatureLink = {};
    (state.dashboard = {
      androidSubscribers: false,
      companies: false,
      coopFunds: false,
      iosSubscribers: false,
      paidSubscribers: false,
      subscribers: false,
      recoveredUsers: false,
      dashboardUsers: [],
      dashboardUsersYear: [],
    }),
      (state.confirmLinkRegisterLoading = false);
    state.confirmLinkRegisterSuccess = false;
    state.confirmLinkRegisterError = false;
  },
};

export default mutations;
