import Vue from 'vue';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);

const state = {
  token: Cookies.get('user-token'),
  tokenExpired: false,
  isFirstTimeLogin: Cookies.get('firstTime-login') || 0,
  status: '',
  hasLoadedOnce: false,
  userEmail: '',
  forgotPasswordToken: '',
  forgotPasswordStatus: '',
  passwordChangesStatus: '',
  passwordChangesStatusToken: '',
  confirmPasswordTokenStatus: '',
};

export default state;
