import Vue from 'vue';
import {
  getGroupsByCPId,
  getMobileUsers,
  getMessages,
  sendMessage,
  sendMessageAttachment,
  sendMessageSchedule,
  sendMessageScheduleAttachment,
  deleteMessage,
  sendCampaign,
  getCampaignMessages,
  getCampaignById,
  updateCampaign,
  deleteCampaign,
  getMobileMessagesReceivers,
  getMessageSequences,
  createMessageSequence,
  createMessageSequenceAttachment,
  deleteMessageSequence,
  deleteNotificationPhoto,
  getPublicSMSMessage,
  updateStatusPublicSMSMessage,
  readPublicSMSMessage,
  feedbackStatusPublicSMSMessage,
  getEmailPushcastMessage,
} from '@/api/request/message';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment';

Vue.use(Cookies);

const actions = {
  GET_USER_GROUPS_BY_CP_ID: ({ commit, dispatch }, contentProviderID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_USER_GROUPS_REQUEST');
    return new Promise((resolve, reject) => {
      return getGroupsByCPId(token, contentProviderID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let userGroupSource = resp.data.data;

              let userGroups = userGroupSource.map((value) => {
                let data = {
                  id: value._id,
                  value: value._id,
                  name: value.name,
                  userCount: value.users,
                };
                return data;
              });
              commit('GET_USER_GROUPS_SUCCESS', userGroups);
              resolve(userGroups);
            }
          }
        })
        .catch((err) => {
          commit('GET_USER_GROUPS_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_MOBILE_USERS: ({ commit, dispatch, state }, obj) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_USERS_REQUEST');
    return new Promise((resolve, reject) => {
      return getMobileUsers(
        token,
        obj.companyID,
        obj.offset,
        obj.objectId,
        obj.sortDirection,
        obj.sortField,
        obj.userType,
        obj.userStatus,
        obj.groups,
        obj.CRFs,
        obj.searchString,
        obj.platForm,
        obj.selectionType
      )
        .then((resp) => {
          if (resp && resp.data) {
            let userListSource = resp.data.data.users;
            const totalFilteredUsers = resp.data.data.totalFilteredUsers;
            const totalUsers = resp.data.data.totalUsers;
            const registeredCount = resp.data.data.registeredCount;
            const unregisteredCount = resp.data.data.unregisteredCount;
            const activeCount = resp.data.data.activeCount;
            const suspendedCount = resp.data.data.suspendedCount;
            const usersForMessagingCount = resp.data.data.usersForMessagingCount;
            const registeredUsersForMessagingCount = resp.data.data.registeredUsersForMessagingCount;
            const unregisteredUsersForMessagingCount = resp.data.data.unregisteredUsersForMessagingCount;

            var groupList = state.selectedGroups.map((group) => {
              return group.id;
            });
            var userList = userListSource.map((value) => {
              value.groups = value.groups.map((group) => {
                return group._id;
              });

              // this is confusing, this is a user object and we are setting the id to the userid,
              // it should stay as user id
              let data = {
                id: value.userid,
                dateCreated: value.dateCreated,
                // duplicate dont want to break old functionality
                userid: value.userid,
                group: value.groups,
                phone: value.phone,
                name: value.name,
                email: value.email,
                platform: value.platForm,
                customFields: value.customFields,
                registered: value.registered,
              };
              return data;
            });

            const docs_returned = userList.length;
            let copy = { ...state.userList };

            if (Object.keys(copy).length === 0) {
              commit('SET_USERS_SUCCESS', {
                list: userList,
                docs_returned: docs_returned,
                totalFilteredUsers,
                totalUsers,
                registeredCount,
                unregisteredCount,
                activeCount,
                suspendedCount,
                usersForMessagingCount,
                registeredUsersForMessagingCount,
                unregisteredUsersForMessagingCount,
              });
              resolve(userList);
            } else {
              copy.push(...userList);
              commit('SET_USERS_SUCCESS', {
                list: copy,
                docs_returned: docs_returned,
                totalFilteredUsers,
                totalUsers,
                registeredCount,
                unregisteredCount,
                activeCount,
                suspendedCount,
                usersForMessagingCount,
                registeredUsersForMessagingCount,
                unregisteredUsersForMessagingCount,
              });
              resolve(copy);
            }
          }
        })
        .catch((err) => {
          console.error(err);
          commit('GET_USERS_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_MESSAGES: ({ commit, dispatch }, companyID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_MESSAGES_REQUEST');
    return new Promise((resolve, reject) => {
      return getMessages(token, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let messageData = resp.data.data;
              let newMessageList = {};
              const daysAbbrevation = ['', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

              let messages = messageData.map((value) => {
                var days = value.scheduleType === 'time' ? (value.schedule_days !== undefined ? value.schedule_days : value.schedule_date) : '';

                if (days !== '') {
                  var daysItems = days.split(',');
                  var daysItemFinal = daysItems.map((dayValue) => {
                    return daysAbbrevation[Number(dayValue)];
                  });
                }

                let data = {
                  id: value._id,
                  type: value.type == 'Regular Message' ? 'PushCast' : value.type == 'AwardNotification' ? 'Award' : value.type,
                  title: value.title,
                  pushMessage: value.type == 'Regular Message' ? value.push_message : value.message,
                  message: value.message || value.push_message,
                  created: value.dateCreated,
                  createdYmd: moment(new Date(value.dateCreated)).format('YYYY-MM-DD'),
                  createdHms: moment(new Date(value.dateCreated)).format('HH:mm:SS'),
                  target: value.device.toUpperCase(),
                  sent: value.sent ? value.sent : 0,
                  read: value.read ? value.read : 0,
                  phone: value.phone,
                  url: value.url,
                  isReadRequired: value.isReadRequired || 0,
                  enableFeedback: value.enableFeedback || 0,
                  htmlFile: value.html_file,
                  isScheduled: value.scheduleType ? true : false,
                  scheduleType: value.scheduleType || '',
                  scheduledDate: value.schedule_date !== undefined ? value.schedule_date : '',
                  scheduledTime: value.schedule_time + ':00',
                  schedule_days: value.schedule_days !== undefined ? value.schedule_days : '',
                  schedule_end_date: value.schedule_time,
                  schedule_weeks_count: value.schedule_time,
                  days: value.scheduleType === 'time' ? daysItemFinal.join(' ') : '',
                  status: value.status !== undefined ? (value.status === 1 ? 'active' : 'inactive') : '',
                  actions: value._id,
                  isExternal: value.isExternal,
                };
                return data;
              });

              messages = messages.sort(function (a, b) {
                return new Date(b.created) - new Date(a.created);
              });

              newMessageList['all'] = messages || [];
              newMessageList['awardNotification'] = Vue.lodash.filter(messages, { type: 'Award' }) || [];
              newMessageList['dailyAward'] = Vue.lodash.filter(messages, { type: 'DailyAward' }) || [];
              newMessageList['discount'] = Vue.lodash.filter(messages, { type: 'Discount' }) || [];
              newMessageList['pushcast'] = Vue.lodash.filter(messages, { type: 'PushCast' }) || [];
              newMessageList['vocast'] = Vue.lodash.filter(messages, { type: 'Vocast' }) || [];
              newMessageList['scheduled'] = Vue.lodash.filter(messages, { isScheduled: true }) || [];
              newMessageList['vidcast'] = Vue.lodash.filter(messages, { type: 'Vidcast' }) || [];

              commit('SET_MESSAGES_SUCCESS', newMessageList);
              resolve(newMessageList);
            }
          }
        })
        .catch((err) => {
          commit('GET_MESSAGES_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_MOBILE_MESSAGES_RECEIVER: ({ commit, dispatch }, messageID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_MOBILE_MESSAGES_RECEIVER');
    return new Promise((resolve, reject) => {
      return getMobileMessagesReceivers(token, messageID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let messageData = resp.data.data;
              let newMessageList = {};

              let messages = messageData.map((value) => {
                let status = '';
                let statusClass = '';
                let feedbackStatus = '';
                let feedbackStatusClass = '';

                var read = value.is_read || 0;
                var readResponse = value.readResponse || 0;
                var feedback = value.feedback;
                var feedbackComment = value.feedbackComment || '';

                if (read === 1 && readResponse === 0) {
                  status = 'opened/unconfirmed';
                  statusClass = 'bg-warning';
                } else if (read === 1 && readResponse === 1) {
                  status = 'opened/confirmed';
                  statusClass = 'bg-success';
                } else if (read === 0 && readResponse === 0) {
                  status = 'not opened';
                  statusClass = 'bg-danger';
                }

                if (read === 1 && feedback === null) {
                  feedbackStatus = 'opened/uncommited';
                  feedbackStatusClass = 'bg-warning';
                } else if (read === 0 && feedback === null) {
                  feedbackStatus = 'not opened';
                  feedbackStatusClass = 'bg-danger';
                } else if (read === 1 && feedback === -1) {
                  feedbackStatus = 'opened/uncommited';
                  feedbackStatusClass = 'bg-warning';
                }

                if (read === 1 && feedback === 1) {
                  feedbackStatus = 'opened/yes';
                  feedbackStatusClass = 'bg-success';
                }

                if (read === 1 && feedback === 0) {
                  feedbackStatus = 'opened/no';
                  feedbackStatusClass = 'bg-vobo';
                }

                let data = {
                  id: value._id,
                  name: value.userInfo.fullName || '',
                  email: value.userInfo.email || '',
                  type: value.type == 'Regular Message' ? 'PushCast' : value.type == 'AwardNotification' ? 'Award' : value.type,
                  created: value.dateCreated,
                  createdYmd: moment(new Date(value.dateCreated)).format('YYYY-MM-DD'),
                  createdHms: moment(new Date(value.dateCreated)).format('HH:mm:SS'),
                  read: value.is_read || 0,
                  readResponse: value.readResponse || 0,
                  isReadRequired: value.isReadRequired || 0,
                  status: status,
                  statusClass: statusClass,
                  feedback: value.feedback,
                  feedbackStatus: feedbackStatus,
                  feedbackStatusClass: feedbackStatusClass,
                  comment: value.feedbackComment,
                  actions: value._id,
                };
                return data;
              });

              messages = messages.sort(function (a, b) {
                return new Date(b.created) - new Date(a.created);
              });

              newMessageList['all'] = messages || [];
              newMessageList['opened-unconfirmed'] = Vue.lodash.filter(messages, { read: 1, readResponse: 0 }) || [];
              newMessageList['opened-confirmed'] = Vue.lodash.filter(messages, { read: 1, readResponse: 1 }) || [];
              newMessageList['notopened'] = Vue.lodash.filter(messages, { read: 0, readResponse: 0 }) || [];

              newMessageList['opened-yes'] =
                messages.filter((value) => {
                  return value.read === 1 && value.feedback == 1;
                }) || [];
              newMessageList['opened-no'] =
                messages.filter((value) => {
                  return value.read === 1 && value.feedback == 0;
                }) || [];
              newMessageList['opened-uncommitted'] =
                messages.filter((value) => {
                  return (value.read === 1 && value.feedback == null) || (value.read === 1 && value.feedback == -1);
                }) || [];
              newMessageList['feedback-notopened'] =
                messages.filter((value) => {
                  return value.read === 0 && value.feedback == null;
                }) || [];

              newMessageList['count'] = {
                openedYes: newMessageList['opened-yes'].length,
                openedno: newMessageList['opened-no'].length,
                openedUncommited: newMessageList['opened-uncommitted'].length,
                openedNotOpened: newMessageList['feedback-notopened'].length,
              };

              commit('SET_MOBILE_MESSAGES_SUCCESS_RECEIVER', newMessageList);
              resolve(newMessageList);
            }
          }
        })
        .catch((err) => {
          commit('GET_MOBILE_MESSAGES_FAILED_RECEIVER');
          reject(errors(err));
        });
    });
  },
  SEND_MESSAGE: ({ commit, dispatch }, messageData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('SEND_MESSAGE_REQUEST');
    return new Promise((resolve, reject) => {
      return sendMessage(token, messageData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SEND_MESSAGE_SUCCESS', resp.data);
              resolve(resp);
            }
          }
        })
        .catch((err) => {
          commit('SEND_MESSAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  SEND_MESSAGE_ATTACHMENT: ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('SEND_MESSAGE_REQUEST');
    return new Promise((resolve, reject) => {
      return sendMessageAttachment(token, formData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SEND_MESSAGE_SUCCESS', resp.data);
              resolve(resp);
            }
          }
        })
        .catch((err) => {
          commit('SEND_MESSAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  SEND_MESSAGE_SCHEDULE: ({ commit, dispatch }, messageData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('SEND_MESSAGE_REQUEST');
    return new Promise((resolve, reject) => {
      return sendMessageSchedule(token, messageData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SEND_MESSAGE_SUCCESS', resp.data);
              resolve(resp);
            }
          }
        })
        .catch((err) => {
          commit('SEND_MESSAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  SEND_MESSAGE_SCHEDULE_ATTACHMENT: ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('SEND_MESSAGE_REQUEST');
    return new Promise((resolve, reject) => {
      return sendMessageScheduleAttachment(token, formData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SEND_MESSAGE_SUCCESS', resp.data);
              resolve(resp);
            }
          }
        })
        .catch((err) => {
          commit('SEND_MESSAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_MESSAGE: ({ commit, dispatch }, messageID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('SEND_MESSAGE_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteMessage(token, messageID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SEND_MESSAGE_SUCCESS');
              resolve(resp);
            } else {
              commit('SEND_MESSAGE_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('SEND_MESSAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  SEND_CAMPAIGN: ({ commit, dispatch }, { campaignData, companyID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('SEND_MESSAGE_REQUEST');
    return new Promise((resolve, reject) => {
      return sendCampaign(token, campaignData, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SEND_MESSAGE_SUCCESS', resp.data);
              resolve(resp);
            }
          }
        })
        .catch((err) => {
          commit('SEND_MESSAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_CAMPAIGN_MESSAGES: ({ commit, dispatch }, companyID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_CAMPAIGN_REQUEST');
    return new Promise((resolve, reject) => {
      return getCampaignMessages(token, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let campaignData = resp.data.data;
              let campaigns = campaignData.map((value) => {
                let data = {
                  id: value._id,
                  schedule: value.repeat == 1 ? 'Everyday @' + value.schedule_time : value.schedule_date + ' ' + value.schedule_time,
                  target: value.target.length == 2 ? 'ALL' : value.target[0].toUpperCase(),
                  phone: value.phone,
                  url: value.url,
                  status: value.status == 1 ? 'active' : 'done',
                  repeat: value.repeat == 1 ? 'TRUE' : 'FALSE',
                  sent: value.sent ? value.sent : 0,
                  read: value.read ? value.read : 0,
                  dateCreated: value.dateCreated,
                  actions: value._id,
                };
                return data;
              });

              campaigns = campaigns.sort(function (a, b) {
                return new Date(b.dateCreated) - new Date(a.dateCreated);
              });

              commit('GET_CAMPAIGN_SUCCESS', campaigns);
              resolve(campaigns);
            }
          }
        })
        .catch((err) => {
          commit('GET_CAMPAIGN_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_CAMPAIGN_BY_ID: ({ commit, dispatch }, campaignID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_CAMPAIGN_REQUEST');
    return new Promise((resolve, reject) => {
      return getCampaignById(token, campaignID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let campaignData = resp.data.data;
              let campaigns = {
                id: campaignData._id,
                schedule: campaignData.schedule_date + ' ' + campaignData.schedule_time,
                target: campaignData.target.length == 2 ? 'ALL' : campaignData.target[0].toUpperCase(),
                phone: campaignData.phone,
                url: campaignData.url,
                status: campaignData.status == 1 ? 'active' : 'done',
                repeat: campaignData.repeat == 1 ? 'TRUE' : 'FALSE',
                actions: campaignData._id,
              };

              commit('GET_CAMPAIGN_SUCCESS', campaigns);
              resolve(campaigns);
            }
          }
        })
        .catch((err) => {
          commit('GET_CAMPAIGN_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_CAMPAIGN: ({ commit, dispatch }, { campaignData, campaignID, companyID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('SEND_MESSAGE_REQUEST');
    return new Promise((resolve, reject) => {
      return updateCampaign(token, campaignData, campaignID, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SEND_MESSAGE_SUCCESS', resp.data);
              resolve(resp);
            }
          }
        })
        .catch((err) => {
          commit('SEND_MESSAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_CAMPAIGN: ({ commit, dispatch }, campaignID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('SEND_MESSAGE_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteCampaign(token, campaignID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SEND_MESSAGE_SUCCESS');
              resolve(resp);
            } else {
              commit('SEND_MESSAGE_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('SEND_MESSAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_MESSAGE_SEQUENCES: ({ commit, dispatch }, companyID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_MESSAGE_SEQUENCES_REQUEST');
    return new Promise((resolve, reject) => {
      return getMessageSequences(token, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let messageData = resp.data.data;
              let messageSequences = {};

              let messages = messageData.map((value) => {
                let data = {
                  id: value._id,
                  label: value.label,
                  interval: value.interval,
                  module: value.module,
                  title: value.title,
                  pushMessage: value.pushMessage,
                  message: value.message || value.pushMessage,
                  isActive: value.isActive,
                  created: value.dateCreated,
                  createdYmd: moment(new Date(value.dateCreated)).format('YYYY-MM-DD'),
                  createdHms: moment(new Date(value.dateCreated)).format('HH:mm:SS'),
                  htmlFile: value.html_file,
                  actions: value._id,
                };
                return data;
              });

              messageSequences = messages.sort(function (a, b) {
                return new Date(b.created) - new Date(a.created);
              });

              commit('GET_MESSAGE_SEQUENCES_SUCCESS', messageSequences);
              resolve(messageSequences);
            }
          }
        })
        .catch((err) => {
          commit('GET_MESSAGE_SEQUENCES_FAILED');
          reject(errors(err));
        });
    });
  },
  CREATE_MESSAGE_SEQUENCE: ({ commit, dispatch }, messageData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_MESSAGE_SEQUENCES_REQUEST');
    return new Promise((resolve, reject) => {
      return createMessageSequence(token, messageData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('GET_MESSAGE_SEQUENCES_SUCCESS', resp.data);
              resolve(resp);
            }
          }
        })
        .catch((err) => {
          commit('GET_MESSAGE_SEQUENCES_FAILED');
          reject(errors(err));
        });
    });
  },
  CREATE_MESSAGE_SEQUENCE_ATTACHMENT: ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_MESSAGE_SEQUENCES_REQUEST');
    return new Promise((resolve, reject) => {
      return createMessageSequenceAttachment(token, formData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('GET_MESSAGE_SEQUENCES_SUCCESS', resp.data);
              resolve(resp);
            }
          }
        })
        .catch((err) => {
          commit('GET_MESSAGE_SEQUENCES_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_MESSAGE_SEQUENCE: ({ commit, dispatch }, messageID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_MESSAGE_SEQUENCES_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteMessageSequence(token, messageID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('GET_MESSAGE_SEQUENCES_SUCCESS');
              resolve(resp);
            } else {
              commit('GET_MESSAGE_SEQUENCES_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_MESSAGE_SEQUENCES_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_NOTIFICATION_IMAGE: ({ commit, dispatch }, filename) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('DELETE_NOTIFICATION_IMAGE_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteNotificationPhoto(token, filename)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('DELETE_NOTIFICATION_IMAGE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('DELETE_NOTIFICATION_IMAGE_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('DELETE_NOTIFICATION_IMAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_SMS_MESSAGE: ({ commit, dispatch }, token) => {
    return new Promise((resolve, reject) => {
      return getPublicSMSMessage(token)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('GET_SMS_MESSAGE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_SMS_MESSAGE_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_SMS_MESSAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_SMS_MESSAGE_STATUS: ({ commit, dispatch }, messageID) => {
    return new Promise((resolve, reject) => {
      return updateStatusPublicSMSMessage(messageID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('GET_SMS_MESSAGE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_SMS_MESSAGE_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_SMS_MESSAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_SMS_MESSAGE_FEEDBACK: ({ commit, dispatch }, messageData) => {
    return new Promise((resolve, reject) => {
      return feedbackStatusPublicSMSMessage(messageData.messageID, messageData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('GET_SMS_MESSAGE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_SMS_MESSAGE_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_SMS_MESSAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_SMS_MESSAGE_READ: ({ commit, dispatch }, messageID) => {
    return new Promise((resolve, reject) => {
      return readPublicSMSMessage(messageID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('GET_SMS_MESSAGE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_SMS_MESSAGE_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_SMS_MESSAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_EMAIL_PUSHCAST: ({ commit, dispatch }, token) => {
    return new Promise((resolve, reject) => {
      commit('GET_EMAIL_PUSHCAST_REQUEST');
      return getEmailPushcastMessage(token)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('GET_EMAIL_PUSHCAST_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_EMAIL_PUSHCAST_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_EMAIL_PUSHCAST_FAILED');
          reject(errors(err));
        });
    });
  },
};

export default actions;
