import Vue from 'vue';
import $http from '../utils/http';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);

const getUnionLinks = (token, companyID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/union-link/${companyID}`, null, 'GET', header);
};

const getUnionLinkByID = (token, packageID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/union-link/link/${packageID}`, null, 'GET', header);
};

const createUnionLink = (token, packageData) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/union-link`, packageData, 'POST', header);
};

const updateUnionLink = (token, packageData, packageID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/union-link/link/${packageID}`, packageData, 'PUT', header);
};

const deleteUnionLink = (token, packageID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/union-link/${packageID}`, null, 'DELETE', header);
};

const getLinkTypes = (token, contentProviderID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/union-link/linktype/${contentProviderID}`, null, 'GET', header);
};

const createLinkType = (token, linkTypeData, contentProviderID) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/union-link/linktype/${contentProviderID}`, linkTypeData, 'POST', header);
};

const updateLinkType = (token, linkTypeData, linkTypeID) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/union-link/linktype/${linkTypeID}`, linkTypeData, 'PUT', header);
};

const updateLinkTypeOrder = (token, linkTypeData, contentProviderID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/union-link/linktype/order/${contentProviderID}`, linkTypeData, 'PUT', header);
};

const deleteLinkType = (token, linkTypeID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/union-link/linktype/${linkTypeID}`, null, 'DELETE', header);
};

export {
  getUnionLinks,
  getUnionLinkByID,
  createUnionLink,
  updateUnionLink,
  deleteUnionLink,
  getLinkTypes,
  createLinkType,
  updateLinkType,
  deleteLinkType,
  updateLinkTypeOrder,
};
