import Vue from 'vue';
import $http from '../utils/http';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);

const getSettings = (token, companyID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/company-settings/${companyID}`, null, 'GET', header);
};

const updateSettings = (token, data) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/company-settings`, data, 'POST', header);
};

const updateSettingsFile = (token, formData) => {
  const header = token ? { authorization: token, 'Content-Type': 'multipart/form-data', 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/company-settings`, formData, 'POST', header);
};

const updateFeaturedLinks = (token, formData) => {
  const header = token ? { authorization: token, 'Content-Type': 'multipart/form-data', 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/company-settings/featured-links`, formData, 'PUT', header);
};

export { getSettings, updateSettings, updateSettingsFile, updateFeaturedLinks };
