import Vue from 'vue';
import $http from '../utils/http';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);

const getGroupsByCPId = (token, contentProviderID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/groups/${contentProviderID}/0`, null, 'GET', header);
};

const getGroupsByAllCPId = (token) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/groups/`, null, 'GET', header);
};

const getGroupsById = (token, groupID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/groups/${groupID}`, null, 'GET', header);
};

const createGroup = (token, groupData) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/groups/`, groupData, 'POST', header);
};

const updateGroup = (token, groupData, groupID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/groups/${groupID}`, groupData, 'PUT', header);
};

const deleteGroup = (token, groupID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/groups/${groupID}`, null, 'DELETE', header);
};

const bulkManageUsersInGroup = (token, data) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/groups/bulk-manage-users`, data, 'POST', header);
};

const getMobileUsers = (
  token,
  companyID,
  offset,
  objectId,
  sortDirection,
  sortField,
  userType,
  userStatus,
  groups,
  CRFs,
  searchString,
  skipAmount,
  selectionType
) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(
    `/user/mobile-users/new`,
    {
      companyID: companyID,
      offset: offset,
      objectId: objectId,
      sortDirection: sortDirection,
      sortField: sortField,
      userType: userType,
      userStatus: userStatus,
      groups: groups,
      CRFs: CRFs,
      searchString: searchString,
      onlyActiveUsers: false,
      skipAmount: skipAmount,
      selectionType: selectionType,
    },
    'POST',
    header
  );
};

const downloadCsv = (token, data) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  const downloadFileOption = { responseType: 'blob' };
  return $http(`/user/mobile-users/download-csv`, data, 'POST', header, undefined, downloadFileOption);
};

const downloadMessageCsv = (token, data) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  const downloadFileOption = { responseType: 'blob' };
  return $http(`/user/mobile-users/download-message-audit`, data, 'POST', header, undefined, downloadFileOption);
};

const updateMobileUsers = (token, action, formData, userID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/mobile-users/${action}/${userID}`, formData, 'PUT', header);
};

const deleteMobileUser = (token, userID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/mobile-users/${userID}`, '', 'DELETE', header);
};

const getMobileRecoveredUsers = (token, companyID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/mobile-users/recovered/${companyID}`, null, 'GET', header);
};

const uploadUsers = (token, userData) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/upload`, userData, 'POST', header);
};

const deleteUploadedUsers = (token, userid) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/upload/delete`, { userid }, 'POST', header);
};

const getUnionActiveUserList = (api) => {
  return $http('/user/union-active/get-users-list', api, 'POST', null);
};

const getUnionActiveResourceGroupList = (api) => {
  return $http('/user/union-active/get-resource-group-list', api, 'POST', null);
};

// Body contains api ob
const uaSync = (api, companyID, cpID, userGroup, currentUserID) => {
  return $http('/user/union-active/sync', { api, companyID, cpID, userGroup, currentUserID }, 'POST', null);
};

const getMobileUsersUaCheck = (token, userType, userStatus, groups, companyID, platForm = '', uaUsers, uaUsersNotSaved, uaUsersNotInUA) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;

  return $http(
    '/user/mobile-users/ua-check',
    {
      userType,
      userStatus,
      groups,
      companyID,
      platForm,
      uaUsers,
      uaUsersNotSaved,
      uaUsersNotInUA,
    },
    'POST',
    header
  );
};

const toggleIM = (token, userID, toggle) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/mobile-users/toggleIM/${userID}/${toggle}`, null, 'POST', header);
};

export {
  getGroupsByCPId,
  getGroupsByAllCPId,
  getGroupsById,
  createGroup,
  updateGroup,
  deleteGroup,
  getMobileUsers,
  updateMobileUsers,
  deleteMobileUser,
  getMobileRecoveredUsers,
  uploadUsers,
  deleteUploadedUsers,
  getUnionActiveUserList,
  getMobileUsersUaCheck,
  getUnionActiveResourceGroupList,
  uaSync,
  downloadCsv,
  toggleIM,
  downloadMessageCsv,
  bulkManageUsersInGroup,
};
