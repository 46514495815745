<template>
  <ContentLoader v-if="userGroup === 1 || userGroup === 4" :speed="2" :width="220" :height="620" primary-color="#f0f3f5" secondary-color="#d0dbe1">
    <rect x="9" y="-14" rx="3" ry="3" width="410" height="6" />
    <rect x="2" y="15" rx="3" ry="3" width="219" height="4" />
    <circle cx="17" cy="63" r="13" />
    <rect x="6" y="24" rx="0" ry="0" width="65" height="11" />
    <rect x="40" y="48" rx="0" ry="0" width="167" height="33" />
    <rect x="2" y="93" rx="3" ry="3" width="219" height="4" />
    <rect x="7" y="103" rx="0" ry="0" width="65" height="11" />
    <circle cx="18" cy="142" r="13" />
    <rect x="39" y="125" rx="0" ry="0" width="167" height="33" />
    <rect x="40" y="174" rx="0" ry="0" width="167" height="33" />
    <circle cx="20" cy="191" r="13" />
    <circle cx="20" cy="242" r="13" />
    <rect x="40" y="224" rx="0" ry="0" width="167" height="33" />
    <rect x="5" y="274" rx="3" ry="3" width="219" height="4" />
    <rect x="10" y="284" rx="0" ry="0" width="65" height="11" />
    <circle cx="21" cy="323" r="13" />
    <rect x="42" y="306" rx="0" ry="0" width="167" height="33" />
    <rect x="43" y="355" rx="0" ry="0" width="167" height="33" />
    <circle cx="23" cy="372" r="13" />
    <circle cx="23" cy="423" r="13" />
    <rect x="43" y="405" rx="0" ry="0" width="167" height="33" />
    <rect x="9" y="449" rx="3" ry="3" width="219" height="4" />
    <rect x="14" y="459" rx="0" ry="0" width="65" height="11" />
    <circle cx="25" cy="498" r="13" />
    <rect x="46" y="481" rx="0" ry="0" width="167" height="33" />
    <rect x="47" y="530" rx="0" ry="0" width="167" height="33" />
    <circle cx="27" cy="547" r="13" />
    <circle cx="27" cy="598" r="13" />
    <rect x="47" y="580" rx="0" ry="0" width="167" height="33" />
  </ContentLoader>

  <ContentLoader v-else-if="userGroup === 2" :speed="2" :width="220" :height="620" primary-color="#f0f3f5" secondary-color="#d0dbe1">
    <rect x="9" y="-14" rx="3" ry="3" width="410" height="6" />
    <rect x="2" y="15" rx="3" ry="3" width="219" height="4" />
    <circle cx="22" cy="63" r="13" />
    <rect x="6" y="24" rx="0" ry="0" width="65" height="11" />
    <rect x="40" y="48" rx="0" ry="0" width="167" height="33" />
    <rect x="2" y="93" rx="3" ry="3" width="219" height="4" />
    <rect x="7" y="103" rx="0" ry="0" width="65" height="11" />
    <circle cx="20" cy="142" r="13" />
    <rect x="39" y="125" rx="0" ry="0" width="167" height="33" />
    <rect x="40" y="174" rx="0" ry="0" width="167" height="33" />
    <circle cx="20" cy="189" r="13" />
    <circle cx="22" cy="288" r="13" />
    <rect x="40" y="272" rx="0" ry="0" width="167" height="33" />
    <rect x="5" y="316" rx="3" ry="3" width="219" height="4" />
    <rect x="10" y="326" rx="0" ry="0" width="65" height="11" />
    <circle cx="21" cy="365" r="13" />
    <rect x="42" y="348" rx="0" ry="0" width="167" height="33" />
    <rect x="43" y="397" rx="0" ry="0" width="167" height="33" />
    <circle cx="23" cy="414" r="13" />
    <circle cx="23" cy="465" r="13" />
    <rect x="43" y="447" rx="0" ry="0" width="167" height="33" />
    <rect x="9" y="491" rx="3" ry="3" width="219" height="4" />
    <rect x="14" y="501" rx="0" ry="0" width="65" height="11" />
    <circle cx="25" cy="540" r="13" />
    <rect x="46" y="523" rx="0" ry="0" width="167" height="33" />
    <rect x="47" y="572" rx="0" ry="0" width="167" height="33" />
    <circle cx="27" cy="589" r="13" />
    <circle cx="27" cy="640" r="13" />
    <rect x="47" y="622" rx="0" ry="0" width="167" height="33" />
    <circle cx="22" cy="240" r="13" />
    <rect x="40" y="222" rx="0" ry="0" width="167" height="33" />
  </ContentLoader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'SidebarLoader',
  components: {
    ContentLoader,
  },
  props: {
    userGroup: {
      type: Number,
      description: 'User Group of current user',
      default: 1,
    },
  },
};
</script>
