import Vue from 'vue';
import $http from '../utils/http';
import Cookies from 'js-cookie';

Vue.use(Cookies);

const getPricePlanById = (token, id) => {
  const header = token ? { authorization: token } : null;
  return $http(`/price_plans/plan/${id}`, null, 'GET', header);
};

const getPricePlanList = (token, company_id) => {
  const header = token ? { authorization: token } : null;
  return $http(`/price_plans/${company_id}`, null, 'GET', header);
};

const createPricePlan = (token, data) => {
  const header = token ? { 'Content-Type': 'application/json', authorization: token } : null;
  return $http(`/price_plans`, data, 'POST', header);
};

const deletePricePlan = (token, id) => {
  const header = token ? { authorization: token } : null;
  return $http(`/price_plans/${id}`, '', 'DELETE', header);
};

const updatePricePlan = (token, id, data) => {
  const header = token ? { 'Content-Type': 'application/json', authorization: token } : null;
  return $http(`/price_plans/${id}`, data, 'PUT', header);
};

export { getPricePlanList, createPricePlan, deletePricePlan, updatePricePlan, getPricePlanById };
