import AudioPlayer from '@/components/AudioPlayer';
import BaseInput from '@/components/Inputs/BaseInput';
import BaseDropdown from '@/components/BaseDropdown';
import Card from '@/components/Cards/Card';
import Modal from '@/components/Modal';
import StatsCard from '@/components/Cards/StatsCard';
import BaseButton from '@/components/BaseButton';
import Badge from '@/components/Badge';
import BaseCheckbox from '@/components/Inputs/BaseCheckbox';
import BaseSwitch from '@/components/BaseSwitch';
import BaseRadio from '@/components/Inputs/BaseRadio';
import BaseProgress from '@/components/BaseProgress';
import BasePagination from '@/components/BasePagination';
import BaseAlert from '@/components/BaseAlert';
import BaseNav from '@/components/Navbar/BaseNav';
import BaseHeader from '@/components/BaseHeader';
import { Input } from 'element-ui';

import Breadcrumb from '@/components/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '@/components/Breadcrumb/BreadcrumbItem';
import RouteBreadcrumb from '@/components/Breadcrumb/RouteBreadcrumb';

import SideBar from '@/components/SidebarPlugin';

import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import BounceLoader from 'vue-spinner/src/BounceLoader.vue';

import Drawer from '@/components/Drawer';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import Multiselect from 'vue-multiselect';

var plugins = {
  'bread-crumb-bar': Breadcrumb,
  'bread-crumb-item': BreadcrumbItem,
  drawer: Drawer,
  'route-bread-crumb': RouteBreadcrumb,
  badge: Badge,
  'pulse-loader': PulseLoader,
  'scale-loader': ScaleLoader,
  'clip-loader': ClipLoader,
  'bounce-loader': BounceLoader,
  'datetime-picker': VueCtkDateTimePicker,
  multiselect: Multiselect,
};

plugins[AudioPlayer.name] = AudioPlayer;
plugins[BaseAlert.name] = BaseAlert;
plugins[BaseButton.name] = BaseButton;
plugins[BaseCheckbox.name] = BaseCheckbox;
plugins[BaseHeader.name] = BaseHeader;
plugins[BaseInput.name] = BaseInput;
plugins[BaseDropdown.name] = BaseDropdown;
plugins[BaseNav.name] = BaseNav;
plugins[BasePagination.name] = BasePagination;
plugins[BaseProgress.name] = BaseProgress;
plugins[BaseRadio.name] = BaseRadio;
plugins[BaseSwitch.name] = BaseSwitch;
plugins[Card.name] = Card;
plugins[Modal.name] = Modal;
plugins[StatsCard.name] = StatsCard;
plugins[Input.name] = Input;

const registers = {
  install(Vue, options) {
    for (var key in plugins) {
      Vue.component(key, plugins[key]);
    }
  },
};

export default registers;
