import Vue from 'vue';
import { getAdminUsers, getAdminUserByID, updateAdminUser, deleteAdminUser } from '@/api/request/admins';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment';

Vue.use(Cookies);

const actions = {
  GET_ADMIN_USERS: ({ commit, dispatch }, companyID = null) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_ADMIN_USER_REQUEST');
    return new Promise((resolve, reject) => {
      return getAdminUsers(token, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let adminUsersSource = resp.data;
              // let adminUsersSource = Vue.lodash.filter(resp.data, { isDeleted: 0 })

              /*for( var i = 0; i < adminUsersSource.length; i++){
                // console.log(adminUsersSource[i]._id)
                 if ( adminUsersSource[i]._id === currentUserID) {
                   adminUsersSource.splice(i, 1);
                 }
              }*/

              let newAdminUsers = {};
              let userGroups = {
                1: 'Super Admin',
                4: 'Admin',
                5: 'Channel Admin',
                2: 'Channel Admin',
              };

              let adminUsers = adminUsersSource.map((value) => {
                let status = '';
                let statusClass = '';
                if (value.isActive === 1) {
                  status = 'active';
                  statusClass = 'bg-success';
                } else if (value.isActive === 0) {
                  status = 'inactive';
                  statusClass = 'bg-danger';
                }

                return {
                  userID: value._id,
                  fullName: value.fullName,
                  email: value.email,
                  status: { statusClass: statusClass, status: status },
                  companyID: value.companyID || 'All',
                  dateCreated: moment(new Date(value.dateCreated)).format('YYYY-MM-DD HH:mm:SS'),
                  createdYmd: moment(new Date(value.dateCreated)).format('YYYY-MM-DD'),
                  createdHms: moment(new Date(value.dateCreated)).format('HH:mm:SS'),
                  userGroup: userGroups[String(value.userGroup)],
                  role: String(value.userGroup),
                  firstname: value.profile.firstname,
                  lastname: value.profile.lastname,
                  isDeleted: value.isDeleted,
                  actions: value._id,
                  phone: value.profile.phone,
                };
              });

              adminUsers = adminUsers.sort(function (a, b) {
                return new Date(b.dateCreated) - new Date(a.dateCreated);
              });

              newAdminUsers['all'] = Vue.lodash.filter(adminUsers, { isDeleted: 0 });
              newAdminUsers['active'] = Vue.lodash.filter(adminUsers, { status: { status: 'active' }, isDeleted: 0 }) || '';
              newAdminUsers['inactive'] = Vue.lodash.filter(adminUsers, { status: { status: 'inactive' }, isDeleted: 0 }) || '';
              newAdminUsers['deleted'] = Vue.lodash.filter(adminUsers, { isDeleted: 1 }) || '';

              commit('SET_ADMIN_USER_SUCCESS', newAdminUsers);
              resolve(newAdminUsers);
            } else {
              commit('GET_ADMIN_USER_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_ADMIN_USER_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_ADMIN_USER_BY_ID: ({ commit, dispatch }, adminID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_ADMIN_USER_BY_ID_REQUEST');
    return new Promise((resolve, reject) => {
      return getAdminUserByID(token, adminID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_ADMIN_USER_BY_ID', resp.data);
              resolve(resp.data);
            } else {
              commit('GET_ADMIN_USER_BY_ID_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_ADMIN_USER_BY_ID_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_ADMIN_USER: ({ commit, dispatch }, { userID, userData }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_ADMIN_REQUEST');
    return new Promise((resolve, reject) => {
      return updateAdminUser(token, userID, userData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_ADMIN_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_ADMIN_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_ADMIN_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_ADMIN_USER: ({ commit, dispatch }, userID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_ADMIN_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteAdminUser(token, userID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_ADMIN_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_ADMIN_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_ADMIN_FAILED');
          reject(errors(err));
        });
    });
  },
};

export default actions;
