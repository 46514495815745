import Vue from 'vue';
import { getVideoFiles, getVideoFileByID, getVideoFileByCPId, createVideoFile, updateVideoFile, deleteVideoFile } from '@/api/request/videoManagement';
import { uploadFile } from '@/api/request/tools';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment-timezone';

Vue.use(Cookies);

const actions = {
  GET_VIDEO_FILES: ({ commit, dispatch }, companyID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_VIDEO_FILES_REQUEST');
    return new Promise((resolve, reject) => {
      return getVideoFiles(token, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let videoFileSource = resp.data.data;

              let videoFiles = videoFileSource.map((value) => {
                let data = {
                  videoFileID: value._id,
                  shortDescription: value.short_description,
                  longDescription: value.long_description,
                  companyID: value.companyID || 'All',
                  filename: value.filename,
                  url: value.url,
                  dateCreated: moment(new Date(value.dateCreated)).tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:SS'),
                  actions: value._id,
                };
                return data;
              });

              videoFiles = videoFiles.sort(function (a, b) {
                return new Date(b.dateCreated) - new Date(a.dateCreated);
              });

              commit('GET_VIDEO_FILES_SUCCESS', videoFiles);
              resolve(videoFiles);
            }
          }
        })
        .catch((err) => {
          commit('GET_VIDEO_FILES_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_VIDEO_FILE_BY_ID: ({ commit, dispatch }, videoFileID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_VIDEO_FILES_REQUEST');
    return new Promise((resolve, reject) => {
      return getVideoFileByID(token, videoFileID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_VIDEO_FILE_SUCCESS', resp.data.data);
              resolve(resp.data.data);
            } else {
              commit('GET_VIDEO_FILES_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_VIDEO_FILES_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_VIDEO_FILE_BY_CP_ID: ({ commit, dispatch }, contentProviderID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_VIDEO_FILES_REQUEST');
    return new Promise((resolve, reject) => {
      return getVideoFileByCPId(token, contentProviderID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let videoFileSource = resp.data.data;

              let videoFiles = videoFileSource.map((value) => {
                let data = {
                  videoFileID: value._id,
                  shortDescription: value.short_description,
                  longDescription: value.long_description,
                  companyID: value.companyID || 'All',
                  filename: value.filename,
                  url: value.url,
                  dateCreated: moment(new Date(value.dateCreated)).format('YYYY-MM-DD HH:mm:SS'),
                  actions: value._id,
                };
                return data;
              });

              videoFiles = videoFiles.sort(function (a, b) {
                return new Date(b.dateCreated) - new Date(a.dateCreated);
              });

              commit('GET_VIDEO_FILES_SUCCESS', videoFiles);
              resolve(videoFiles);
            }
          }
        })
        .catch((err) => {
          commit('GET_VIDEO_FILES_FAILED');
          reject(errors(err));
        });
    });
  },
  CREATE_VIDEO_FILE: ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_VIDEO_FILES_REQUEST');
    return new Promise((resolve, reject) => {
      return createVideoFile(token, formData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_VIDEO_FILE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_VIDEO_FILES_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_VIDEO_FILES_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_VIDEO_FILE: ({ commit, dispatch }, { formData, videoFileID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_VIDEO_FILES_REQUEST');
    return new Promise((resolve, reject) => {
      return updateVideoFile(token, formData, videoFileID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_VIDEO_FILE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_VIDEO_FILES_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_VIDEO_FILES_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_VIDEO_FILE: ({ commit, dispatch }, videoFileID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_VIDEO_FILES_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteVideoFile(token, videoFileID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_VIDEO_FILE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_VIDEO_FILES_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_VIDEO_FILES_FAILED');
          reject(errors(err));
        });
    });
  },
};

export default actions;
