import Vue from 'vue';
import $http from '../utils/http';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);

const getWaveFiles = (token, companyID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/wave-management/${companyID}`, null, 'GET', header);
};

const getWaveFileByID = (token, waveFileID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/wave-management/file/${waveFileID}`, null, 'GET', header);
};

const getWaveFileByCPId = (token) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/wave-management/content-provider/files`, null, 'GET', header);
};

const createWaveFile = (token, waveFileData) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/wave-management`, waveFileData, 'POST', header);
};

const updateWaveFile = (token, waveFileData, waveFileID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/wave-management/${waveFileID}`, waveFileData, 'PUT', header);
};

const deleteWaveFile = (token, waveFileID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/wave-management/${waveFileID}`, null, 'DELETE', header);
};

export { getWaveFiles, getWaveFileByID, getWaveFileByCPId, createWaveFile, updateWaveFile, deleteWaveFile };
