const state = {
  loading: false,
  success: false,
  error: false,
  groupLoading: false,
  groupSuccess: false,
  groupError: false,
  selectedGroups: [],
  userLoading: false,
  userSuccess: false,
  userError: false,
  userList: false,
  campaignLoading: false,
  campaignSuccess: false,
  campaignError: false,
  campaignList: [],
  messageReceiverLoading: false,
  messageReceiverSuccess: false,
  messageReceiverError: false,
  messageReceiverList: [],
  messageSequencesList: [],
  messageImageLoading: false,
  messageImageSuccess: false,
  messageImageFailed: false,
  smsMessageLoading: false,
  smsMessageSuccess: false,
  smsMessageError: false,
  smsMessage: {},
  emailPushcastLoading: false,
  emailPushcastSuccess: true,
  emailPushcastError: false,
  emailPushcast: {},
};

export default state;
