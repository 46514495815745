<template>
  <footer class="footer px-4 mx-2">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6"></div>
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-right text-gray" v-html="copyright"></div>
      </div>
    </div>
  </footer>
</template>
<script>
const YEAR = new Date().getFullYear();
export default {
  data() {
    return {};
  },
  computed: {
    copyright() {
      let copyright = `© ${YEAR} <a href="http://www.vobo.com/">VOBO</a>, LLC`;

      if (this.$store.state.company.company !== undefined && this.$store.state.company.company.hasOwnProperty('copyright')) {
        copyright = this.$store.state.company.company.copyright.message;
      }

      return copyright;
    },
  },
};
</script>
<style></style>
