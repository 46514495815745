const mutations = {
  GET_USER_GROUPS_REQUEST: (state) => {
    state.groupLoading = true;
    state.groupSuccess = false;
    state.groupError = false;
    state.selectedGroups = [];
  },
  GET_USER_GROUPS_SUCCESS: (state, data) => {
    state.groupLoading = false;
    state.groupSuccess = true;
    state.groupError = false;
    state.selectedGroups = data;
  },
  GET_USER_GROUPS_FAILED: (state) => {
    state.groupLoading = false;
    state.groupSuccess = false;
    state.groupError = true;
    state.selectedGroups = [];
  },
  GET_MESSAGES_REQUEST: (state) => {
    state.loading = true;
    state.success = false;
    state.error = false;
    state.messageList = [];
  },
  SET_MESSAGES_SUCCESS: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
    state.messageList = data;
  },
  GET_MESSAGES_FAILED: (state) => {
    state.loading = false;
    state.success = false;
    state.error = true;
    state.messageList = [];
  },
  GET_USERS_REQUEST: (state) => {
    state.userLoading = true;
    state.userSuccess = false;
    state.userError = false;
    state.userList = [];
  },
  SET_USERS_SUCCESS: (state, data) => {
    state.userLoading = false;
    state.userSuccess = true;
    state.userError = false;
    state.userList = data.list;
    state.documentsReturned = data.docs_returned;
    state.totalFilteredUsers = data.totalFilteredUsers;
    state.totalUsers = data.totalUsers;
    state.registeredCount = data.registeredCount;
    state.unregisteredCount = data.unregisteredCount;
    state.activeCount = data.activeCount;
    state.suspendedCount = data.suspendedCount;
    state.usersForMessagingCount = data.usersForMessagingCount;
    state.registeredUsersForMessagingCount = data.registeredUsersForMessagingCount;
    state.unregisteredUsersForMessagingCount = data.unregisteredUsersForMessagingCount;
  },
  GET_USERS_FAILED: (state) => {
    state.userLoading = false;
    state.userSuccess = false;
    state.userError = true;
    state.userList = [];
  },
  SEND_MESSAGE_REQUEST: (state) => {
    state.loading = true;
    state.success = false;
    state.error = false;
  },
  SEND_MESSAGE_SUCCESS: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
  },
  SEND_MESSAGE_FAILED: (state) => {
    state.loading = false;
    state.success = false;
    state.error = true;
  },
  GET_CAMPAIGN_REQUEST: (state) => {
    state.campaignLoading = true;
    state.campaignSuccess = false;
    state.campaignError = false;
  },
  GET_CAMPAIGN_SUCCESS: (state, data) => {
    state.campaignLoading = false;
    state.campaignSuccess = true;
    (state.campaignError = false), (state.campaignList = data);
  },
  GET_CAMPAIGN_FAILED: (state) => {
    state.campaignLoading = false;
    state.campaignSuccess = false;
    state.campaignError = true;
  },
  GET_MOBILE_MESSAGES_RECEIVER: (state) => {
    state.messageReceiverLoading = true;
    state.messageReceiverSuccess = false;
    state.messageReceiverError = false;
    state.messageReceiverList = [];
  },
  SET_MOBILE_MESSAGES_SUCCESS_RECEIVER: (state, data) => {
    state.messageReceiverLoading = false;
    state.messageReceiverSuccess = true;
    state.messageReceiverError = false;
    state.messageReceiverList = data;
  },
  GET_MOBILE_MESSAGES_FAILED_RECEIVER: (state) => {
    state.messageReceiverLoading = false;
    state.messageReceiverSuccess = false;
    state.messageReceiverError = true;
    state.messageReceiverList = [];
  },
  GET_MESSAGE_SEQUENCES_REQUEST: (state) => {
    state.loading = true;
    state.success = false;
    state.error = false;
    state.messageSequencesList = [];
  },
  GET_MESSAGE_SEQUENCES_SUCCESS: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
    state.messageSequencesList = data;
  },
  GET_MESSAGE_SEQUENCES_FAILED: (state) => {
    state.loading = false;
    state.success = false;
    state.error = true;
    state.messageSequencesList = [];
  },
  DELETE_NOTIFICATION_IMAGE_REQUEST: (state) => {
    state.messageImageLoading = true;
    state.messageImageSuccess = false;
    state.messageImageFailed = false;
  },
  DELETE_NOTIFICATION_IMAGE_SUCCESS: (state) => {
    state.messageImageLoading = false;
    state.messageImageSuccess = true;
    state.messageImageFailed = false;
  },
  DELETE_NOTIFICATION_IMAGE_FAILED: (state) => {
    state.messageImageLoading = false;
    state.messageImageSuccess = false;
    state.messageImageFailed = true;
  },
  GET_SMS_MESSAGE_REQUEST: (state) => {
    state.smsMessageLoading = true;
    state.smsMessageSuccess = false;
    state.smsMessageError = false;
  },
  GET_SMS_MESSAGE_SUCCESS: (state, data) => {
    state.smsMessageLoading = false;
    state.smsMessageSuccess = true;
    state.smsMessageError = false;
    state.smsMessage = data;
  },
  GET_SMS_MESSAGE_FAILED: (state) => {
    state.smsMessageLoading = false;
    state.smsMessageSuccess = false;
    state.smsMessageError = true;
  },
  GET_EMAIL_PUSHCAST_REQUEST: (state) => {
    state.emailPushcastLoading = true;
    state.emailPushcastSuccess = false;
    state.emailPushcastError = false;
  },
  GET_EMAIL_PUSHCAST_SUCCESS: (state, data) => {
    state.emailPushcastLoading = false;
    state.emailPushcastSuccess = true;
    state.emailPushcastError = false;
    state.emailPushcast = data;
  },
  GET_EMAIL_PUSHCAST_FAILED: (state) => {
    state.emailPushcastLoading = false;
    state.emailPushcastSuccess = false;
    state.emailPushcastError = true;
  },
  RESET_DATA: (state) => {
    state.loading = false;
    state.success = false;
    state.error = false;
    state.groupLoading = false;
    state.groupSuccess = false;
    state.groupError = false;
    state.selectedGroups = [];
    state.userLoading = false;
    state.userSuccess = false;
    state.userError = false;
    state.userList = false;
    state.campaignLoading = false;
    state.campaignSuccess = false;
    state.campaignError = false;
    (state.campaignList = []), (state.messageImageLoading = false);
    state.messageImageSuccess = false;
    state.messageImageFailed = false;
  },
};

export default mutations;
