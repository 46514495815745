import Vue from 'vue';
import {
  getCompanyList,
  getCompanyById,
  getCompanyByCompanyID,
  createCompany,
  deleteCompany,
  updateCompany,
  updateCompanyStatus,
  updateCompanySettings,
} from '@/api/request/company';
import { getSettings, updateSettings, updateSettingsFile, updateFeaturedLinks } from '@/api/request/companySettings';
import { updateAllowPushcastEmail } from '@/api/request/settings';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';

Vue.use(Cookies);

const actions = {
  GET_COMPANY_LIST: ({ commit }, data) => {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      commit('COMPANY_LIST_REQUEST');
      return getCompanyList(token, data)
        .then(async (result) => {
          if (result) {
            commit('SET_COMPANY_LIST', result.data);
            resolve(result.data);
          }
        })
        .catch((err) => {
          commit('COMPANY_LIST_ERROR', err);
          reject(errors(err));
        });
    });
  },
  GET_COMPANY_BY_ID: ({ commit }, id) => {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      commit('COMPANY_REQUEST');
      return getCompanyById(token, id)
        .then(async (result) => {
          if (result) {
            commit('SET_COMPANY', result.data);
            resolve(result.data);
          }
        })
        .catch((err) => {
          commit('COMPANY_ERROR', err);
          reject(errors(err));
        });
    });
  },
  GET_COMPANY_BY_COMPANY_ID: ({ commit }, companyID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      commit('COMPANY_REQUEST');
      return getCompanyByCompanyID(token, companyID)
        .then(async (result) => {
          if (result) {
            commit('SET_COMPANY', result.data);
            resolve(result.data);
          }
        })
        .catch((err) => {
          commit('COMPANY_ERROR', err);
          reject(errors(err));
        });
    });
  },
  CREATE_COMPANY: ({ commit }, data) => {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      commit('COMPANY_LIST_REQUEST');
      return createCompany(token, data)
        .then((result) => {
          if (result) {
            resolve(result);
          }
        })
        .catch((err) => {
          commit('COMPANY_LIST_ERROR', err);
          reject(errors(err));
        });
    });
  },
  DELETE_COMPANY: ({ commit }, companyId) => {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      commit('COMPANY_LIST_REQUEST');
      return deleteCompany(token, companyId)
        .then((result) => {
          if (result) {
            resolve(result);
          }
        })
        .catch((err) => {
          commit('COMPANY_LIST_ERROR', err);
          reject(errors(err));
        });
    });
  },
  UPDATE_COMPANY_STATUS: ({ commit }, { companyId, status }) => {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      commit('COMPANY_LIST_REQUEST');
      return updateCompanyStatus(token, companyId, status)
        .then((result) => {
          if (result) {
            resolve(result);
          }
        })
        .catch((err) => {
          commit('COMPANY_LIST_ERROR', err);
          reject(errors(err));
        });
    });
  },
  UPDATE_COMPANY: ({ commit }, { companyId, formData }) => {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      commit('COMPANY_LIST_REQUEST');
      return updateCompany(token, companyId, formData)
        .then((result) => {
          if (result) {
            resolve(result);
          }
        })
        .catch((err) => {
          commit('COMPANY_LIST_ERROR', err);
          reject(errors(err));
        });
    });
  },
  UPDATE_COMPANY_SETTINGS: ({ commit, dispatch }, settingsData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;

    if (settingsData.type === 'maintenance') {
      commit('GET_COMPANY_SETTINGS_MAINTENANCE');
    } else if (settingsData.type === 'favicon') {
      commit('GET_COMPANY_SETTINGS_FAVICON');
    } else if (settingsData.type === 'adminColor') {
      commit('GET_COMPANY_SETTINGS_ADMINCOLOR');
    } else if (settingsData.type === 'logo') {
      commit('GET_COMPANY_SETTINGS_LOGO');
    } else if (settingsData.type === 'copyright') {
      commit('GET_COMPANY_SETTINGS_COPYRIGHT');
    } else if (settingsData.type === 'accountSetupLink') {
      commit('GET_COMPANY_SETTINGS_ACCOUNTSETUP');
    } else if (settingsData.type === 'adminMenu') {
      commit('GET_COMPANY_SETTINGS_ADMINMENU');
    } else if (settingsData.type === 'mobileMenu') {
      commit('GET_COMPANY_SETTINGS_MOBILEMENU');
    } else if (settingsData.type === 'featureLinks') {
      commit('GET_COMPANY_SETTINGS_FEATURELINKS');
    }

    if (settingsData.type === 'favicon' || settingsData.type === 'logo' || settingsData.type === 'featureLinks') {
      return new Promise((resolve, reject) => {
        return updateSettingsFile(token, settingsData)
          .then((resp) => {
            if (resp) {
              if (resp.data) {
                if (settingsData.type === 'maintenance') {
                  commit('GET_COMPANY_SETTINGS_MAINTENANCE_DONE', resp);
                } else if (settingsData.type === 'favicon') {
                  commit('GET_COMPANY_SETTINGS_FAVICON_DONE', resp);
                } else if (settingsData.type === 'adminColor') {
                  commit('GET_COMPANY_SETTINGS_ADMINCOLOR_DONE', resp);
                } else if (settingsData.type === 'logo') {
                  commit('GET_COMPANY_SETTINGS_LOGO_DONE', resp);
                } else if (settingsData.type === 'accountSetupLink') {
                  commit('GET_COMPANY_SETTINGS_ACCOUNTSETUP_DONE', resp);
                } else if (settingsData.type === 'copyright') {
                  commit('GET_COMPANY_SETTINGS_COPYRIGHT_DONE', resp);
                } else if (settingsData.type === 'adminMenu') {
                  commit('GET_COMPANY_SETTINGS_ADMINMENU_DONE');
                } else if (settingsData.type === 'mobileMenu') {
                  commit('GET_COMPANY_SETTINGS_MOBILEMENU_DONE');
                } else if (settingsData.type === 'featureLinks') {
                  commit('GET_COMPANY_SETTINGS_FEATURELINKS_DONE');
                }
                resolve(resp);
              } else {
                if (settingsData.type === 'maintenance') {
                  commit('GET_COMPANY_SETTINGS_MAINTENANCE_FAILED');
                } else if (settingsData.type === 'favicon') {
                  commit('GET_COMPANY_SETTINGS_FAVICON_FAILED');
                } else if (settingsData.type === 'adminColor') {
                  commit('GET_COMPANY_SETTINGS_ADMINCOLOR_FAILED');
                } else if (settingsData.type === 'logo') {
                  commit('GET_COMPANY_SETTINGS_LOGO_FAILED');
                } else if (settingsData.type === 'copyright') {
                  commit('GET_COMPANY_SETTINGS_COPYRIGHT_FAILED');
                } else if (settingsData.type === 'accountSetupLink') {
                  commit('GET_COMPANY_SETTINGS_ACCOUNTSETUP_FAILED');
                } else if (settingsData.type === 'adminMenu') {
                  commit('GET_COMPANY_SETTINGS_ADMINMENU_FAILED');
                } else if (settingsData.type === 'mobileMenu') {
                  commit('GET_COMPANY_SETTINGS_MOBILEMENU_FAILED');
                } else if (settingsData.type === 'featureLinks') {
                  commit('GET_COMPANY_SETTINGS_FEATURELINKS_FAILED');
                }
              }
            }
          })
          .catch((err) => {
            if (settingsData.type === 'maintenance') {
              commit('GET_COMPANY_SETTINGS_MAINTENANCE_FAILED');
            } else if (settingsData.type === 'favicon') {
              commit('GET_COMPANY_SETTINGS_FAVICON_FAILED');
            } else if (settingsData.type === 'adminColor') {
              commit('GET_COMPANY_SETTINGS_ADMINCOLOR_FAILED');
            } else if (settingsData.type === 'logo') {
              commit('GET_COMPANY_SETTINGS_LOGO_FAILED');
            } else if (settingsData.type === 'copyright') {
              commit('GET_COMPANY_SETTINGS_COPYRIGHT_FAILED');
            } else if (settingsData.type === 'accountSetupLink') {
              commit('GET_COMPANY_SETTINGS_ACCOUNTSETUP_FAILED');
            } else if (settingsData.type === 'adminMenu') {
              commit('GET_COMPANY_SETTINGS_ADMINMENU_FAILED');
            } else if (settingsData.type === 'mobileMenu') {
              commit('GET_COMPANY_SETTINGS_MOBILEMENU_FAILED');
            } else if (settingsData.type === 'featureLinks') {
              commit('GET_COMPANY_SETTINGS_FEATURELINKS_FAILED');
            }
            reject(errors(err));
          });
      });
    } else {
      settingsData.companyID = Cookies.get('companyID');

      return new Promise((resolve, reject) => {
        return updateSettings(token, settingsData)
          .then((resp) => {
            if (resp) {
              if (resp.data) {
                if (settingsData.type === 'maintenance') {
                  commit('GET_COMPANY_SETTINGS_MAINTENANCE_DONE', resp);
                } else if (settingsData.type === 'adminColor') {
                  commit('GET_COMPANY_SETTINGS_ADMINCOLOR_DONE', resp);
                } else if (settingsData.type === 'copyright') {
                  commit('GET_COMPANY_SETTINGS_COPYRIGHT_DONE', resp);
                } else if (settingsData.type === 'accountSetupLink') {
                  commit('GET_COMPANY_SETTINGS_ACCOUNTSETUP_FAILED', resp);
                }
                resolve(resp);
              } else {
                if (settingsData.type === 'maintenance') {
                  commit('GET_COMPANY_SETTINGS_MAINTENANCE_FAILED');
                } else if (settingsData.type === 'adminColor') {
                  commit('GET_COMPANY_SETTINGS_ADMINCOLOR_FAILED');
                } else if (settingsData.type === 'copyright') {
                  commit('GET_COMPANY_SETTINGS_COPYRIGHT_FAILED');
                } else if (settingsData.type === 'accountSetupLink') {
                  commit('GET_COMPANY_SETTINGS_ACCOUNTSETUP_FAILED');
                }
              }
            }
          })
          .catch((err) => {
            if (settingsData.type === 'maintenance') {
              commit('GET_COMPANY_SETTINGS_MAINTENANCE_FAILED');
            } else if (settingsData.type === 'adminColor') {
              commit('GET_COMPANY_SETTINGS_ADMINCOLOR_FAILED');
            } else if (settingsData.type === 'copyright') {
              commit('GET_COMPANY_SETTINGS_COPYRIGHT_FAILED');
            } else if (settingsData.type === 'accountSetupLink') {
              commit('GET_COMPANY_SETTINGS_ACCOUNTSETUP_FAILED');
            }
            reject(errors(err));
          });
      });
    }
  },
  UPDATE_FEATURED_LINKS: ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;

    commit('GET_COMPANY_SETTINGS_FEATURELINKS');

    return new Promise((resolve, reject) => {
      return updateFeaturedLinks(token, formData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('GET_COMPANY_SETTINGS_FEATURELINKS_DONE');
              resolve(resp);
            } else {
              commit('GET_COMPANY_SETTINGS_FEATURELINKS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_COMPANY_SETTINGS_FEATURELINKS_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_COMPANY_SETTINGS_BY_ID: ({ commit }, id) => {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      commit('COMPANY_REQUEST');
      return getSettings(token, id)
        .then(async (result) => {
          if (result) {
            commit('SET_COMPANY', result.data);
            resolve(result.data);
          }
        })
        .catch((err) => {
          commit('COMPANY_ERROR', err);
          reject(errors(err));
        });
    });
  },
  UPDATE_ALLOW_PUSHCAST_SETTING: ({ commit }, data) => {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      commit('COMPANY_ALLOW_PUSHCAST_LOADING');
      return updateAllowPushcastEmail(token, data)
        .then(async (result) => {
          if (result) {
            commit('COMPANY_ALLOW_PUSHCAST_DONE', result.data.allowPushcastEmail);
            resolve(result.data.data.allowPushcastEmail);
          }
        })
        .catch((err) => {
          commit('COMPANY_ALLOW_PUSHCAST_ERROR', err);
          reject(errors(err));
        });
    });
  },
};

export default actions;
