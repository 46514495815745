import $http from '../utils/http';

const getAdminUsers = (token, companyID = null) => {
  const header = token ? { authorization: token } : null;
  if (companyID) {
    return $http(`/user/admins/${companyID}`, null, 'GET', header);
  } else {
    return $http(`/user/admins`, null, 'GET', header);
  }
};

const getAdminUserByID = (token, adminID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/admins/admin/${adminID}`, null, 'GET', header);
};

const createAdminUser = (token, data) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/admins/`, data, 'POST', header);
};

const updateAdminUser = (token, adminID, data) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/admins/${adminID}`, data, 'PUT', header);
};

const deleteAdminUser = (token, adminID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/admins/${adminID}`, '', 'DELETE', header);
};

export { getAdminUsers, getAdminUserByID, createAdminUser, updateAdminUser, deleteAdminUser };
