<template>
  <content-loader :height="50" :width="180" :speed="2" primary-color="#f0f3f5" secondary-color="#d0dbe1">
    <circle cx="25.7488" cy="24.3788" r="18.7488" />
    <rect x="54" y="13" rx="4" ry="4" width="60" height="7.5" />
    <rect x="54" y="28" rx="4" ry="4" width="100" height="6" />
  </content-loader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  components: {
    ContentLoader,
  },
};
</script>
