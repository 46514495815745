const mutations = {
  GET_WAVE_FILES_REQUEST: (state) => {
    state.loading = true;
    state.success = false;
    state.error = false;
    state.waveList = [];
    state.waveFile = {};
  },
  GET_WAVE_FILES_SUCCESS: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
    state.waveList = data;
    state.waveFile = {};
  },
  GET_WAVE_FILES_FAILED: (state) => {
    state.loading = false;
    state.success = false;
    state.error = true;
    state.waveList = [];
    state.waveFile = {};
  },
  SET_WAVE_FILE_SUCCESS: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
    state.waveFile = data;
  },
  RESET_DATA: (state) => {
    state.loading = false;
    state.success = false;
    state.error = false;
    state.waveList = [];
    state.waveFile = {};
  },
};

export default mutations;
