import Vue from 'vue';
import { getProducts, getProductsById, createProduct, updateProduct, deleteProduct } from '@/api/request/product';
import { uploadFile } from '@/api/request/tools';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment-timezone';

Vue.use(Cookies);

const actions = {
  GET_PRODUCTS: ({ commit, dispatch }, companyID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_PRODUCTS_REQUEST');
    return new Promise((resolve, reject) => {
      return getProducts(token, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let productSource = resp.data.data;

              let products = productSource.map((value) => {
                let data = {
                  _id: value._id,
                  productID: value.productID,
                  product: value.product,
                  description: value.description,
                  productType: value.productType,
                  companyID: value.companyID || 'All',
                  planID: value.plan[0].planID,
                  planType: value.plan[0].nickname,
                  amount: value.plan[0].amount,
                  currency: value.plan[0].currency,
                  interval: value.plan[0].interval === 'month' ? 'monthly' : 'yearly',
                  dateCreated: moment(new Date(value.dateCreated)).tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:SS'),
                  actions: value._id,
                };
                return data;
              });

              products = products.sort(function (a, b) {
                return new Date(b.dateCreated) - new Date(a.dateCreated);
              });

              commit('GET_PRODUCTS_SUCCESS', products);
              resolve(products);
            }
          }
        })
        .catch((err) => {
          commit('GET_PRODUCTS_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_PRODUCT_BY_ID: ({ commit, dispatch }, productID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_PRODUCTS_REQUEST');
    return new Promise((resolve, reject) => {
      return getProductsById(token, productID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_PRODUCT_SUCCESS', resp.data.data);
              resolve(resp.data.data);
            } else {
              commit('GET_PRODUCTS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_PRODUCTS_FAILED');
          reject(errors(err));
        });
    });
  },
  CREATE_PRODUCT: ({ commit, dispatch }, productData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_PRODUCTS_REQUEST');
    return new Promise((resolve, reject) => {
      return createProduct(token, productData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_PRODUCT_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_PRODUCTS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_PRODUCTS_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_PRODUCT: ({ commit, dispatch }, { productData, productID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_PRODUCTS_REQUEST');
    return new Promise((resolve, reject) => {
      return updateProduct(token, productData, productID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_PRODUCT_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_PRODUCTS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_PRODUCTS_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_PRODUCT: ({ commit, dispatch }, productID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_PRODUCTS_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteProduct(token, productID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_PRODUCT_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_PRODUCTS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_PRODUCTS_FAILED');
          reject(errors(err));
        });
    });
  },
};

export default actions;
