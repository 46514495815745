import Vue from 'vue';
import $http from '../utils/http';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);

const getVideoFiles = (token, companyID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/video-management/${companyID}`, null, 'GET', header);
};

const getVideoFileByID = (token, videoFileID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/video-management/file/${videoFileID}`, null, 'GET', header);
};

const getVideoFileByCPId = (token) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/video-management/content-provider/files`, null, 'GET', header);
};

const createVideoFile = (token, videoFileData) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/video-management`, videoFileData, 'POST', header);
};

const updateVideoFile = (token, videoFileData, videoFileID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/video-management/${videoFileID}`, videoFileData, 'PUT', header);
};

const deleteVideoFile = (token, videoFileID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/video-management/${videoFileID}`, null, 'DELETE', header);
};

export { getVideoFiles, getVideoFileByID, getVideoFileByCPId, createVideoFile, updateVideoFile, deleteVideoFile };
