import Vue from 'vue';
import * as Cookie from 'js-cookie';
import Cookies from 'js-cookie-remove-all';
import config from 'config';
import { decoder } from '@/utils/sessions/jwt';
import store from '@/store';

Vue.use(Cookie);
// console.log($store)

export const globalStore = new Vue({
  data() {
    return {
      token: null,
      userID: null,
      global: {},
      profile: {},
      dashBoard: {},
    };
  },
  methods: {
    updateToken() {
      try {
        const token = Cookie.get('user-token') || '';

        this.token = token || null;
        if (token || token !== '') {
          const decode = decoder({ secret: config.secret });
          var payload = decode(token);
          this.userID = payload._id || null;
        } else {
          Cookies.removeAll();
        }
      } catch (e) {
        if (e.name === 'TokenExpiredError') {
          Cookies.removeAll();
        }
      }
    },
    resetToken() {
      this.token = null;
      this.userID = null;
    },
  },
});

globalStore.updateToken();
