/*
 API request UTILITY
 with params : (url, data, method, header)
*/
import axios from 'axios';
import { API_URL } from '../constants/baseUrl';

const $http = (url: any, data: any, method: any, header: any = null, customAPI?: any, downloadFileOption: any = null) =>
  new Promise((resolve, reject) => {
    let trimUrl = url.substr(1);
    let apiUrl = `${API_URL}/${trimUrl}`;

    if (customAPI !== undefined && customAPI) {
      apiUrl = url;
    }

    const options = { url: apiUrl, data: data, method: method, ...downloadFileOption };

    if (header !== null) {
      options.headers = header;
    }

    axios(options)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });

export default $http;
