const mutations = {
  COMPANY_LIST_REQUEST: (state) => {
    state.loading = true;
  },
  SET_COMPANY_LIST: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
    state.companyList = data;
  },
  SET_COMPANY_LIST_MODULE: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
    state.companyListModule = data;
  },
  COMPANY_LIST_ERROR: (state) => {
    state.loading = false;
    state.success = false;
    state.error = true;
  },
  SET_SELECTED_COMPANY: (state, companyID) => {
    state.selectedCompany = companyID;
  },
  SET_SELECTED_COMPANY_DETAILS: (state, company) => {
    state.company = company;
  },
  COMPANY_REQUEST: (state) => {
    state.companyLoading = true;
    state.companyEdit = {};
  },
  SET_COMPANY: (state, data) => {
    state.companyLoading = false;
    state.companySuccess = true;
    state.companyError = false;
    state.companyEdit = data;
  },
  COMPANY_ERROR: (state) => {
    state.companyLoading = false;
    state.companySuccess = false;
    state.companyError = true;
  },
  COMPANY_ALLOW_PUSHCAST_LOADING: (state) => {
    state.allowPushcastEmailLoading = true;
    state.allowPushcastEmailError = false;
  },
  COMPANY_ALLOW_PUSHCAST_DONE: (state, data) => {
    state.allowPushcastEmailLoading = false;
    state.allowPushcastEmail = data;
    state.allowPushcastEmailError = false;
  },
  COMPANY_ALLOW_PUSHCAST_ERROR: (state) => {
    state.allowPushcastEmailLoading = false;
    state.allowPushcastEmailError = true;
  },
  RESET_DATA: (state) => {
    state.loading = false;
    state.success = false;
    state.error = false;
    state.company = {};
    state.selectedCompany = '';
    state.companyList = [];
    state.companyLoading = false;
    state.companySuccess = false;
    state.companyError = false;
    state.companyEdit = {};
  },
  GET_COMPANY_SETTINGS_MAINTENANCE: (state) => {
    state.maintenanceLoading = true;
  },
  GET_COMPANY_SETTINGS_FAVICON: (state) => {
    state.companyLogoLoading = true;
  },
  GET_COMPANY_SETTINGS_ADMINCOLOR: (state) => {
    state.adminColorLoading = true;
  },
  GET_COMPANY_SETTINGS_LOGO: (state) => {
    state.faviconLoading = true;
  },
  GET_COMPANY_SETTINGS_ACCOUNTSETUP: (state) => {
    state.accountSetupLinkLoading = true;
  },
  GET_COMPANY_SETTINGS_COPYRIGHT: (state) => {
    state.copyrightLoading = true;
  },
  GET_COMPANY_SETTINGS_ADMINMENU: (state) => {
    state.adminMenuLoading = true;
  },
  GET_COMPANY_SETTINGS_MOBILEMENU: (state) => {
    state.mobileMenuLoading = true;
  },
  GET_COMPANY_SETTINGS_FEATURELINKS: (state) => {
    state.featuredLinksLoading = true;
  },
  GET_COMPANY_SETTINGS_FAVICON_DONE: (state) => {
    state.companyLogoLoading = false;
  },
  GET_COMPANY_SETTINGS_ADMINCOLOR_DONE: (state) => {
    state.adminColorLoading = false;
  },
  GET_COMPANY_SETTINGS_LOGO_DONE: (state) => {
    state.faviconLoading = false;
  },
  GET_COMPANY_SETTINGS_COPYRIGHT_DONE: (state) => {
    state.copyrightLoading = false;
  },
  GET_COMPANY_SETTINGS_ACCOUNTSETUP_DONE: (state) => {
    state.accountSetupLinkLoading = false;
  },
  GET_COMPANY_SETTINGS_MAINTENANCE_DONE: (state) => {
    state.maintenanceLoading = false;
  },
  GET_COMPANY_SETTINGS_FAVICON_DONE: (state) => {
    state.companyLogoLoading = false;
  },
  GET_COMPANY_SETTINGS_LOGO_DONE: (state) => {
    state.faviconLoading = false;
  },
  GET_COMPANY_SETTINGS_COPYRIGHT_DONE: (state) => {
    state.copyrightLoading = false;
  },
  GET_COMPANY_SETTINGS_ADMINCOLOR_DONE: (state) => {
    state.adminColorLoading = false;
  },
  GET_COMPANY_SETTINGS_MAINTENANCE_DONE: (state) => {
    state.maintenanceLoading = false;
  },
  GET_COMPANY_SETTINGS_ADMINMENU_DONE: (state) => {
    state.adminMenuLoading = false;
  },
  GET_COMPANY_SETTINGS_MOBILEMENU_DONE: (state) => {
    state.mobileMenuLoading = false;
  },
  GET_COMPANY_SETTINGS_FEATURELINKS_DONE: (state) => {
    state.featuredLinksLoading = false;
  },
  GET_COMPANY_SETTINGS_MAINTENANCE_FAILED: (state) => {
    state.maintenanceLoading = false;
  },
  GET_COMPANY_SETTINGS_FAVICON_FAILED: (state) => {
    state.faviconLoading = false;
  },
  GET_COMPANY_SETTINGS_ADMINCOLOR_FAILED: (state) => {
    state.adminColorLoading = false;
  },
  GET_COMPANY_SETTINGS_LOGO_FAILED: (state) => {
    state.companyLogoLoading = false;
  },
  GET_COMPANY_SETTINGS_COPYRIGHT_FAILED: (state) => {
    state.copyrightLoading = false;
  },
  GET_COMPANY_SETTINGS_ACCOUNTSETUP_FAILED: (state) => {
    state.accountSetupLinkLoading = false;
  },
  GET_COMPANY_SETTINGS_ADMINMENU_FAILED: (state) => {
    state.adminMenuLoading = false;
  },
  GET_COMPANY_SETTINGS_MOBILEMENU_FAILED: (state) => {
    state.mobileMenuLoading = false;
  },
  GET_COMPANY_SETTINGS_FEATURELINKS_FAILED: (state) => {
    state.featuredLinksLoading = false;
  },
};

export default mutations;
