import Vue from 'vue';
import $http from '../utils/http';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);
/*
  $http (url, data, method, header)
*/

const getCompanyList = (token, id) => {
  const header = token ? { authorization: token } : null;
  return $http(`/company`, null, 'GET', header);
};

const getCompanyById = (token, commpanyID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/company/${commpanyID}`, null, 'GET', header);
};

const getCompanyByCompanyID = (token, commpanyID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/public/company/${commpanyID}`, null, 'GET', header);
};

const getLastCompanyNumber = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/company/number/last_company_number`, null, 'GET', header);
};

const createCompany = (token, data) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token } : null;
  return $http(`/company`, data, 'POST', header);
};

const deleteCompany = (token, id) => {
  const header = token ? { authorization: token } : null;
  return $http(`/company/${id}`, '', 'DELETE', header);
};

const updateCompanyStatus = (token, id, data) => {
  const header = token ? { authorization: token } : null;
  return $http(`/company/status/${id}`, data, 'PUT', header);
};

const updateCompany = (token, id, data) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token } : null;
  return $http(`/company/${id}`, data, 'PUT', header);
};

const updateCompanySettings = (token, commpanyID, data) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/company/${commpanyID}`, data, 'PUT', header);
};

export {
  getCompanyList,
  createCompany,
  deleteCompany,
  updateCompany,
  getCompanyById,
  getCompanyByCompanyID,
  getLastCompanyNumber,
  updateCompanyStatus,
  updateCompanySettings,
};
