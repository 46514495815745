import Vue from 'vue';
import $http from '../utils/http';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);

const getGroupsByCPId = (token, contentProviderID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/groups/${contentProviderID}/0`, null, 'GET', header);
};

const getMobileUsers = (
  token,
  companyID,
  offset,
  objectId,
  sortDirection,
  sortField,
  userType,
  userStatus,
  groups,
  CRFs,
  searchString,
  platForm,
  selectionType
) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(
    `/user/mobile-users/new`,
    {
      companyID: companyID,
      offset: offset,
      objectId: objectId,
      sortDirection: sortDirection,
      sortField: sortField,
      userType: userType,
      userStatus: userStatus,
      groups: groups,
      CRFs: CRFs,
      searchString: searchString,
      onlyActiveUsers: true,
      platForm: platForm,
      searchStringFields: ['email', 'firstName', 'lastName', 'name'],
      selectionType: selectionType,
    },
    'POST',
    header
  );
};

const getMessages = (token, companyID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/messages/${companyID}`, null, 'GET', header);
};

const sendMessage = (token, data, companyID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/messages/`, data, 'POST', header);
};

const deleteMessage = (token, messageID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/messages/${messageID}`, null, 'DELETE', header);
};

const sendCampaign = (token, data, companyID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/messages/campaign/${companyID}`, data, 'POST', header);
};

const sendMessageAttachment = (token, data) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/messages/file`, data, 'POST', header);
};

const sendMessageSchedule = (token, data, companyID) => {
  data.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/messages/schedule`, data, 'POST', header);
};

const sendMessageScheduleAttachment = (token, data) => {
  data.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/messages/schedule/file`, data, 'POST', header);
};

const getCampaignMessages = (token, companyID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/messages/campaigns/${companyID}`, null, 'GET', header);
};

const updateCampaign = (token, campaignData, campaignID, companyID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/messages/campaign/${campaignID}/${companyID}`, campaignData, 'PUT', header);
};

const getCampaignById = (token, campaignID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/messages/campaign/${campaignID}`, null, 'GET', header);
};

const deleteCampaign = (token, campaignID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/messages/campaign/${campaignID}`, null, 'DELETE', header);
};

const getMobileMessagesReceivers = (token, messageID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/messages/${Cookies.get('companyID')}/message/${messageID}`, null, 'GET', header);
};

const getMessageSequences = (token, companyID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/messages/sequence/${companyID}`, null, 'GET', header);
};

const createMessageSequence = (token, data, companyID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/messages/sequence`, data, 'POST', header);
};

const createMessageSequenceAttachment = (token, data) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/messages/sequence/file`, data, 'POST', header);
};

const deleteMessageSequence = (token, messageID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/messages/sequence/${messageID}`, null, 'DELETE', header);
};

const deleteNotificationPhoto = (token, filename) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/messages/file/upload/${filename}`, null, 'DELETE', header);
};

const getPublicSMSMessage = (token) => {
  return $http(`/api/channel/sms/${token}`, null, 'GET', null);
};

const updateStatusPublicSMSMessage = (messageID) => {
  return $http(`/api/channel/sms/update/status/${messageID}`, null, 'GET', null);
};

const readPublicSMSMessage = (messageID) => {
  return $http(`/api/channel/sms/update/read-response/${messageID}`, null, 'GET', null);
};

const feedbackStatusPublicSMSMessage = (messageID, feedbackData) => {
  return $http(`/api/channel/sms/update/feedback/${messageID}`, feedbackData, 'POST', null);
};

const getEmailPushcastMessage = (token) => {
  return $http(`/api/channel/pushcast/${token}`, null, 'GET', null);
};

export {
  getGroupsByCPId,
  getMobileUsers,
  getMessages,
  sendMessage,
  sendMessageAttachment,
  sendMessageSchedule,
  sendMessageScheduleAttachment,
  deleteMessage,
  sendCampaign,
  getCampaignMessages,
  getCampaignById,
  updateCampaign,
  deleteCampaign,
  getMobileMessagesReceivers,
  getMessageSequences,
  createMessageSequence,
  createMessageSequenceAttachment,
  deleteMessageSequence,
  deleteNotificationPhoto,
  getPublicSMSMessage,
  updateStatusPublicSMSMessage,
  readPublicSMSMessage,
  feedbackStatusPublicSMSMessage,
  getEmailPushcastMessage,
};
