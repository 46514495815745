const mutations = {
  GET_SUBSCRIPTIONS_REQUEST: (state) => {
    state.loading = true;
    state.success = false;
    state.error = false;
    state.subscriptionList = [];
    state.subscription = {};
  },
  GET_SUBSCRIPTIONS_SUCCESS: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
    state.subscriptionList = data;
    state.subscription = {};
  },
  GET_SUBSCRIPTIONS_FAILED: (state) => {
    state.loading = false;
    state.success = false;
    state.error = true;
    state.subscriptionList = [];
    state.subscription = {};
  },
  SET_SUBSCRIPTION_SUCCESS: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
    state.subscription = data;
  },
  RESET_DATA: (state) => {
    state.loading = false;
    state.success = false;
    state.error = false;
    state.subscriptionList = [];
    state.subscription = {};
  },
};

export default mutations;
