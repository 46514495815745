<template>
  <router-view></router-view>
</template>

<script lang="ts">
// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import '@/polyfills';
// element ui language configuration
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
locale.use(lang);

// asset imports
import '@/assets/sass/argon.scss';
import '@/assets/css/nucleo/css/nucleo.css';

import 'vue-promise-btn/dist/vue-promise-btn.css';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

export default {
  data() {
    return {};
  },

  mounted() {
    // @ts-ignore
    if (this.$store.state.auth.token) {
      // @ts-ignore
      if (!this.$store.state.user.profileLoaded) {
        // @ts-ignore
        this.$store.dispatch('user/USER_PROFILE_REQUEST');
      }
    }
  },
  methods: {},
};
</script>
<style lang="scss">
/* Import Font Awesome Icons Set */
$fa-font-path: '~font-awesome/fonts/';
@import '~font-awesome/css/font-awesome.min.css';
/* Import Bootstrap Vue Styles */
@import '~bootstrap-vue/dist/bootstrap-vue.css';
/* Import Date Picker */
@import '~vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
/* Import Vue Select */
@import '~vue-multiselect/dist/vue-multiselect.min.css';

.swal2-container .btn-secondary {
  border: 1px solid #eaeaea !important;
}

.g-sidenav-pinned.g-sidenav-show.modal-open {
  padding: 0 !important;
  overflow-y: auto !important;
}
</style>
