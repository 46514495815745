import Vue from 'vue';
import config from 'config';
import { decoder } from '@/utils/sessions/jwt';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import {
  registerUser,
  getUserProfile,
  updateCurrentUserProfile,
  updateStatusUser,
  getDashboard,
  getChannelInfo,
  registerUserByLink,
  confirmLinkRegistration,
  sendSmsRegistration,
} from '@/api/request/user';
import { resetPassword, forgotPassword, changePassword, confirmPasswordToken } from '@/api/request/forgotPassword';
import { globalStore } from '@/utils/global';

Vue.use(Cookies);

const actions = {
  REGISTER_USER: ({ commit, dispatch }, data) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('REGISTER_USER_REQUEST');
    return new Promise((resolve, reject) => {
      return registerUser(token, data)
        .then((resp) => {
          if (resp) {
            commit('REGISTER_USER_SUCCESS', resp);
            resolve(resp);
          } else {
            commit('REGISTER_USER_FAILED');
            reject('Error in creating user');
          }
        })
        .catch((err) => {
          commit('REGISTER_USER_FAILED');
          reject(errors(err));
        });
    });
  },
  USER_PROFILE_REQUEST: ({ commit, dispatch }, token) => {
    const newToken = Cookies.get('user-token') || globalStore.token || token;
    if (newToken === 'TokenExpiredError') {
      commit('auth/AUTH_TOKEN_EXPIRED', {}, { root: true });
      commit('RESET_PROFILE_STATE_DATA');
    } else {
      commit('USER_PROFILE_REQUEST');
      return new Promise((resolve, reject) => {
        return getUserProfile(newToken)
          .then((resp) => {
            if (resp) {
              if (resp.data) {
                if (resp.data.user.companyID) {
                  Cookies.set('companyID', resp.data.user.companyID);
                }
                commit('USER_PROFILE_SUCCESS', resp.data.user);
                resolve(resp.data.user);
              }
            }
          })
          .catch((err) => {
            console.error(err);
            reject(errors(err));
          });
      });
    }
  },
  UPDATE_USER_PROFILE: ({ commit, dispatch }, user) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('UPDATE_USER_PROFILE');
    return new Promise((resolve, reject) => {
      return updateCurrentUserProfile(token, user)
        .then((resp) => {
          if (resp.data.success) {
            commit('UPDATE_USER_PROFILE_SUCCESS', user);
            resolve(resp);
          } else {
            commit('UPDATE_USER_PROFILE_ERROR');
          }
        })
        .catch((err) => {
          reject(errors(err));
        });
    });
  },
  CHANGE_PASSWORD_REQUEST: ({ commit, dispatch }, { password, token }) => {
    const newToken = Cookies.get('user-token') || globalStore.token || token;
    return new Promise((resolve, reject) => {
      commit('CHANGE_PASSWORD_REQUEST');
      return changePassword(newToken, password)
        .then(async (resp) => {
          if (resp) {
            commit('CHANGE_PASSWORD_SUCCESS', resp);
            resolve(resp);
          }
        })
        .catch((err) => {
          commit('CHANGE_PASSWORD_ERROR', err);
          reject(errors(err));
        });
    });
  },
  UPDATE_USER_STATUS: ({ commit, dispatch }, { userID, status }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_USER_PROFILE');
    return new Promise((resolve, reject) => {
      return updateStatusUser(token, userID, status)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_USER_PROFILE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_USER_PROFILE_ERROR');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_USER_PROFILE_ERROR');
          reject(errors(err));
        });
    });
  },
  RESET_PASSWORD_REQUEST: ({ commit, dispatch }, userID) => {
    return new Promise((resolve, reject) => {
      const token = Cookies.get('user-token') || globalStore.token || token;
      commit('RESET_PASSWORD_REQUEST');
      return resetPassword(token, userID)
        .then(async (resp) => {
          if (resp) {
            commit('RESET_PASSWORD_SUCCESS', resp);
            resolve(resp);
          }
        })
        .catch((err) => {
          commit('RESET_PASSWORD_ERROR', err);
          reject(errors(err));
        });
    });
  },
  GET_USER_DASHBOARD: ({ commit, dispatch }, { pooling = false, companyID, userGroup }) => {
    const token = Cookies.get('user-token') || globalStore.token;
    if (!pooling) {
      commit('GET_USER_DASHBOARD');
    }
    return new Promise((resolve, reject) => {
      return getDashboard(token, companyID, userGroup)
        .then((resp) => {
          if (resp.data) {
            commit('GET_USER_DASHBOARD_SUCCESS', resp.data.data);
            resolve(resp.data);
          }
        })
        .catch((err) => {
          commit('GET_USER_DASHBOARD_ERROR');
          console.error(err.response);
          reject(errors(err));
        });
    });
  },
  FORGOT_PASSWORD_REQUEST: ({ commit, dispatch }, email) => {
    return new Promise((resolve, reject) => {
      commit('FORGOT_PASSWORD_REQUEST');
      return forgotPassword(email)
        .then(async (resp) => {
          if (resp) {
            commit('FORGOT_PASSWORD_SUCCESS', resp);
            resolve(resp);
          }
        })
        .catch((err) => {
          commit('FORGOT_PASSWORD_ERROR', err);
          reject(errors(err));
        });
    });
  },
  CONFIRM_PASSWORD_TOKEN: ({ commit, dispatch }, token) => {
    return new Promise((resolve, reject) => {
      commit('CONFIRM_PASSWORD_TOKEN_REQUEST');
      return confirmPasswordToken(token)
        .then(async (resp) => {
          if (resp) {
            commit('CONFIRM_PASSWORD_TOKEN_SUCCESS', resp);
            resolve(resp);
          }
        })
        .catch((err) => {
          const error = String(err.response.data.error.split(' ').join('_'));
          err.response.data.error = error;
          commit('CONFIRM_PASSWORD_TOKEN_ERROR', err);
          reject(errors(err));
        });
    });
  },
  REGISTER_USER_BY_LINK: ({ commit, dispatch }, data) => {
    commit('REGISTER_USER_REQUEST');
    return new Promise((resolve, reject) => {
      return registerUserByLink(data)
        .then((resp) => {
          if (resp) {
            commit('REGISTER_USER_SUCCESS', resp);
            resolve(resp);
          } else {
            commit('REGISTER_USER_FAILED');
            reject('Error in creating user');
          }
        })
        .catch((err) => {
          commit('REGISTER_USER_FAILED');
          reject(errors(err));
        });
    });
  },
  CONFIRM_REGISTER_LINK: ({ commit, dispatch }, hashID) => {
    return new Promise((resolve, reject) => {
      commit('CONFIRM_REGISTER_LINK_REQUEST');
      return confirmLinkRegistration(hashID)
        .then(async (resp) => {
          if (resp) {
            commit('CONFIRM_REGISTER_LINK_SUCCESS', resp);
            resolve(resp);
          }
        })
        .catch((err) => {
          const error = String(err.response.data.error.split(' ').join('_'));
          err.response.data.error = error;
          commit('CONFIRM_REGISTER_LINK_ERROR', err);
          reject(errors(err));
        });
    });
  },
  GET_CHANNEL_INFO: ({ commit, dispatch }, hashID) => {
    return new Promise((resolve, reject) => {
      commit('CONFIRM_REGISTER_LINK_REQUEST');
      return getChannelInfo(hashID)
        .then(async (resp) => {
          if (resp) {
            commit('CONFIRM_REGISTER_LINK_SUCCESS', resp);
            resolve(resp);
          }
        })
        .catch((err) => {
          const error = String(err.response.data.error.split(' ').join('_'));
          err.response.data.error = error;
          commit('CONFIRM_REGISTER_LINK_ERROR', err);
          reject(errors(err));
        });
    });
  },
  SEND_SMS_REGISTER: ({ commit, dispatch }, { token, type }) => {
    return new Promise((resolve, reject) => {
      commit('CONFIRM_REGISTER_LINK_REQUEST');
      return sendSmsRegistration(token, type)
        .then(async (resp) => {
          if (resp) {
            commit('CONFIRM_REGISTER_LINK_SUCCESS', resp);
            resolve(resp);
          }
        })
        .catch((err) => {
          const error = String(err.response.data.error.split(' ').join('_'));
          err.response.data.error = error;
          commit('CONFIRM_REGISTER_LINK_ERROR', err);
          reject(errors(err));
        });
    });
  },
};

export default actions;
