const mutations = {
  GET_SETTINGS_REQUEST: (state) => {
    state.success = false;
    state.error = false;
    state.settingsList = [];
  },
  GET_SETTINGS_SUCCESS: (state, data) => {
    state.success = true;
    state.error = false;
    state.settingsList = data;
  },
  GET_SETTINGS_FAILED: (state) => {
    state.success = false;
    state.error = true;
  },
  GET_SETTINGS_VOBO_CONNECT: (state) => {
    state.voboLoading = true;
  },
  GET_SETTINGS_VOBO_CONNECT_DONE: (state) => {
    state.voboLoading = false;
  },
  GET_SETTINGS_ACCESS_CODE: (state) => {
    state.accessLoading = true;
  },
  GET_SETTINGS_ACCESS_CODE_DONE: (state) => {
    state.accessLoading = false;
  },
  GET_SETTINGS_AUTO_PHONE: (state) => {
    state.autoPhoneLoading = true;
  },
  GET_SETTINGS_AUTO_PHONE_DONE: (state) => {
    state.autoPhoneLoading = false;
  },
  GET_SETTINGS_MAX_NOTIF: (state) => {
    state.notifMaxLoading = true;
  },
  GET_SETTINGS_MAX_NOTIF_DONE: (state) => {
    state.notifMaxLoading = false;
  },
  GET_SETTINGS_BACKGROUND: (state) => {
    state.backgroundLoading = true;
  },
  GET_SETTINGS_BACKGROUND_DONE: (state) => {
    state.backgroundLoading = false;
  },
  GET_SETTINGS_SOCIAL_MEDIA: (state) => {
    state.socialMediaLoading = true;
  },
  GET_SETTINGS_SOCIAL_MEDIA_DONE: (state) => {
    state.socialMediaLoading = false;
  },
  GET_SETTINGS_CUSTOM_MENU: (state) => {
    state.customMenuLoading = true;
  },
  GET_SETTINGS_CUSTOM_MENU_DONE: (state) => {
    state.customMenuLoading = false;
  },
  GET_SETTINGS_CUSTOM_MENU_SAVE: (state) => {
    state.customMenuLoadingSave = true;
  },
  GET_SETTINGS_CUSTOM_MENU_SAVE_DONE: (state) => {
    state.customMenuLoadingSave = false;
  },
  GET_SETTINGS_FEATURE_LINK: (state) => {
    state.featureLinkLoading = true;
  },
  GET_SETTINGS_FEATURE_LINK_DONE: (state) => {
    state.featureLinkLoading = false;
  },
  GET_SETTINGS_CHANNEL: (state) => {
    state.channelLoading = true;
  },
  GET_SETTINGS_CHANNEL_DONE: (state) => {
    state.channelLoading = false;
  },
  GET_SETTINGS_APP_DISPLAY: (state) => {
    state.appDisplayLoading = true;
  },
  GET_SETTINGS_APP_DISPLAY_DONE: (state) => {
    state.appDisplayLoading = false;
  },
  GET_SETTINGS_CUSTOM_FIELDS: (state) => {
    state.customFieldsLoading = true;
  },
  GET_SETTINGS_CUSTOM_FIELDS_DONE: (state) => {
    state.customFieldsLoading = false;
  },
  GET_REPLICA_BUILD: (state) => {
    state.replicaBuildLoading = true;
  },
  GET_REPLICA_BUILD_DONE: (state, data) => {
    state.replicaBuildLoading = false;
    state.selectedReplicaBuild = data;
  },
  GET_REPLICA_BUILD_NAMES: (state) => {
    state.replicaBuildNamesLoading = true;
  },
  GET_REPLICA_BUILD_NAMES_DONE: (state, data) => {
    state.replicaBuildNamesLoading = false;
    state.replicaBuildsList = data;
  },
  GET_EXTERNAL_APIS: (state) => {
    state.externalApisListLoading = true;
  },
  GET_EXTERNAL_APIS_DONE: (state, data) => {
    state.externalApisListLoading = false;
    state.externalApisList = data;
  },
  SET_SELECTED_REPLICA_BUILD: (state, data) => {
    const { name, id } = data;
    state.selectedReplicaBuildName = name;
    state.selectedReplicaBuildID = id;
  },
  PREP_REPLICA_BUILD_SETTINGS: (state) => {
    (state.selectedReplicaBuild = {
      iosVersion: '',
      androidVersion: '',
      iosAppUrl: '',
      androidAppUrl: '',
      androidAPN: '',
      iosAPN: '',
      iosIAS: '',
      deepLink: '',
      webAPIKey: '',
      channels: [],
    }),
      (state.selectedReplicaBuildName = null);
    state.selectedReplicaBuildID = null;
  },
  RESET_DATA: (state) => {
    state.voboLoading = false;
    state.accessLoading = false;
    state.backgroundLoading = false;
    state.autoPhoneLoading = false;
    state.notifMaxLoading = false;
    state.socialMediaLoading = false;
    state.success = false;
    state.error = false;
    (state.settingsList = []), (state.customMenuLoading = false);
    state.featureLinkLoading = false;
    state.channelLoading = false;
    state.appDisplayLoading = false;
    state.replicaBuildLoading = false;
    state.replicaBuildNamesLoading = false;
    state.replicaBuildsList = [];
    state.selectedReplicaBuildName = null;
    state.selectedReplicaBuildID = null;
    state.selectedReplicaBuild = {
      iosVersion: '',
      androidVersion: '',
      iosAppUrl: '',
      androidAppUrl: '',
      androidAPN: '',
      iosAPN: '',
      iosIAS: '',
      deepLink: '',
      webAPIKey: '',
      channels: [],
    };
  },
};

export default mutations;
