const state = {
  loading: false,
  loadedOnce: false,
  unionLinkLoadedOnce: false,
  success: false,
  error: false,
  errorUnionLink: false,
  unionLinkList: [],
  selectedUnionLink: {},
  selectedUnionLinkSuccess: false,
  selectedUnionLinkError: false,
  linkTypeLoading: false,
  linkTypeError: false,
  linkTypeSuccess: false,
  linkTypes: [],
};

export default state;
