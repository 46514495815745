import Vue from 'vue';
import {
  getSettings,
  getSettingsByCPID,
  createSettings,
  deleteAutoPhone,
  updateSettingChannel,
  updateReplicaBuildSetting,
  getAllReplicaBuildNames,
  getReplicaBuild,
  updateReplicaBuild,
  getAllExternalApis,
} from '@/api/request/settings';
import { uploadFile } from '@/api/request/tools';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment';

Vue.use(Cookies);

const actions = {
  GET_SETTINGS: ({ commit, dispatch }, companyID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_SETTINGS_REQUEST');
    return new Promise((resolve, reject) => {
      return getSettings(token, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let settings = resp.data.data;

              commit('GET_SETTINGS_SUCCESS', settings);
              resolve(settings);
            }
          }
        })
        .catch((err) => {
          commit('GET_SETTINGS_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_SETTINGS_BY_CP_ID: ({ commit, dispatch }, { settingName, contentProviderID, companyID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;

    commit('GET_SETTINGS_REQUEST');
    if (settingName === 'menu-color') {
      commit('GET_SETTINGS_BACKGROUND');
    } else if (settingName === 'social-media') {
      commit('GET_SETTINGS_SOCIAL_MEDIA');
    } else if (settingName === 'custom-menu') {
      commit('GET_SETTINGS_CUSTOM_MENU');
    } else if (settingName === 'feature-link') {
      commit('GET_SETTINGS_FEATURE_LINK');
    } else if (settingName === 'channel') {
      commit('GET_SETTINGS_CHANNEL');
      commit('GET_SETTINGS_APP_DISPLAY');
    } else if (settingName === 'app-display') {
      commit('GET_SETTINGS_APP_DISPLAY');
    } else if (settingName === 'custom-fields') {
      commit('GET_SETTINGS_CUSTOM_FIELDS');
    }

    return new Promise((resolve, reject) => {
      return getSettingsByCPID(token, settingName, contentProviderID, companyID)
        .then((resp) => {
          console.log('getSettingsByCPID', resp);
          if (resp) {
            if (resp.data) {
              commit('GET_SETTINGS_SUCCESS', resp.data.data);
              if (settingName === 'menu-color') {
                commit('GET_SETTINGS_BACKGROUND_DONE');
              } else if (settingName === 'social-media') {
                commit('GET_SETTINGS_SOCIAL_MEDIA_DONE');
              } else if (settingName === 'custom-menu') {
                commit('GET_SETTINGS_CUSTOM_MENU_DONE');
              } else if (settingName === 'feature-link') {
                commit('GET_SETTINGS_FEATURE_LINK_DONE');
              } else if (settingName === 'channel') {
                commit('GET_SETTINGS_CHANNEL_DONE');
                commit('GET_SETTINGS_APP_DISPLAY_DONE');
              } else if (settingName === 'app-display') {
                commit('GET_SETTINGS_APP_DISPLAY_DONE');
              } else if (settingName === 'custom-fields') {
                commit('GET_SETTINGS_CUSTOM_FIELDS_DONE');
              }

              resolve(resp.data.data ? resp.data.data : []);
            }
          }
        })
        .catch((err) => {
          commit('GET_SETTINGS_FAILED');
          if (settingName === 'menu-color') {
            commit('GET_SETTINGS_BACKGROUND_DONE');
          } else if (settingName === 'social-media') {
            commit('GET_SETTINGS_SOCIAL_MEDIA_DONE');
          } else if (settingName === 'custom-menu') {
            commit('GET_SETTINGS_CUSTOM_MENU_DONE');
          } else if (settingName === 'feature-link') {
            commit('GET_SETTINGS_FEATURE_LINK_DONE');
          } else if (settingName === 'channel') {
            commit('GET_SETTINGS_CHANNEL_DONE');
            commit('GET_SETTINGS_APP_DISPLAY_DONE');
          } else if (settingName === 'app-display') {
            commit('GET_SETTINGS_APP_DISPLAY_DONE');
          } else if (settingName === 'custom-fields') {
            commit('GET_SETTINGS_CUSTOM_FIELDS_DONE');
          }

          reject(errors(err));
        });
    });
  },
  CREATE_SETTINGS: ({ commit, dispatch }, { settingName, settingsData }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;

    commit('GET_SETTINGS_REQUEST');
    if (settingName === 'vobo-connect') {
      commit('GET_SETTINGS_VOBO_CONNECT');
    } else if (settingName === 'access-code') {
      commit('GET_SETTINGS_ACCESS_CODE');
    } else if (settingName === 'auto-phone-number') {
      commit('GET_SETTINGS_AUTO_PHONE');
    } else if (settingName === 'max-notification') {
      commit('GET_SETTINGS_MAX_NOTIF');
    } else if (settingName === 'menu-color') {
      commit('GET_SETTINGS_BACKGROUND');
    } else if (settingName === 'social-media') {
      commit('GET_SETTINGS_SOCIAL_MEDIA');
    } else if (settingName === 'custom-menu') {
      commit('GET_SETTINGS_CUSTOM_MENU_SAVE');
    } else if (settingName === 'feature-link') {
      commit('GET_SETTINGS_FEATURE_LINK');
    } else if (settingName === 'channel') {
      commit('GET_SETTINGS_CHANNEL');
    } else if (settingName === 'app-display') {
      commit('GET_SETTINGS_APP_DISPLAY');
    } else if (settingName === 'custom-fields') {
      commit('GET_SETTINGS_CUSTOM_FIELDS');
    }

    return new Promise((resolve, reject) => {
      return createSettings(token, settingName, settingsData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('GET_SETTINGS_SUCCESS', resp.data);
              if (settingName === 'vobo-connect') {
                commit('GET_SETTINGS_VOBO_CONNECT_DONE');
              } else if (settingName === 'access-code') {
                commit('GET_SETTINGS_ACCESS_CODE_DONE');
              } else if (settingName === 'auto-phone-number') {
                commit('GET_SETTINGS_AUTO_PHONE_DONE');
              } else if (settingName === 'max-notification') {
                commit('GET_SETTINGS_MAX_NOTIF_DONE');
              } else if (settingName === 'menu-color') {
                commit('GET_SETTINGS_BACKGROUND_DONE');
              } else if (settingName === 'social-media') {
                commit('GET_SETTINGS_SOCIAL_MEDIA_DONE');
              } else if (settingName === 'custom-menu') {
                commit('GET_SETTINGS_CUSTOM_MENU_SAVE_DONE');
              } else if (settingName === 'feature-link') {
                commit('GET_SETTINGS_FEATURE_LINK_DONE');
              } else if (settingName === 'channel') {
                commit('GET_SETTINGS_CHANNEL_DONE');
              } else if (settingName === 'app-display') {
                commit('GET_SETTINGS_APP_DISPLAY_DONE');
              } else if (settingName === 'custom-fields') {
                commit('GET_SETTINGS_CUSTOM_FIELDS_DONE');
              }

              resolve(resp);
            } else {
              commit('GET_SETTINGS_FAILED');
              if (settingName === 'vobo-connect') {
                commit('GET_SETTINGS_VOBO_CONNECT_DONE');
              } else if (settingName === 'access-code') {
                commit('GET_SETTINGS_ACCESS_CODE_DONE');
              } else if (settingName === 'auto-phone-number') {
                commit('GET_SETTINGS_AUTO_PHONE_DONE');
              } else if (settingName === 'max-notification') {
                commit('GET_SETTINGS_MAX_NOTIF_DONE');
              } else if (settingName === 'menu-color') {
                commit('GET_SETTINGS_BACKGROUND_DONE');
              } else if (settingName === 'social-media') {
                commit('GET_SETTINGS_SOCIAL_MEDIA_DONE');
              } else if (settingName === 'custom-menu') {
                commit('GET_SETTINGS_CUSTOM_MENU_SAVE_DONE');
              } else if (settingName === 'feature-link') {
                commit('GET_SETTINGS_FEATURE_LINK_DONE');
              } else if (settingName === 'channel') {
                commit('GET_SETTINGS_CHANNEL_DONE');
              } else if (settingName === 'app-display') {
                commit('GET_SETTINGS_APP_DISPLAY_DONE');
              } else if (settingName === 'custom-fields') {
                commit('GET_SETTINGS_CUSTOM_FIELDS_DONE');
              }
            }
          }
        })
        .catch((err) => {
          commit('GET_SETTINGS_FAILED');
          if (settingName === 'vobo-connect') {
            commit('GET_SETTINGS_VOBO_CONNECT_DONE');
          } else if (settingName === 'access-code') {
            commit('GET_SETTINGS_ACCESS_CODE_DONE');
          } else if (settingName === 'auto-phone-number') {
            commit('GET_SETTINGS_AUTO_PHONE_DONE');
          } else if (settingName === 'max-notification') {
            commit('GET_SETTINGS_MAX_NOTIF_DONE');
          } else if (settingName === 'menu-color') {
            commit('GET_SETTINGS_BACKGROUND');
          } else if (settingName === 'social-media') {
            commit('GET_SETTINGS_SOCIAL_MEDIA');
          } else if (settingName === 'custom-menu') {
            commit('GET_SETTINGS_CUSTOM_MENU_SAVE_DONE');
          } else if (settingName === 'feature-link') {
            commit('GET_SETTINGS_FEATURE_LINK_DONE');
          } else if (settingName === 'channel') {
            commit('GET_SETTINGS_CHANNEL_DONE');
          } else if (settingName === 'app-display') {
            commit('GET_SETTINGS_APP_DISPLAY_DONE');
          } else if (settingName === 'custom-fields') {
            commit('GET_SETTINGS_CUSTOM_FIELDS_DONE');
          }

          reject(errors(err));
        });
    });
  },
  UPDATE_SETTING_CHANNEL: ({ commit, dispatch }, { formData, cpID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_SETTINGS_CHANNEL');
    return new Promise((resolve, reject) => {
      return updateSettingChannel(token, formData, cpID)
        .then((resp) => {
          if (resp) {
            commit('GET_SETTINGS_CHANNEL_DONE');
            resolve(resp);
          }
        })
        .catch((err) => {
          commit('GET_SETTINGS_CHANNEL_DONE');
          reject(errors(err));
        });
    });
  },
  DELETE_AUTO_PHONE: ({ commit, dispatch }, autoPhoneID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_SETTINGS_AUTO_PHONE');
    return new Promise((resolve, reject) => {
      return deleteAutoPhone(token, autoPhoneID)
        .then((resp) => {
          if (resp) {
            commit('GET_SETTINGS_AUTO_PHONE_DONE');
            resolve(resp);
          }
        })
        .catch((err) => {
          commit('GET_SETTINGS_AUTO_PHONE_DONE');
          reject(errors(err));
        });
    });
  },
  UPDATE_REPLICA_SETTINGS: ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_REPLICA_BUILD');
    return new Promise((resolve, reject) => {
      return updateReplicaBuildSetting(token, formData)
        .then((resp) => {
          if (resp) {
            commit('GET_REPLICA_BUILD_DONE', null);
            resolve(resp);
          }
        })
        .catch((err) => {
          commit('GET_REPLICA_BUILD_DONE', null);
          reject(errors(err));
        });
    });
  },
  GET_ALL_REPLICA_BUILD_NAMES: ({ commit, dispatch }, companyID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_REPLICA_BUILD_NAMES');
    return new Promise((resolve, reject) => {
      return getAllReplicaBuildNames(token, companyID)
        .then((resp) => {
          if (resp) {
            let replicaBuildsList = resp.data.data;
            commit('GET_REPLICA_BUILD_NAMES_DONE', replicaBuildsList);
            resolve(replicaBuildsList);
          } else {
            commit('GET_REPLICA_BUILD_NAMES_DONE', []);
            reject(resp);
          }
        })
        .catch((err) => {
          commit('GET_REPLICA_BUILD_NAMES_DONE', []);
          reject(errors(err));
        });
    });
  },
  SELECT_REPLICA_BUILD: ({ commit, dispatch }, data) => {
    const { id } = data;
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_REPLICA_BUILD');
    commit('SET_SELECTED_REPLICA_BUILD', data);
    return new Promise((resolve, reject) => {
      return getReplicaBuild(token, id)
        .then((resp) => {
          if (resp) {
            let replicaBuild = resp.data.data[0];
            commit('GET_REPLICA_BUILD_DONE', replicaBuild);
            resolve(replicaBuild);
          } else {
            commit('GET_REPLICA_BUILD_DONE', null);
            reject(resp);
          }
        })
        .catch((err) => {
          commit('GET_REPLICA_BUILD_DONE', {});
          reject(errors(err));
        });
    });
  },
  UPDATE_REPLICA_BUILD: ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_REPLICA_BUILD');
    return new Promise((resolve, reject) => {
      return updateReplicaBuild(token, formData)
        .then((resp) => {
          if (resp) {
            let replicaBuild = resp.data.data;
            commit('GET_REPLICA_BUILD_DONE', replicaBuild);
            resolve(resp);
          } else {
            commit('GET_REPLICA_BUILD_DONE', null);
            reject(resp);
          }
        })
        .catch((err) => {
          commit('GET_REPLICA_BUILD_DONE', null);
          reject(errors(err));
        });
    });
  },
  GET_ALL_EXTERNAL_APIS: ({ commit, _ }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_EXTERNAL_APIS');
    return new Promise((resolve, reject) => {
      return getAllExternalApis(token)
        .then((resp) => {
          if (resp) {
            let allExternalApis = [];
            resp.data.data.map((oneApi) => {
              allExternalApis.push(oneApi.config);
            });
            commit('GET_EXTERNAL_APIS_DONE', allExternalApis);
            resolve(allExternalApis);
          } else {
            commit('GET_EXTERNAL_APIS_DONE', []);
            reject(resp);
          }
        })
        .catch((err) => {
          commit('GET_EXTERNAL_APIS_DONE', []);
          reject(errors(err));
        });
    });
  },
};

export default actions;
