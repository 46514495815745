import Vue from 'vue';
import { getWaveFiles, getWaveFileByID, getWaveFileByCPId, createWaveFile, updateWaveFile, deleteWaveFile } from '@/api/request/waveManagement';
import { uploadFile } from '@/api/request/tools';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment-timezone';

Vue.use(Cookies);

const actions = {
  GET_WAVE_FILES: ({ commit, dispatch }, companyID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_WAVE_FILES_REQUEST');
    return new Promise((resolve, reject) => {
      return getWaveFiles(token, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let waveFileSource = resp.data.data;

              let waveFiles = waveFileSource.map((value) => {
                let data = {
                  waveFileID: value._id,
                  shortDescription: value.short_description,
                  longDescription: value.long_description,
                  companyID: value.companyID || 'All',
                  filename: value.filename,
                  url: value.url,
                  dateCreated: moment(new Date(value.dateCreated)).tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:SS'),
                  actions: value._id,
                };
                return data;
              });

              waveFiles = waveFiles.sort(function (a, b) {
                return new Date(b.dateCreated) - new Date(a.dateCreated);
              });

              commit('GET_WAVE_FILES_SUCCESS', waveFiles);
              resolve(waveFiles);
            }
          }
        })
        .catch((err) => {
          commit('GET_WAVE_FILES_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_WAVE_FILE_BY_ID: ({ commit, dispatch }, waveFileID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_WAVE_FILES_REQUEST');
    return new Promise((resolve, reject) => {
      return getWaveFileByID(token, waveFileID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_WAVE_FILE_SUCCESS', resp.data.data);
              resolve(resp.data.data);
            } else {
              commit('GET_WAVE_FILES_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_WAVE_FILES_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_WAVE_FILE_BY_CP_ID: ({ commit, dispatch }, contentProviderID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_WAVE_FILES_REQUEST');
    return new Promise((resolve, reject) => {
      return getWaveFileByCPId(token, contentProviderID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let waveFileSource = resp.data.data;

              let waveFiles = waveFileSource.map((value) => {
                let data = {
                  waveFileID: value._id,
                  shortDescription: value.short_description,
                  longDescription: value.long_description,
                  companyID: value.companyID || 'All',
                  filename: value.filename,
                  url: value.url,
                  dateCreated: moment(new Date(value.dateCreated)).format('YYYY-MM-DD HH:mm:SS'),
                  actions: value._id,
                };
                return data;
              });

              waveFiles = waveFiles.sort(function (a, b) {
                return new Date(b.dateCreated) - new Date(a.dateCreated);
              });

              commit('GET_WAVE_FILES_SUCCESS', waveFiles);
              resolve(waveFiles);
            }
          }
        })
        .catch((err) => {
          commit('GET_WAVE_FILES_FAILED');
          reject(errors(err));
        });
    });
  },
  CREATE_WAVE_FILE: ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_WAVE_FILES_REQUEST');
    return new Promise((resolve, reject) => {
      return createWaveFile(token, formData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_WAVE_FILE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_WAVE_FILES_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_WAVE_FILES_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_WAVE_FILE: ({ commit, dispatch }, { formData, waveFileID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_WAVE_FILES_REQUEST');
    return new Promise((resolve, reject) => {
      return updateWaveFile(token, formData, waveFileID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_WAVE_FILE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_WAVE_FILES_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_WAVE_FILES_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_WAVE_FILE: ({ commit, dispatch }, waveFileID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_WAVE_FILES_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteWaveFile(token, waveFileID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_WAVE_FILE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_WAVE_FILES_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_WAVE_FILES_FAILED');
          reject(errors(err));
        });
    });
  },
};

export default actions;
