<template>
  <base-nav
    ref="navColor"
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{
      'default navbar-dark': type === 'default',
      'bg-vobo default navbar-dark': backgroundColor === '',
    }"
    :style="`background: ${backgroundColor} !important`">
    <div class="align-items-left pull-left">
      <li v-if="currentUserGroup === 2 && cp" class="nav-item pr-3 d-none d-sm-block">
        <div class="cpImageContainer">
          <span class="avatar avatar-sm transparent-bg pull-left">
            <img :alt="cp.name" :src="cp.image" />
          </span>
          <div class="media-body ml-2 d-lg-block navbar-user pull-left cpNameContainer">
            <span v-if="cp" class="mb-0 text-sm font-weight-bold" :class="{ middleFloat: cp.name.length <= 25 }">{{ cp.name }}</span>
          </div>
        </div>
      </li>

      <a v-else class="navbar-brand" href="/">
        <span v-if="logo.src"><img ref="logo" :src="logo.src" class="navbar-brand-img" :alt="logo.title" /> {{ logo.name }}</span>
        <span v-else-if="logo.srcDefault"><img :src="logo.srcDefault" class="navbar-brand-img" :alt="logo.titleDefault" /></span>
        <span v-else>{{ logo.name }}</span>
      </a>
    </div>
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
    </ul>
    <ul class="navbar-nav">
      <li class="nav-item dropdown">
        <base-dropdown title-tag="a" :has-toggle="false" :menu-on-right="true" class="nav-link pr-0">
          <div v-if="!pageLoading" slot="title" class="media align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="/img/avatars/user-avatar.png" />
            </span>
            <div class="media-body ml-2 d-lg-block navbar-user">
              <span v-if="firstname && lastname" class="mb-0 text-sm font-weight-bold">{{ firstname }} {{ lastname }}</span>
              <span v-else class="mb-0 text-sm font-weight-bold">{{ fullName }}</span>
              <br />
              <span class="mb-0 text-sm font-weight-bold">{{ email }}</span>
            </div>
          </div>
          <div v-if="pageLoading" slot="title" class="media align-items-center">
            <LoadingAccountHeader style="width: 180px" />
          </div>
          <template>
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <router-link to="/user/profile" class="dropdown-item">
              <i class="ni ni-circle-08"></i>
              <span>My profile</span>
            </router-link>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" @click="logout">
              <i class="fas fa-sign-out-alt"></i>
              <span>Logout</span>
            </a>
          </template>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
import Vue from 'vue';
import { BaseNav } from '@/components';
import LoadingAccountHeader from '@/components/ContentLoader/AccountHeader';
import Cookies from 'js-cookie';

Vue.use(Cookies);

export default {
  name: 'DashboardNavbar',
  components: {
    // CollapseTransition,
    // Modal,
    LoadingAccountHeader,
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description: 'Look of the dashboard navbar. Default (Green) or light (gray)',
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      channelBackgroundColor: undefined,
    };
  },
  computed: {
    companyID: {
      get: function () {
        return this.$store.state.user.companyID || Cookies.get('companyID');
      },
      set: function (newValue) {
        return newValue;
      },
    },
    backgroundColor: {
      get: function () {
        if (this.currentUserGroup && this.currentUserGroup == 2) {
          if (this.channelBackgroundColor) {
            Cookies.set('adminColor', this.channelBackgroundColor);
            return this.channelBackgroundColor;
          } else {
            this.fetchMenuColor();
          }
        } else {
          return Cookies.get('adminColor');
        }
      },
      set: function (newValue) {
        return newValue;
      },
    },
    logo() {
      let company = this.$store.state.user.company || null;

      if (this.selectedCompany == null && (company === null || company === '')) {
        return {
          srcDefault: '/img/brand/logo.png',
          nameDefault: 'Vobo',
          titleDefault: 'Vobo',
        };
      } else {
        return {
          src: company !== null ? (typeof company.logo !== 'undefined' ? company.logo : '') : '/img/brand/logo.png',
          name: company !== null ? company.name : '',
          title: company !== null ? company.name : '',
        };
      }
    },
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    pageLoading() {
      const loaded = this.$store.state.user.profileLoaded;
      return !loaded ? this.$store.getters['user/loading'] : false;
    },
    fullName() {
      const fullName = this.$store.state.user.fullName;
      return typeof fullName !== 'undefined' ? fullName : '';
    },
    firstname() {
      const firstname = this.$store.state.user.firstname;
      return typeof firstname !== 'undefined' ? firstname : '';
    },
    lastname() {
      const lastname = this.$store.state.user.lastname;
      return typeof lastname !== 'undefined' ? lastname : '';
    },
    email() {
      const email = this.$store.state.user.email;
      return typeof email !== 'undefined' ? email : '';
    },
    currentUserGroup() {
      return this.$store.state.user.userGroup || null;
    },
    cp() {
      let company = this.$store.state.user.company || null;
      var logo = '';
      if (this.selectedCompany == null && (company === null || company === '')) {
        logo = '/img/brand/logo.png';
      } else {
        logo = company !== null ? (typeof company.logo !== 'undefined' ? company.logo : '') : '/img/brand/logo.png';
      }

      return {
        image: this.$store.state.user.image || logo,
        name: this.$store.state.user.cpName || '',
      };
    },
  },
  mounted() {},
  methods: {
    fetchMenuColor() {
      let companyID = Cookies.get('companyID') || '';

      this.$store
        .dispatch('settings/GET_SETTINGS_BY_CP_ID', {
          settingName: 'menu-color',
          contentProviderID: this.$store.state.user.contentProviderID,
          companyID: companyID,
        })
        .then((resp) => {
          console.log(resp);
          if (resp[0]) {
            this.channelBackgroundColor = resp[0].config.background_image_hex;
            Cookies.set('adminColor', this.channelBackgroundColor);
          }
        });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout() {
      const location = this.companyID || 'login';
      this.$store.dispatch('auth/AUTH_LOGOUT').then(() => {
        this.$router.push(`/${location}`);
      });
    },
  },
};
</script>
<style lang="scss">
.transparent-bg {
  background-color: transparent !important;
}
.cpImageContainer {
  background: #ffffff !important;
  float: left;
  padding: 5px 8px;
  border-radius: 5px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  border: 1px solid #0b3086;
  .cpNameContainer {
    max-width: 190px !important;
    color: #0b3086;
  }
  .avatar img {
    width: 100%;
    height: 100%;
  }
}

.middleFloat {
  padding-top: 10px;
}
</style>
