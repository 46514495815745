import Vue from 'vue';
import {
  getGroupsByCPId,
  getGroupsByAllCPId,
  getGroupsById,
  createGroup,
  updateGroup,
  deleteGroup,
  getMobileUsers,
  updateMobileUsers,
  deleteMobileUser,
  getMobileRecoveredUsers,
  uploadUsers,
} from '@/api/request/users';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment';

Vue.use(Cookies);

const actions = {
  GET_USER_GROUP_BY_CP_ID: ({ commit, dispatch }, contentProviderID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_USER_GROUP_REQUEST');
    return new Promise((resolve, reject) => {
      return getGroupsByCPId(token, contentProviderID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let userGroupSource = resp.data.data;

              let userGroups = userGroupSource.map((value) => {
                let data = {
                  userGroupID: value._id,
                  name: value.name,
                  type: value.type || 'private',
                  description: value.description || '',
                  companyID: value.companyID || 'All',
                  cpID: value.cp_id,
                  createdBy: value.createdName,
                  createdByID: value.createdBy,
                  createdUserGroup: value.createdUserGroup,
                  dateCreated: moment(new Date(value.dateCreated)).format('YYYY-MM-DD HH:mm:SS'),
                  users: value.users,
                  actions: value._id,
                  cp_name: value.cp_name,
                };
                return data;
              });

              commit('GET_USER_GROUP_SUCCESS', userGroups);
              resolve(userGroups);
            }
          }
        })
        .catch((err) => {
          commit('GET_USER_GROUP_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_USER_GROUP_BY_ALL_CP_ID: ({ commit, dispatch }, contentProviderID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_USER_GROUP_REQUEST');
    return new Promise((resolve, reject) => {
      return getGroupsByAllCPId(token, contentProviderID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let userGroupSource = resp.data.data;

              let userGroups = userGroupSource.map((value) => {
                let data = {
                  userGroupID: value._id,
                  name: value.name,
                  type: value.type || 'private',
                  description: value.description || '',
                  companyID: value.companyID || 'All',
                  cpID: value.cp_id,
                  createdBy: value.createdName,
                  createdByID: value.createdBy,
                  createdUserGroup: value.createdUserGroup,
                  dateCreated: moment(new Date(value.dateCreated)).format('YYYY-MM-DD HH:mm:SS'),
                  users: value.users,
                  actions: value._id,
                };
                return data;
              });

              commit('GET_USER_GROUP_SUCCESS', userGroups);
              resolve(userGroups);
            }
          }
        })
        .catch((err) => {
          commit('GET_USER_GROUP_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_USER_GROUP_BY_GROUP_ID: ({ commit, dispatch }, userGroupID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_USER_GROUP_REQUEST');
    return new Promise((resolve, reject) => {
      return getGroupsById(token, userGroupID)
        .then((resp) => {
          if (resp) {
            commit('SET_USER_GROUP_BY_GROUP_ID', resp.data);
            resolve(resp.data);
          } else {
            commit('GET_USER_GROUP_FAILED');
          }
        })
        .catch((err) => {
          commit('GET_USER_GROUP_FAILED');
          reject(errors(err));
        });
    });
  },
  CREATE_USER_GROUP: ({ commit, dispatch }, groupData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_USER_GROUP_REQUEST');
    return new Promise((resolve, reject) => {
      return createGroup(token, groupData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('GET_USER_GROUP_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_USER_GROUP_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_USER_GROUP_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_GROUP: ({ commit, dispatch }, { groupData, userGroupID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_USER_GROUP_REQUEST');
    return new Promise((resolve, reject) => {
      return updateGroup(token, groupData, userGroupID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_USER_GROUP_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_USER_GROUP_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_USER_GROUP_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_USER_GROUP: ({ commit, dispatch }, userID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_USER_GROUP_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteGroup(token, userID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_USER_GROUP_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_USER_GROUP_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_USER_GROUP_FAILED');
          reject(errors(err));
        });
    });
  },
  UPLOAD_IMAGE: ({ commit, dispatch }, imageData) => {
    const token = Cookies.get('user-token') || globalStore.token;

    return new Promise((resolve, reject) => {
      return uploadFile(token, imageData)
        .then((resp) => {})
        .catch((err) => {
          reject(errors(err));
        });
    });
  },
  GET_MOBILE_USERS: ({ commit, dispatch, state }, obj) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_USERS_REQUEST');
    return new Promise((resolve, reject) => {
      return getMobileUsers(
        token,
        obj.companyID,
        obj.offset,
        obj.objectId,
        obj.sortDirection,
        obj.sortField,
        obj.userType,
        obj.userStatus,
        obj.groups,
        obj.CRFs,
        obj.searchString,
        obj.skipAmount,
        obj.selectionType
      )
        .then((resp) => {
          if (resp && resp.data) {
            let userListSource = resp.data.data.users;
            const totalFilteredUsers = resp.data.data.totalFilteredUsers;
            const totalUsers = resp.data.data.totalUsers;
            const registeredCount = resp.data.data.registeredCount;
            const unregisteredCount = resp.data.data.unregisteredCount;
            const activeCount = resp.data.data.activeCount;
            const suspendedCount = resp.data.data.suspendedCount;

            var newUserList = {};
            var groupCount = 0;

            var groupList = state.groupList.map((group) => {
              return group.actions;
            });
            let userList = userListSource.map((value) => {
              let status = '';
              let statusClass = '';
              if (value.isActive === 1) {
                status = 'active';
                statusClass = 'bg-success';
              } else if (value.isActive === 0) {
                status = 'suspended';
                statusClass = 'bg-danger';
              }
              value.selectedGroups = [];
              value.groupName = [];
              value.groupFinal = '';

              if (value.groups.length > 0) {
                if (groupList.length > 0) {
                  groupList.map((userGroup) => {
                    value.selectedGroups.push(userGroup);
                  });
                  if (value.selectedGroups.length > 0) {
                    value.groups.map((groupNew) => {
                      if (value.selectedGroups.includes(groupNew._id)) {
                        value.groupName.push(groupNew.name);
                      }

                      if (value.groupName.length > 1) {
                        value.groupFinal = value.groupName.join('<br/>');
                      } else {
                        value.groupFinal = value.groupName[0] || '';
                      }
                    });
                  } else {
                    if (groupList.includes(value.groups[0]._id)) {
                      value.groupFinal = value.groups[0] || '';
                    }
                  }
                }
              }

              let data = {
                mobileUserID: value.mobileUser ? value.mobileUser[0]._id : value._id,
                userID: value.userid,
                email: value.email,
                firstname: value.firstname || '',
                lastname: value.lastname || '',
                fullname: value.name || '',
                phone: value.phone || '',
                state: value.state || '',
                zipCode: value.zipcode || '',
                country: value.country || '',
                platform: value.platForm || '',
                companyID: value.companyID || 'All',
                fundedProvider: value.fundedProvider || '',
                status: { statusClass: statusClass, status: status },
                version: value.version && value.version == 2 ? 2 : 1,
                dateCreated: new Date(value.dateCreated),
                dateCreatedYmd: new Date(value.dateCreated).toISOString().split('T')[0],
                // .replace(/\//g, '-'),
                dateCreatedHms: new Date(value.dateCreated).toLocaleTimeString(undefined, { hour12: false }),
                groups: value.groups,
                groupsName: value.groupFinal,
                contentProviders: value.contentProviders,
                subAdmins: value.subAdmins,
                isDailyDeal: value.isDailyDeal,
                customFields: value.customFields || [],
                type: value.type || '',
                actions: value.mobileUser ? value.mobileUser[0]._id : value._id,
                groupCount: groupCount++,
                registered: 'registered' in value ? value.registered : true,
                imDisabled: value.imDisabled || false,
              };
              return data;
            });

            // ok if we skipped a bunch of users, what do we do about previous pages?
            // we can trigger requery in userlist
            if (obj.skipAmount > 0) {
              // shift all elements of users array to the right by skipAmount
              // and insert new users at the beginning
              for (let i = 0; i < obj.skipAmount; i++) {
                if (!state.userList.all[i]) {
                  const copyUser = { ...state.userList.all[0] };
                  copyUser.mobileUserID = 'dummy';
                  userList.unshift(copyUser);
                }
              }
            }

            newUserList['all'] = userList || [];
            userList = userList.filter((user) => user.mobileUserID !== 'dummy');
            newUserList['active'] = userList.filter((user) => user.status.status === 'active') || [];
            newUserList['suspended'] = userList.filter((user) => user.status.status === 'suspended') || [];
            newUserList['v1'] = userList.filter((user) => user.version === 1) || [];
            newUserList['v2'] = userList.filter((user) => user.version === 2) || [];
            newUserList['registered'] = userList.filter((user) => user.registered) || [];
            newUserList['unregistered'] = userList.filter((user) => !user.registered) || [];
            newUserList['active-registered'] = userList.filter((user) => user.status.status === 'active' && user.registered) || [];
            newUserList['active-unregistered'] = userList.filter((user) => user.status.status === 'active' && !user.registered) || [];
            newUserList['suspended-registered'] = userList.filter((user) => user.status.status === 'suspended' && user.registered) || [];
            newUserList['suspended-unregistered'] = userList.filter((user) => user.status.status === 'suspended' && !user.registered) || [];

            var newGroupUserList = [];
            groupList.map((group) => {
              newGroupUserList[group] = [];
              newUserList[group] = [];

              var test = userList.filter((x) => {
                var userSelected = x.groups.find((grp) => {
                  return grp._id === group;
                });

                return userSelected;
              });

              newGroupUserList[group].push(test);
            });
            // What does this do I dont even see it in the state
            for (var i in newGroupUserList) {
              newUserList[i] = newGroupUserList[i][0] || [];
              newUserList['active-' + i] = newUserList[i].filter((user) => user.status.status === 'active') || [];
              newUserList['suspended-' + i] = newUserList[i].filter((user) => user.status.status === 'suspended') || [];
              newUserList['active-registered-' + i] = newUserList[i].filter((user) => user.status.status === 'active' && user.registered) || [];
              newUserList['suspended-registered-' + i] = newUserList[i].filter((user) => user.status.status === 'suspended' && user.registered) || [];
              newUserList['active-unregistered-' + i] = newUserList[i].filter((user) => user.status.status === 'active' && !user.registered) || [];
              newUserList['suspended-unregistered-' + i] = newUserList[i].filter((user) => user.status.status === 'suspended' && !user.registered) || [];
              newUserList['registered-' + i] = newUserList[i].filter((user) => user.registered) || [];
              newUserList['unregistered-' + i] = newUserList[i].filter((user) => !user.registered) || [];
            }

            const docs_returned = newUserList.all.length;

            let copy = { ...state.userList };
            if (Object.keys(copy).length === 0) {
              commit('SET_USERS_SUCCESS', {
                list: newUserList,
                docs_returned: docs_returned,
                totalUsers: totalUsers,
                registeredCount,
                unregisteredCount,
                activeCount,
                suspendedCount,
                totalFilteredUsers,
              });
              resolve(newUserList);
            } else {
              // if this is for a page of dummy users, we need to replace them
              // instead of append

              if (copy.all[obj.skipAmount] && copy.all[obj.skipAmount].mobileUserID === 'dummy') {
                copy.all.splice(obj.skipAmount, obj.perPage, ...newUserList.all);
              } else {
                copy.all.push(...newUserList.all);
              }

              copy.active.push(...newUserList.active);

              copy.suspended.push(...newUserList.suspended);
              copy.v1.push(...newUserList.v1);
              copy.v2.push(...newUserList.v2);
              copy.registered.push(...newUserList.registered);
              copy.unregistered.push(...newUserList.unregistered);
              copy['active-registered'].push(...newUserList['active-registered']);
              copy['suspended-registered'].push(...newUserList['suspended-registered']);
              copy['suspended-unregistered'].push(...newUserList['suspended-unregistered']);

              commit('SET_USERS_SUCCESS', {
                list: copy,
                docs_returned: docs_returned,
                totalUsers: totalUsers,
                registeredCount,
                unregisteredCount,
                activeCount,
                suspendedCount,
                totalFilteredUsers,
              });
              resolve(copy);
            }
          }
        })
        .catch((err) => {
          console.error(err);
          commit('GET_USERS_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_USER_DAILY_DEALS: ({ commit, dispatch }, { formData, userID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_USERS_REQUEST');
    return new Promise((resolve, reject) => {
      return updateMobileUsers(token, 'deals', formData, userID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_USERS_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_USERS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_USERS_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_USER_GROUP: ({ commit, dispatch }, { formData, userID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_USERS_REQUEST');
    return new Promise((resolve, reject) => {
      return updateMobileUsers(token, 'group', formData, userID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_USERS_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_USERS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_USERS_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_CUSTOM_FIELDS: ({ commit, dispatch }, { formData, userID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_USERS_REQUEST');
    return new Promise((resolve, reject) => {
      return updateMobileUsers(token, 'custom-fields', formData, userID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_USERS_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_USERS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_USERS_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_MOBILE_USER: ({ commit, dispatch }, userID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_USERS_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteMobileUser(token, userID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_DELETED_USER_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_USERS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_USERS_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_MOBILE_USERS_RECOVERED: ({ commit, dispatch, state }, companyID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_USERS_RECOVERED_REQUEST');
    return new Promise((resolve, reject) => {
      return getMobileRecoveredUsers(token, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              var userListSource = resp.data.data;
              var newUserList = {};
              var groupCount = 0;

              var groupList = state.groupList.map((group) => {
                return group.actions;
              });

              let userList = userListSource.map((value) => {
                let status = '';
                let statusClass = '';
                if (value.isActive === 1) {
                  status = 'active';
                  statusClass = 'bg-success';
                } else if (value.isActive === 0) {
                  status = 'suspended';
                  statusClass = 'bg-danger';
                }
                value.selectedGroups = [];
                value.groupName = [];
                value.groupFinal = '';

                if (value.groups.length > 0) {
                  if (groupList.length > 0) {
                    groupList.map((userGroup) => {
                      value.selectedGroups.push(userGroup);
                    });

                    if (value.selectedGroups.length > 0) {
                      value.groups.map((groupNew) => {
                        if (value.selectedGroups.includes(groupNew._id)) {
                          value.groupName.push(groupNew.name);
                        }

                        if (value.groupName.length > 1) {
                          value.groupFinal = value.groupName.join('<br/>');
                        } else {
                          value.groupFinal = value.groupName[0] || '';
                        }
                      });
                    } else {
                      if (groupList.includes(value.groups[0]._id)) {
                        value.groupFinal = value.groups[0] || '';
                      }
                    }
                  }
                }

                let data = {
                  mobileUserID: value._id,
                  userID: value.userid,
                  email: value.email,
                  firstname: value.firstname || '',
                  lastname: value.lastname || '',
                  fullname: value.name || '',
                  phone: value.phone || '',
                  state: value.state || '',
                  zipCode: value.zipcode || '',
                  platform: value.platForm || '',
                  companyID: value.companyID || 'All',
                  status: { statusClass: statusClass, status: status },
                  version: value.version && value.version == 2 ? 2 : 1,
                  dateCreated: new Date(value.dateCreated).toLocaleString(undefined, { hour12: false }),
                  dateCreatedYmd: new Date(value.dateCreated).toISOString().split('T')[0],
                  dateCreatedHms: new Date(value.dateCreated).toLocaleTimeString(undefined, { hour12: false }),
                  dateCreated: new Date(value.dateUpdated).toLocaleString(undefined, { hour12: false }),
                  dateCreatedYmd: new Date(value.dateUpdated).toISOString().split('T')[0],
                  dateCreatedHms: new Date(value.dateUpdated).toLocaleTimeString(undefined, { hour12: false }),
                  groups: value.groups,
                  groupsName: value.groupFinal,
                  contentProviders: value.contentProviders,
                  isDailyDeal: value.isDailyDeal,
                  actions: value._id,
                  groupCount: groupCount++,
                };
                return data;
              });

              userList = userList.sort(function (a, b) {
                return new Date(b.dateUpdated) - new Date(a.dateUpdated);
              });

              newUserList['all'] = userList || [];
              newUserList['active'] = Vue.lodash.filter(userList, { status: { status: 'active' } }) || [];
              newUserList['suspended'] = Vue.lodash.filter(userList, { status: { status: 'suspended' } }) || [];
              newUserList['v1'] = Vue.lodash.filter(userList, { version: 1 }) || [];
              newUserList['v2'] = Vue.lodash.filter(userList, { version: 2 }) || [];
              // console.log(newUserList)
              commit('SET_USERS_RECOVERED_SUCCESS', newUserList);
              resolve(newUserList);
            }
          }
        })
        .catch((err) => {
          console.error(err);
          commit('GET_USERS_RECOVERED_FAILED');
          reject(errors(err));
        });
    });
  },
  CREATE_USER: ({ commit, dispatch }, userData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CREATE_USER_REQUEST');
    return new Promise((resolve, reject) => {
      return uploadUsers(token, userData)
        .then((resp) => {
          if (resp && resp.data) {
            const request = resp.data;

            if (request.success) {
              commit('GET_CREATE_USER_SUCCESS');
            } else {
              commit('GET_CREATE_USER_FAILED');
            }

            resolve(request);
          }
        })
        .catch((err) => {
          console.error('ERROR:', { err });
          commit('GET_CREATE_USER_FAILED');
          // reject(errors(err))
          resolve(err.response.data);
          // dispatch('GET_PROMOCODES')
        });
    });
  },
};

export default actions;
