import Vue from 'vue';
import {
  getSubscriptions,
  getSubscriptionsByUserId,
  createSubscription,
  updateSubscription,
  cancelSubscription,
  deleteSubscription,
} from '@/api/request/subscriptions';
import { uploadFile } from '@/api/request/tools';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment-timezone';

Vue.use(Cookies);

const actions = {
  GET_SUBSCRIPTIONS: ({ commit, dispatch }, companyID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_SUBSCRIPTIONS_REQUEST');
    return new Promise((resolve, reject) => {
      return getSubscriptions(token, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let subscriptionSource = resp.data.data;

              let subscriptions = subscriptionSource.map((value) => {
                return {
                  _id: value._id,
                  customerID: value.customerID,
                  subscriptionID: value.subscriptionID,
                  productID: value.productID,
                  planID: value.planID,
                  customer: {
                    name: Object.keys(value.customerInfo).length > 0 ? value.customerInfo.contactName : '',
                    email: Object.keys(value.customerInfo).length > 0 ? value.customerInfo.billingInfo.email : '',
                    phone: Object.keys(value.customerInfo).length > 0 ? value.customerInfo.billingInfo.phone : '',
                  },
                  channel: Object.keys(value.customerInfo).length > 0 ? value.customerInfo.clientName : '',
                  companyID: value.companyID || 'All',
                  planType: value.productInfo[0].plan.nickname,
                  amount: value.productInfo[0].plan.amount / 100,
                  currency: value.productInfo[0].plan.currency.toUpperCase(),
                  status: value.status,
                  interval: value.productInfo[0].plan.interval === 'month' ? 'monthly' : 'yearly',
                  created: moment(new Date(value.dateCreated)).tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:SS'),
                  createdYmd: moment(new Date(value.dateCreated)).format('YYYY-MM-DD'),
                  createdHms: moment(new Date(value.dateCreated)).format('HH:mm:SS'),
                  actions: value._id,
                };
              });

              subscriptions = subscriptions.sort(function (a, b) {
                return new Date(b.dateCreated) - new Date(a.dateCreated);
              });

              commit('GET_SUBSCRIPTIONS_SUCCESS', subscriptions);
              resolve(subscriptions);
            }
          }
        })
        .catch((err) => {
          console.error(err);
          commit('GET_SUBSCRIPTIONS_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_SUBSCRIPTION_BY_USER_ID: ({ commit, dispatch }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_SUBSCRIPTIONS_REQUEST');
    return new Promise((resolve, reject) => {
      return getSubscriptionsByUserId(token)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_SUBSCRIPTION_SUCCESS', resp.data.data);
              resolve(resp.data.data);
            } else {
              commit('GET_SUBSCRIPTIONS_FAILED');
            }
          }
        })
        .catch((err) => {
          console.error(err);
          commit('GET_SUBSCRIPTIONS_FAILED');
          reject(errors(err));
        });
    });
  },
  CREATE_SUBSCRIPTION: ({ commit, dispatch }, subscriptionData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_SUBSCRIPTIONS_REQUEST');
    return new Promise((resolve, reject) => {
      return createSubscription(token, subscriptionData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_SUBSCRIPTION_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_SUBSCRIPTIONS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_SUBSCRIPTIONS_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_SUBSCRIPTION: ({ commit, dispatch }, { subscriptionData, subscriptionID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_SUBSCRIPTIONS_REQUEST');
    return new Promise((resolve, reject) => {
      return updateSubscription(token, subscriptionData, subscriptionID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_SUBSCRIPTION_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_SUBSCRIPTIONS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_SUBSCRIPTIONS_FAILED');
          reject(errors(err));
        });
    });
  },
  CANCEL_SUBSCRIPTION: ({ commit, dispatch }, subscriptionID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_SUBSCRIPTIONS_REQUEST');
    return new Promise((resolve, reject) => {
      return cancelSubscription(token, subscriptionID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              resolve(resp);
            } else {
              commit('GET_SUBSCRIPTIONS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_SUBSCRIPTIONS_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_SUBSCRIPTION: ({ commit, dispatch }, subscriptionID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_SUBSCRIPTIONS_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteSubscription(token, subscriptionID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_SUBSCRIPTION_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_SUBSCRIPTIONS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_SUBSCRIPTIONS_FAILED');
          reject(errors(err));
        });
    });
  },
};

export default actions;
