import $http from '../utils/http';

const getRoleListRequest = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/roles`, null, 'GET', header);
};

const getUserListRequest = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/roles/users/admin`, null, 'GET', header);
};

const getRoleById = (token, roleID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/roles/${roleID}`, null, 'GET', header);
};

const addRole = (token, payload) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/roles`, payload, 'POST', header);
};

const updateRole = (token, payload) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/roles/${payload._id}`, payload, 'PUT', header);
};

const deleteRole = (token, id) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/roles/${id}`, '', 'DELETE', header);
};

const setUserRole = (token, payload) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/set-role`, payload, 'POST', header);
};

const getUserRoleById = (token, userID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/set-role/${userID}`, null, 'GET', header);
};

const updateUserRoleById = (token, userID, role) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/set-role/${userID}`, role, 'PUT', header);
};

export { getRoleListRequest, getUserListRequest, addRole, updateRole, deleteRole, getRoleById, setUserRole, getUserRoleById, updateUserRoleById };
