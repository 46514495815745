import Vue from 'vue';
import $http from '../utils/http';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);

const getSubscriptions = (token, companyID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/subscriptions/${companyID}`, null, 'GET', header);
};

const getSubscriptionsByUserId = (token) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/subscription/userid/items`, null, 'GET', header);
};

const createSubscription = (token, subscriptionData) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/subscription`, subscriptionData, 'POST', header);
};

const updateSubscription = (token, subscriptionData, subscriptionID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/subscription/${subscriptionID}`, subscriptionData, 'PUT', header);
};

const cancelSubscription = (token, subscriptionID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/subscriptions/cancel/${subscriptionID}`, null, 'GET', header);
};

const deleteSubscription = (token, subscriptionID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/subscription/${subscriptionID}`, null, 'DELETE', header);
};

export { getSubscriptions, getSubscriptionsByUserId, createSubscription, updateSubscription, cancelSubscription, deleteSubscription };
