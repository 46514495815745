import Vue from 'vue';
import {
  getUnionLinks,
  getUnionLinkByID,
  createUnionLink,
  updateUnionLink,
  deleteUnionLink,
  getLinkTypes,
  createLinkType,
  updateLinkType,
  deleteLinkType,
  updateLinkTypeOrder,
} from '@/api/request/unionLink';
import { uploadFile } from '@/api/request/tools';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment';

Vue.use(Cookies);

const actions = {
  GET_UNION_LINKS: ({ commit, dispatch }, companyID = null) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_UNION_LINK_REQUEST');
    return new Promise((resolve, reject) => {
      return getUnionLinks(token, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let unionLinkSource = resp.data.data;

              let unionLinks = unionLinkSource.map((value) => {
                let data = {
                  unionLinkID: value._id,
                  unionLinkName: value.unionLink_name,
                  description: value.benefits_description,
                  companyID: value.companyID || 'All',
                  dateCreated: moment(new Date(value.dateCreated)).format('YYYY-MM-DD HH:mm:SS'),
                  actions: value._id,
                };
                return data;
              });

              commit('SET_UNION_LINK_SUCCESS', unionLinks);
              resolve(unionLinks);
            }
          }
        })
        .catch((err) => {
          commit('GET_UNION_LINK_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_UNION_LINK_BY_CP_ID: ({ commit, dispatch }, unionLinkID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_UNION_LINK_BY_CP_ID_REQUEST');
    return new Promise((resolve, reject) => {
      return getUnionLinkByID(token, unionLinkID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_UNION_LINK_BY_ID', resp.data.data);
              resolve(resp.data.data);
            } else {
              commit('GET_UNION_LINK_BY_CP_ID_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_UNION_LINK_BY_CP_ID_FAILED');
          reject(errors(err));
        });
    });
  },
  CREATE_UNION_LINK: ({ commit, dispatch }, cpData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_UNION_LINK_REQUEST');
    return new Promise((resolve, reject) => {
      return createUnionLink(token, cpData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_UNION_LINK_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_UNION_LINK_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_UNION_LINK_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_UNION_LINK: ({ commit, dispatch }, { formData, unionLinkID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_UNION_LINK_REQUEST');
    return new Promise((resolve, reject) => {
      return updateUnionLink(token, formData, unionLinkID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_UNION_LINK_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_UNION_LINK_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_UNION_LINK_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_UNION_LINK: ({ commit, dispatch }, userID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_UNION_LINK_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteUnionLink(token, userID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_UNION_LINK_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_UNION_LINK_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_UNION_LINK_FAILED');
          reject(errors(err));
        });
    });
  },
  UPLOAD_IMAGE: ({ commit, dispatch }, imageData) => {
    const token = Cookies.get('user-token') || globalStore.token;

    return new Promise((resolve, reject) => {
      return uploadFile(token, imageData)
        .then((resp) => {})
        .catch((err) => {
          reject(errors(err));
        });
    });
  },
  GET_LINK_TYPE_BY_CP_ID: ({ commit, dispatch }, contentProviderID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_LINK_TYPE_BY_CP_ID_REQUEST');
    return new Promise((resolve, reject) => {
      return getLinkTypes(token, contentProviderID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              var linkTypes = resp.data.data;

              linkTypes.map((value) => {
                value.dateCreated = moment(new Date(value.dateCreated)).format('YYYY-MM-DD HH:mm:SS');
                return value;
              });

              commit('SET_LINK_TYPE_BY_CP_ID_SUCCESS', linkTypes);
              resolve(linkTypes);
            } else {
              commit('SET_LINK_TYPE_BY_CP_ID_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('SET_LINK_TYPE_BY_CP_ID_FAILED');
          reject(errors(err));
        });
    });
  },
  CREATE_LINK_TYPE: ({ commit, dispatch }, linkData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    const { formData, contentProviderID } = linkData;
    commit('GET_LINK_TYPE_BY_CP_ID_REQUEST');
    return new Promise((resolve, reject) => {
      return createLinkType(token, formData, contentProviderID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_LINK_TYPE_BY_CP_ID_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('SET_LINK_TYPE_BY_CP_ID_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('SET_LINK_TYPE_BY_CP_ID_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_LINK_TYPE: ({ commit, dispatch }, { formData, linkTypeID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_LINK_TYPE_BY_CP_ID_REQUEST');
    return new Promise((resolve, reject) => {
      return updateLinkType(token, formData, linkTypeID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_LINK_TYPE_BY_CP_ID_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('SET_LINK_TYPE_BY_CP_ID_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('SET_LINK_TYPE_BY_CP_ID_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_LINK_TYPE: ({ commit, dispatch }, linkTypeID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_LINK_TYPE_BY_CP_ID_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteLinkType(token, linkTypeID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_LINK_TYPE_BY_CP_ID_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('SET_LINK_TYPE_BY_CP_ID_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('SET_LINK_TYPE_BY_CP_ID_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_LINK_TYPE_ORDER: ({ commit, dispatch }, { linkTypeData, contentProvderID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_LINK_TYPE_BY_CP_ID_REQUEST');
    return new Promise((resolve, reject) => {
      return updateLinkTypeOrder(token, linkTypeData, contentProvderID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_LINK_TYPE_BY_CP_ID_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('SET_LINK_TYPE_BY_CP_ID_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('SET_LINK_TYPE_BY_CP_ID_FAILED');
          reject(errors(err));
        });
    });
  },
};

export default actions;
