import { ElNotification } from "element-ui/types/notification";

export enum NotificationTypes {
    Success = 'success',
    Error = 'danger',
    Info = 'info',
    Warning = 'warning',
}

export const wrapNotification = (caller: ElNotification, type: NotificationTypes, title?: string, message?: string, timeout?: number) => {
    let iconClass = '';
    switch (type) {
        case NotificationTypes.Success:
            iconClass = 'fas fa-check';
            break;
        case NotificationTypes.Error:
            iconClass = 'fas fa-exclamation-triangle';
            break;
        case NotificationTypes.Info:
            iconClass = 'fas fa-cog fa-spin';
            break;
        case NotificationTypes.Warning:
            iconClass = 'fas fa-exclamation-circle';
            break;
        default:
            iconClass = 'fas fa-info-circle';
            break;
    }

    if (!message || message === '') {
        message = title;
        title = '';
    }
    caller({
        title: title || '',
        message: message || '',
        // @ts-ignore
        icon: iconClass,
        // @ts-ignore
        type: type,
        timeout: timeout || 4000,
    });
};