import Vue from 'vue';
import $http from '../utils/http';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);

const getContentProviderCustomRegistrationFields = (token, contentProviderId) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/settings/custom-fields/cp-custom-fields/${contentProviderId}`, null, 'GET', header);
};

const getSettings = (token, companyID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/settings/all/company/${companyID}`, null, 'GET', header);
};

const getSettingsByCPID = (token, settingName, contentProviderID, companyID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/settings/${settingName}/params/${settingName}/${contentProviderID}/${companyID}`, null, 'GET', header);
};

const createSettings = (token, settingUrl, data) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/settings/${settingUrl}`, data, 'POST', header);
};

const updateSettings = (token, settingUrl, data) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/settings/${settingUrl}`, data, 'PUT', header);
};

const deleteAutoPhone = (token, autoPhoneID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/settings/auto-phone-number/${autoPhoneID}`, null, 'DELETE', header);
};

const updateSettingChannel = (token, data, cpID) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/settings/channel/${cpID}`, data, 'PUT', header);
};

const updateReplicaBuildSetting = (token, data) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/settings/channel/replica/build`, data, 'PUT', header);
};

const updateAllowPushcastEmail = (token, data) => {
  const header = token ? { authorization: token } : null;
  return $http(`/settings/channel/pushcast/setting`, data, 'POST', header);
};

const getAllReplicaBuildNames = (token, companyID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/settings/replica-builds/all/company/${companyID}`, null, 'GET', header);
};

const getReplicaBuild = (token, id) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/settings/replica-builds/params/id/${id}`, null, 'GET', header);
};

const updateReplicaBuild = (token, data) => {
  const id = data.get('id');
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/settings/replica-builds/update/params/id/${id}`, data, 'PUT', header);
};

const getAllExternalApis = (token) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http('/settings/external-apis/all-apis', null, 'GET', header);
};

export {
  getSettings,
  getSettingsByCPID,
  createSettings,
  updateSettings,
  deleteAutoPhone,
  updateSettingChannel,
  updateReplicaBuildSetting,
  updateAllowPushcastEmail,
  getAllReplicaBuildNames,
  getReplicaBuild,
  updateReplicaBuild,
  getContentProviderCustomRegistrationFields,
  getAllExternalApis,
};
