const mutations = {
  PRICE_PLAN_LIST_REQUEST: (state) => {
    state.loading = true;
  },
  SET_PRICE_PLAN_LIST: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
    state.price_plan_list = data;
  },
  PRICE_PLAN_LIST_ERROR: (state) => {
    state.loading = false;
    state.success = false;
    state.error = true;
  },
  PRICE_PLAN_REQUEST: (state) => {
    state.pricePlanLoading = true;
    state.pricePlanEdit = {};
  },
  SET_PRICE_PLAN: (state, data) => {
    state.pricePlanLoading = false;
    state.pricePlanSuccess = true;
    state.pricePlanError = false;
    state.pricePlanEdit = data;
  },
  PRICE_PLAN_ERROR: (state) => {
    state.pricePlanLoading = false;
    state.pricePlanSuccess = false;
    state.pricePlanError = true;
  },
};

export default mutations;
