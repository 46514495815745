const state = {
  voboLoading: false,
  accessLoading: false,
  backgroundLoading: false,
  autoPhoneLoading: false,
  notifMaxLoading: false,
  socialMediaLoading: false,
  featureLinkLoading: false,
  customMenuLoading: false,
  customMenuLoadingSave: false,
  channelLoading: false,
  appDisplayLoading: false,
  customFieldsLoading: false,
  replicaBuildNamesLoading: false,
  replicaBuildLoading: false,
  selectedReplicaBuildName: null,
  selectedReplicaBuildID: null,
  selectedReplicaBuild: {
    iosVersion: '',
    androidVersion: '',
    iosAppUrl: '',
    androidAppUrl: '',
    androidAPN: '',
    iosAPN: '',
    iosIAS: '',
    deepLink: '',
    webAPIKey: '',
    channels: [],
  },
  success: false,
  error: false,
  settingsList: [],
  replicaBuildsList: [],
  externalApisList: [],
  externalApisListLoading: false,
};

export default state;
