import Vue from 'vue';
import $http from '../utils/http';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);

const getProducts = (token, companyID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/products/${companyID}`, null, 'GET', header);
};

const getProductsById = (token, productID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/products/item/${productID}`, null, 'GET', header);
};

const createProduct = (token, productData) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/products`, productData, 'POST', header);
};

const updateProduct = (token, productData, productID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/products/${productID}`, productData, 'PUT', header);
};

const deleteProduct = (token, productID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/products/${productID}`, null, 'DELETE', header);
};

export { getProducts, getProductsById, createProduct, updateProduct, deleteProduct };
