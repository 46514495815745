const mutations = {
  GET_USERS_REQUEST: (state) => {
    state.loading = true;
    state.loadedOnce = false;
    state.usersLoadedOnce = false;
    state.success = false;
    state.error = false;
    state.selectedUser = {};
  },
  SET_USERS_SUCCESS: (state, data) => {
    state.loading = false;
    state.loadedOnce = true;
    state.usersLoadedOnce = true;
    state.success = true;
    state.error = false;
    state.errorUser = false;
    state.userList = data.list;
    state.documentsReturned = data.docs_returned;
    state.totalUsers = data.totalUsers;
    state.totalFilteredUsers = data.totalFilteredUsers;
    state.registeredCount = data.registeredCount;
    state.unregisteredCount = data.unregisteredCount;
    state.activeCount = data.activeCount;
    state.suspendedCount = data.suspendedCount;
    state.selectedUser = {};
  },
  SET_DELETED_USER_SUCCESS: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
  },
  GET_USERS_FAILED: (state) => {
    state.loading = false;
    state.usersLoadedOnce = false;
    state.userList = [];
  },
  GET_USERS_BY_CP_ID_REQUEST: (state) => {
    state.loading = true;
    state.selectedUserSuccess = false;
    state.selectedUserError = false;
  },
  SET_USERS_BY_ID: (state, data) => {
    state.loading = false;
    state.selectedUserSuccess = true;
    state.selectedUser = data;
  },
  GET_USERS_BY_CP_ID_FAILED: (state) => {
    state.loading = false;
    state.selectedUserError = true;
    state.selectedUser = {};
  },
  UPDATE_USERS_REQUEST: (state) => {
    state.loading = true;
    state.success = false;
    state.error = false;
  },
  UPDATE_USERS_SUCCESS: (state) => {
    state.loading = false;
    state.success = true;
    state.error = false;
  },
  UPDATE_USERS_FAILED: (state) => {
    state.loading = false;
    state.success = false;
    state.error = true;
  },
  GET_USER_GROUP_REQUEST: (state) => {
    state.groupLoading = true;
    state.groupSuccess = false;
    state.groupError = false;
  },
  GET_USER_GROUP_SUCCESS: (state, data) => {
    state.groupLoading = false;
    state.groupSuccess = true;
    state.groupError = false;
    state.groupList = data;
  },
  GET_USER_GROUP_FAILED: (state) => {
    state.groupLoading = false;
    state.groupSuccess = false;
    state.groupError = true;
  },
  SET_USER_GROUP_BY_GROUP_ID: (state, data) => {
    state.groupLoading = false;
    state.selectedUserSuccess = true;
    state.selectedGroup = data;
  },
  UPDATE_USER_GROUP_REQUEST: (state) => {
    state.groupLoading = true;
    state.selectedUserSuccess = false;
    state.groupError = false;
  },
  UPDATE_USER_GROUP_SUCCESS: (state) => {
    state.groupLoading = false;
    state.selectedUserSuccess = true;
    state.groupError = false;
  },
  UPDATE_USER_GROUP_FAILED: (state) => {
    state.groupLoading = false;
    state.selectedUserSuccess = false;
    state.groupError = true;
  },
  GET_USERS_RECOVERED_REQUEST: (state) => {
    state.loading = true;
    state.loadedOnce = false;
    state.usersLoadedOnce = false;
    state.success = false;
    state.error = false;
    state.userRecoveredList = [];
    state.selectedUser = {};
  },
  SET_USERS_RECOVERED_SUCCESS: (state, data) => {
    state.loading = false;
    state.loadedOnce = true;
    state.usersLoadedOnce = true;
    state.success = true;
    state.error = false;
    state.errorUser = false;
    state.userRecoveredList = data;
    state.selectedUser = {};
  },
  GET_USERS_RECOVERED_FAILED: (state) => {
    state.loading = false;
    state.usersLoadedOnce = false;
    state.userRecoveredList = [];
  },
  GET_CREATE_USER_REQUEST: (state) => {
    state.singleUserloading = true;
    state.singleUserSuccess = false;
    state.singleUserFailed = false;
  },
  GET_CREATE_USER_SUCCESS: (state) => {
    state.singleUserloading = false;
    state.singleUserSuccess = true;
    state.singleUserFailed = false;
  },
  GET_CREATE_USER_FAILED: (state) => {
    state.singleUserloading = false;
    state.singleUserSuccess = false;
    state.singleUserFailed = true;
  },
  RESET_DATA: (state) => {
    state.loading = false;
    state.loadedOnce = false;
    state.usersLoadedOnce = false;
    state.groupLoading = false;
    state.success = false;
    state.error = false;
    state.groupSuccess = false;
    state.groupError = false;
    state.errorUser = false;
    state.errorGroup = false;
    state.userList = [];
    state.groupList = [];
    state.selectedUser = {};
    state.selectedGroup = {};
    state.selectedUserSuccess = false;
    state.selectedUserError = false;
    state.selectedGroupSuccess = false;
    (state.selectedGroupError = false), (state.userRecoveredList = []);
  },
};

export default mutations;
