const state = {
  loading: false,
  success: false,
  error: false,
  company: {
    colorSetting: { color_hex: '' },
    adminColor: { color_hex: '' },
    adminMenu: [],
    mobileMenu: [],
    isConfigFileReady: 0,
    accountSetupLink: '',
    emailDomain: '',
  },
  selectedCompany: '',
  companyList: [],
  companyLoading: false,
  companySuccess: false,
  companyError: false,
  companyEdit: {},
  maintenanceLoading: false,
  companyLogoLoading: false,
  adminColorLoading: false,
  faviconLoading: false,
  copyrightLoading: false,
  adminMenuLoading: false,
  mobileMenuLoading: false,
  featuredLinksLoading: false,
  isConfigFileReady: 0,
  accountSetupLinkLoading: false,
  allowPushcastEmailLoading: false,
  allowPushcastEmail: null,
  allowPushcastEmailError: false,
  emailDomain: '',
};

export default state;
