const mutations = {
  GET_PACKAGE_REQUEST: (state) => {
    state.loading = true;
    state.loadedOnce = false;
    state.packageLoadedOnce = false;
    state.success = false;
    state.error = false;
  },
  SET_PACKAGE_SUCCESS: (state, data) => {
    state.loading = false;
    state.loadedOnce = true;
    state.packageLoadedOnce = true;
    state.success = true;
    state.error = false;
    state.errorPackage = false;
    state.packageList = data;
    state.selectedPackage = {};
  },
  GET_PACKAGE_FAILED: (state) => {
    state.loading = false;
    state.packageLoadedOnce = false;
    state.packageList = [];
  },
  GET_PACKAGE_BY_ID_REQUEST: (state) => {
    state.loading = true;
    state.selectedPackageSuccess = false;
    state.selectedPackageError = false;
  },
  SET_PACKAGE_BY_ID: (state, data) => {
    state.loading = false;
    state.selectedPackageSuccess = true;
    state.selectedPackage = data;
  },
  GET_PACKAGE_BY_ID_FAILED: (state) => {
    state.loading = false;
    state.selectedPackageError = true;
    state.selectedPackage = {};
  },
  UPDATE_PACKAGE_REQUEST: (state) => {
    state.loading = true;
    state.success = false;
    state.error = false;
  },
  UPDATE_PACKAGE_SUCCESS: (state) => {
    state.loading = false;
    state.success = true;
    state.error = false;
  },
  UPDATE_PACKAGE_FAILED: (state) => {
    state.loading = false;
    state.success = false;
    state.error = true;
  },
  RESET_DATA: (state) => {
    state.loading = false;
    state.loadedOnce = false;
    state.packageLoadedOnce = false;
    state.success = false;
    state.error = false;
    state.errorPackage = false;
    state.packageList = [];
    state.selectedPackage = {};
    state.selectedPackageSuccess = false;
    state.selectedPackageError = false;
  },
};

export default mutations;
