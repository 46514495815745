const state = {
  loading: false,
  loadedOnce: false,
  packageLoadedOnce: false,
  success: false,
  error: false,
  errorPackage: false,
  packageList: [],
  selectedPackage: {},
  selectedPackageSuccess: false,
  selectedPackageError: false,
};

export default state;
