<template>
  <card class="card-stats" :show-footer-line="true">
    <div class="row">
      <div class="col">
        <slot>
          <h5 v-if="title" class="card-title text-uppercase text-gray mb-0">
            {{ title }}
          </h5>
          <span v-if="subTitle" class="h1 font-weight-bold mb-0">
            {{ prefix }}
            <ICountUp v-if="countUp" :delay="delay" :end-val="formattedSubTitle" :options="countUpOptions" />
            <span v-else>{{ subTitle }}</span>
          </span>
          <h5 v-if="addOrUpgradeBundleOption && thirdTitle" class="card-title text-uppercase text-gray mb-0">
            <div v-if="thirdTitle === 'AVAILABLE' || thirdTitle === 'DISABLED'">
              <div v-if="thirdTitle === 'AVAILABLE'">
                {{ thirdTitle }}:
                <b v-if="thirdTitleCount < 0" class="red">{{ thirdTitleCount }}</b>
                <b v-else>{{ thirdTitleCount }}</b>
              </div>
              <div v-else>
                <b class="redText">{{ thirdTitle }}</b>
              </div>
            </div>

            <div v-else>
              {{ thirdTitle }}:
              <b v-if="formattedSubTitle > thirdTitleCount && thirdTitleCount >= 0" class="red">{{ thirdTitleCount }}</b>
              <b v-else-if="thirdTitleCount < 0" class="red">{{ thirdTitleCount }}</b>
              <b v-else>{{ thirdTitleCount }}</b>
            </div>
          </h5>
        </slot>
      </div>

      <div v-if="$slots.icon || icon" class="col-auto">
        <slot name="icon">
          <div class="icon icon-shape text-white rounded-circle shadow" :class="[`bg-${type}`, iconClasses]">
            <i :class="icon"></i>
          </div>
        </slot>
        <slot name="icon">
          <div v-if="addOrUpgradeBundleOption" class="text-white addOrUpgradeContainer" :class="[`bg-${type}`, iconClasses]">
            <router-link :to="`/content-provider/pricing-panel/${contentProviderId}`" class="btn btn-sm text-success" :class="{ disabled: is_disabled }">
              <h5 class="text-white card-title text-uppercase mb-0">ADD OR UPGRADE</h5>
            </router-link>
          </div>
        </slot>
      </div>
    </div>

    <p v-if="!addOrUpgradeBundleOption" class="extended-footer mt-3 mb-0 text-sm">
      <slot name="footer"></slot>
    </p>
    <p v-else-if="!thirdTitle && !isChannelAdmin" class="mt-3 mb-0 text-sm">
      <slot name="footer"></slot>
    </p>
    <p v-else-if="!thirdTitle && isChannelAdmin" class="extended-footer mt-3 mb-0 text-sm">
      <slot name="footer"></slot>
    </p>
  </card>
</template>
<script>
import Card from './Card.vue';
import ICountUp from 'vue-countup-v2';

export default {
  name: 'StatsCard',
  components: {
    Card,
    ICountUp,
  },
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    icon: String,
    title: String,
    subTitle: String,
    thirdTitle: String,
    thirdTitleCount: Number,
    isChannelAdmin: Boolean,
    contentProviderId: String,
    addOrUpgradeBundleOption: null,
    is_disabled: Boolean,
    prefix: {
      type: String,
      default: '',
    },
    subTitleType: {
      type: String,
      default: 'number',
    },
    countUp: {
      type: Boolean,
      default: true,
    },
    iconClasses: [String, Array],
  },
  data() {
    return {
      formattedSubTitle: 0,
      delay: 1000,
      countUpOptions: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
      },
    };
  },
  created() {
    this.formattedSubTitle =
      this.subTitleType === 'number' ? parseInt(this.subTitle) : this.subTitleType === 'decimal' ? parseFloat(this.subTitle) : this.subTitle;
  },
};
</script>
<style>
.red {
  color: red;
  animation: blinker 1s step-start infinite;
}

.redText {
  color: red;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.addOrUpgradeContainer {
  width: 5rem;
  text-align: center;
  border-radius: 5px;
  color: white !important;
  margin-top: 0.5rem;
}

.col-auto {
  text-align: center;
}

.extended-footer {
  padding-bottom: 2rem;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
