const state = {
  loading: false,
  success: false,
  error: false,
  errorUser: false,
  cpList: {},
  categoryLoading: false,
  typeLoading: false,
  groupLoading: false,
  cpCategories: [],
  cpTypes: [],
  cpGroups: [],
  uniontypeLoading: false,
  uniontypeSuccess: false,
  uniontypeError: false,
  unionTypes: [],
  selectedCP: {},
  defaultCompany: {},
  allowPushcastEmail: null,
};

export default state;
