import Vue from 'vue';
import {
  getContentProviders,
  getContentProviderByID,
  createContentProvider,
  updateContentProvider,
  updateContentProviderStatus,
  deleteContentProvider,
  getCPCategories,
  getCategoryByID,
  createCPCategory,
  updateCPCategory,
  deleteCPCategory,
  updateCPCategoryGroup,
  getCPTypes,
  getTypeByID,
  createCPType,
  updateCPType,
  deleteCPType,
  getCPGroups,
  getGroupByID,
  createCPGroup,
  updateCPGroup,
  deleteCPGroup,
  getUnionTypes,
  getUnionTypeByID,
  createUnionType,
  updateUnionType,
  deleteUnionType,
} from '@/api/request/contentProvider';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment-timezone';

Vue.use(Cookies);

const actions = {
  GET_CONTENT_PROVIDERS: ({ commit, dispatch }, companyID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_CONTENT_PROVIDER');
    return new Promise((resolve, reject) => {
      return getContentProviders(token, companyID)
        .then((resp) => {
          if (resp) {
            var contentProviders = resp.data.data;
            var newCPList = {};
            let cpList = contentProviders.map((value) => {
              let status = '';
              let statusClass = '';
              if (value.isActive === 1) {
                status = 'active';
                statusClass = 'bg-success';
              } else if (value.isActive === 0) {
                status = 'inactive';
                statusClass = 'bg-danger';
              }

              let data = {
                cpID: value._id,
                clientName: value.clientName,
                contactName: value.contactName,
                category: value.category || '',
                group: value.group || '',
                image: value.image,
                email: value.email,
                accessCode: value.access_code,
                status: { statusClass: statusClass, status: status },
                companyID: value.companyID || 'All',
                channelNumber: value.channelNumber,
                isLocal: value.is_local ? 'Yes' : 'No',
                isCompanyDefault: value.isCompanyDefault,
                allowRegistration: value.allowRegistration,
                isForceFollowing: value.isForceFollowing,
                type:
                  value.is_local && !value.isCompanyDefault ? 'PRIMARY' : value.allowRegistration ? 'HYBRID' : value.isCompanyDefault ? 'DEFAULT' : 'SECONDARY',
                dateCreatedString:
                  new Date(value.dateCreated).toISOString().split('T')[0] +
                  ' ' +
                  new Date(value.dateCreated).toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles' }),
                dateCreated: value.dateCreated,
                dateCreatedYMD: value.dateCreated ? new Date(value.dateCreated).toISOString().split('T')[0] : '',
                dateCreatedHMS: value.dateCreated ? new Date(value.dateCreated).toLocaleTimeString(undefined, { hour12: false }) : '',
                actions: value._id,
                userID: value.userid,
                userCount: value.userCount || 0,
                sentSms: value.sentSms || 0,
                secondaryUserCount: value.secondaryUserCount || 0,
                fcUserCount: value.fcUserCount || 0,
                appDisplay: value.links || '',
                links: value.links || [],
                featuredLinks: value.featuredLinks,
                allowPushcastEmail: value.allowPushcastEmail || null,
                requireEmailVerificationForPushcast: value.requireEmailVerificationForPushcast || null,
                pricePlan: value.pricePlan,
                resellerCode: value.resellerCode,
                partnerAccountNumber: value.partnerAccountNumber,
                revenueCollectionMode: value.revenueCollectionMode,
                enablePrivateVideo: value.enablePrivateVideo,
                off_app_text_credits_used_last_month: value.off_app_text_credits_used_last_month,
                off_app_text_credits_used_current_month: value.off_app_text_credits_used_current_month,
                purchased_off_app_text_credits_last_month: value.purchased_off_app_text_credits_last_month,
                total_messages_sent_last_month: value.total_messages_sent_last_month,
                purchased_app_users: value.purchased_app_users || 0,
                purchased_app_users_cost: value.purchased_app_users_cost || 0,
                registeredUserCount: value.registeredUserCount || 0,
                replicaBuild: value.replicaBuild || null,
                selectedExternalApis: value.selectedExternalApis || [],
                inviteID: value.inviteID || null,
                adminLoginsMonth: value.adminLoginsMonth || 0,
                userLoginsMonth: value.userLoginsMonth || 0,
              };
              return data;
            });

            newCPList['all'] = cpList;
            newCPList['active'] = Vue.lodash.filter(cpList, { status: { status: 'active' } }) || '';
            newCPList['inactive'] = Vue.lodash.filter(cpList, { status: { status: 'inactive' } }) || '';

            const defaultCompany = Vue.lodash.filter(cpList, { isCompanyDefault: true });

            commit('SET_DEFAULT_COMPANY_CHANNEL', defaultCompany.length > 0 ? defaultCompany[0] : {});
            commit('SET_CONTENT_PROVIDER_SUCCESS', newCPList);
            resolve(newCPList);
          }
        })
        .catch((err) => {
          commit('GET_CONTENT_PROVIDER_FAILED');
          reject(errors(err));
        });
    });
  },
  CREATE_CONTENT_PROVIDER: ({ commit, dispatch }, cpData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CONTENT_PROVIDER');
    return new Promise((resolve, reject) => {
      return createContentProvider(token, cpData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_CONTENT_PROVIDER_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_CONTENT_PROVIDER_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CONTENT_PROVIDER_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_CONTENT_PROVIDER_BY_ID: ({ commit, dispatch }, cpID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CONTENT_PROVIDER');
    return new Promise((resolve, reject) => {
      return getContentProviderByID(token, cpID)
        .then((resp) => {
          if (resp) {
            if (resp.data.data) {
              commit('LOAD_CONTENT_PROVIDER_SUCCESS', resp.data.data);
              resolve(resp.data.data);
            } else {
              commit('GET_CONTENT_PROVIDER_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CONTENT_PROVIDER_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_CONTENT_PROVIDER: ({ commit, dispatch }, { formData, contentProviderID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CONTENT_PROVIDER');
    return new Promise((resolve, reject) => {
      return updateContentProvider(token, formData, contentProviderID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_CONTENT_PROVIDER_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_CONTENT_PROVIDER_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CONTENT_PROVIDER_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_CONTENT_PROVIDER_STATUS: ({ commit, dispatch }, { formData, contentProviderID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CONTENT_PROVIDER');
    return new Promise((resolve, reject) => {
      return updateContentProviderStatus(token, formData, contentProviderID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_CONTENT_PROVIDER_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_CONTENT_PROVIDER_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CONTENT_PROVIDER_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_CONTENT_PROVIDER: ({ commit, dispatch }, contentProviderID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CONTENT_PROVIDER');
    return new Promise((resolve, reject) => {
      return deleteContentProvider(token, contentProviderID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              resolve(resp);
            } else {
              commit('GET_CONTENT_PROVIDER_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CONTENT_PROVIDER_FAILED');
          reject(errors(err));
        });
    });
  },

  GET_CP_GROUPS: ({ commit, dispatch }, companyID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_CP_GROUPS_REQUEST');
    return new Promise((resolve, reject) => {
      return getCPGroups(token, companyID)
        .then((resp) => {
          if (resp) {
            var cpCategories = resp.data.data;

            cpCategories.map((value) => {
              value.actions = { id: value._id };
              value.dateCreated = moment(new Date(value.dateCreated)).format('YYYY-MM-DD HH:mm:SS');
              return value;
            });

            cpCategories = cpCategories.sort(function (a, b) {
              return new Date(b.dateCreated) - new Date(a.dateCreated);
            });

            commit('SET_CP_GROUPS_SUCCESS', cpCategories);
            resolve(cpCategories);
          }
        })
        .catch((err) => {
          commit('GET_CP_GROUPS_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_CP_GROUP_BY_ID: ({ commit, dispatch }, groupID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CP_GROUPS_REQUEST');
    return new Promise((resolve, reject) => {
      return getGroupByID(token, groupID)
        .then((resp) => {
          if (resp) {
            if (resp.data.data) {
              commit('SET_CP_GROUPS_SUCCESS', resp.data.data);
              resolve(resp.data.data);
            } else {
              commit('GET_CP_GROUPS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CP_GROUPS_FAILED');
          reject(errors(err));
        });
    });
  },
  CREATE_CP_GROUP: ({ commit, dispatch }, groupData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CP_GROUPS_REQUEST');
    return new Promise((resolve, reject) => {
      return createCPGroup(token, groupData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_CP_GROUPS_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_CP_GROUPS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CP_GROUPS_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_CP_GROUP: ({ commit, dispatch }, { formData, groupID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CP_GROUPS_REQUEST');
    return new Promise((resolve, reject) => {
      return updateCPGroup(token, formData, groupID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('LOAD_GROUP_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_CP_GROUPS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CP_GROUPS_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_CP_GROUP: ({ commit, dispatch }, groupID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CP_GROUPS_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteCPGroup(token, groupID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              resolve(resp);
            } else {
              commit('GET_CP_GROUPS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CP_GROUPS_FAILED');
          reject(errors(err));
        });
    });
  },

  GET_CP_CATEGORIES: ({ commit, dispatch }, companyID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_CP_CATEGORIES_REQUEST');
    return new Promise((resolve, reject) => {
      return getCPCategories(token, companyID)
        .then((resp) => {
          if (resp) {
            var cpCategories = resp.data.data;
            var groupCount = 0;

            cpCategories.map((value) => {
              value.actions = { id: value._id };
              value.groupCount = groupCount++;
              value.dateCreated = moment(new Date(value.dateCreated)).format('YYYY-MM-DD HH:mm:SS');
              return value;
            });

            cpCategories = cpCategories.sort(function (a, b) {
              return new Date(b.dateCreated) - new Date(a.dateCreated);
            });

            commit('SET_CP_CATEGORIES_SUCCESS', cpCategories);
            resolve(cpCategories);
          }
        })
        .catch((err) => {
          commit('GET_CP_CATEGORIES_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_CP_CATEGORY_BY_ID: ({ commit, dispatch }, categoryID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CP_CATEGORIES_REQUEST');
    return new Promise((resolve, reject) => {
      return getCategoryByID(token, categoryID)
        .then((resp) => {
          if (resp) {
            if (resp.data.data) {
              commit('LOAD_CATEGORY_SUCCESS', resp.data.data);
              resolve(resp.data.data);
            } else {
              commit('GET_CP_CATEGORIES_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CP_CATEGORIES_FAILED');
          reject(errors(err));
        });
    });
  },
  CREATE_CP_CATEGORY: ({ commit, dispatch }, categoryData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CP_CATEGORIES_REQUEST');
    return new Promise((resolve, reject) => {
      return createCPCategory(token, categoryData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('LOAD_CATEGORY_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_CP_CATEGORIES_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CP_CATEGORIES_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_CP_CATEGORY: ({ commit, dispatch }, { formData, categoryID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CP_CATEGORIES_REQUEST');
    return new Promise((resolve, reject) => {
      return updateCPCategory(token, formData, categoryID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('LOAD_CATEGORY_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_CP_CATEGORIES_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CP_CATEGORIES_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_CP_CATEGORY: ({ commit, dispatch }, categoryID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CP_CATEGORIES_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteCPCategory(token, categoryID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              resolve(resp);
            } else {
              commit('UPDATE_ADMIN_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CP_CATEGORIES_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_CP_CATEGORY_GROUP: ({ commit, dispatch }, { formData, categoryID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CP_CATEGORIES_REQUEST');
    return new Promise((resolve, reject) => {
      return updateCPCategoryGroup(token, formData, categoryID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_CP_CATEGORIES_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_CP_CATEGORIES_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CP_CATEGORIES_FAILED');
          reject(errors(err));
        });
    });
  },

  GET_CP_TYPES: ({ commit, dispatch }, companyID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_CP_TYPES_REQUEST');
    return new Promise((resolve, reject) => {
      return getCPTypes(token, companyID)
        .then((resp) => {
          if (resp) {
            var cpCategories = resp.data.data;

            cpCategories.map((value) => {
              value.actions = { id: value._id };
              value.dateCreated = moment(new Date(value.dateCreated)).format('YYYY-MM-DD HH:mm:SS');
              return value;
            });

            cpCategories = cpCategories.sort(function (a, b) {
              return new Date(b.dateCreated) - new Date(a.dateCreated);
            });

            commit('SET_CP_TYPES_SUCCESS', cpCategories);
            resolve(cpCategories);
          }
        })
        .catch((err) => {
          commit('GET_CP_TYPES_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_CP_TYPE_BY_ID: ({ commit, dispatch }, typeID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CP_TYPES_REQUEST');
    return new Promise((resolve, reject) => {
      return getTypeByID(token, typeID)
        .then((resp) => {
          if (resp) {
            if (resp.data.data) {
              commit('SET_CP_TYPES_SUCCESS', resp.data.data);
              resolve(resp.data.data);
            } else {
              commit('GET_CP_TYPES_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CP_TYPES_FAILED');
          reject(errors(err));
        });
    });
  },
  CREATE_CP_TYPE: ({ commit, dispatch }, typeData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CP_TYPES_REQUEST');
    return new Promise((resolve, reject) => {
      return createCPType(token, typeData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('LOAD_TYPE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_CP_TYPES_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CP_TYPES_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_CP_TYPE: ({ commit, dispatch }, { formData, typeID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CP_TYPES_REQUEST');
    return new Promise((resolve, reject) => {
      return updateCPType(token, formData, typeID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('LOAD_TYPE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_CP_TYPES_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CP_TYPES_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_CP_TYPE: ({ commit, dispatch }, typeID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CP_TYPES_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteCPType(token, typeID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              resolve(resp);
            } else {
              commit('GET_CP_TYPES_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CP_TYPES_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_CP_UNIONTYPES: ({ commit, dispatch }, companyID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_CP_UNIONTYPE_REQUEST');
    return new Promise((resolve, reject) => {
      return getUnionTypes(token, companyID)
        .then((resp) => {
          if (resp) {
            var cpCategories = resp.data.data;

            cpCategories.map((value) => {
              value.actions = { id: value._id };
              value.dateCreated = moment(new Date(value.dateCreated)).format('YYYY-MM-DD HH:mm:SS');
              return value;
            });

            commit('GET_CP_UNIONTYPE_SUCCESS', cpCategories);
            resolve(cpCategories);
          }
        })
        .catch((err) => {
          commit('GET_CP_UNIONTYPE_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_CP_UNIONTYPE_BY_ID: ({ commit, dispatch }, unionTypeID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CP_UNIONTYPE_REQUEST');
    return new Promise((resolve, reject) => {
      return getUnionTypeByID(token, unionTypeID)
        .then((resp) => {
          if (resp) {
            if (resp.data.data) {
              commit('LOAD_CP_UNIONTYPE_SUCCESS', resp.data.data);
              resolve(resp.data.data);
            } else {
              commit('GET_CP_UNIONTYPE_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CP_UNIONTYPE_FAILED');
          reject(errors(err));
        });
    });
  },
  CREATE_CP_UNIONTYPE: ({ commit, dispatch }, unionTypeData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CP_UNIONTYPE_REQUEST');
    return new Promise((resolve, reject) => {
      return createUnionType(token, unionTypeData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('GET_CP_UNIONTYPE_REQUEST', resp.data);
              resolve(resp);
            } else {
              commit('GET_CP_UNIONTYPE_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CP_UNIONTYPE_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_CP_UNIONTYPE: ({ commit, dispatch }, { unionTypeData, unionTypeID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CP_UNIONTYPE_REQUEST');
    return new Promise((resolve, reject) => {
      return updateUnionType(token, unionTypeData, unionTypeID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('GET_CP_UNIONTYPE_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_CP_UNIONTYPE_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CP_UNIONTYPE_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_CP_UNIONTYPE: ({ commit, dispatch }, unionTypeID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CP_UNIONTYPE_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteUnionType(token, unionTypeID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('GET_CP_UNIONTYPE_REQUEST');
              resolve(resp);
            } else {
              commit('GET_CP_UNIONTYPE_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CP_UNIONTYPE_FAILED');
          reject(errors(err));
        });
    });
  },
};

export default actions;
