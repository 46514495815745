var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-nav',{ref:"navColor",staticClass:"navbar-top border-bottom navbar-expand",class:{
    'default navbar-dark': _vm.type === 'default',
    'bg-vobo default navbar-dark': _vm.backgroundColor === '',
  },style:(`background: ${_vm.backgroundColor} !important`),attrs:{"container-classes":"container-fluid"}},[_c('div',{staticClass:"align-items-left pull-left"},[(_vm.currentUserGroup === 2 && _vm.cp)?_c('li',{staticClass:"nav-item pr-3 d-none d-sm-block"},[_c('div',{staticClass:"cpImageContainer"},[_c('span',{staticClass:"avatar avatar-sm transparent-bg pull-left"},[_c('img',{attrs:{"alt":_vm.cp.name,"src":_vm.cp.image}})]),_c('div',{staticClass:"media-body ml-2 d-lg-block navbar-user pull-left cpNameContainer"},[(_vm.cp)?_c('span',{staticClass:"mb-0 text-sm font-weight-bold",class:{ middleFloat: _vm.cp.name.length <= 25 }},[_vm._v(_vm._s(_vm.cp.name))]):_vm._e()])])]):_c('a',{staticClass:"navbar-brand",attrs:{"href":"/"}},[(_vm.logo.src)?_c('span',[_c('img',{ref:"logo",staticClass:"navbar-brand-img",attrs:{"src":_vm.logo.src,"alt":_vm.logo.title}}),_vm._v(" "+_vm._s(_vm.logo.name))]):(_vm.logo.srcDefault)?_c('span',[_c('img',{staticClass:"navbar-brand-img",attrs:{"src":_vm.logo.srcDefault,"alt":_vm.logo.titleDefault}})]):_c('span',[_vm._v(_vm._s(_vm.logo.name))])])]),_c('ul',{staticClass:"navbar-nav align-items-center ml-md-auto"},[_c('li',{staticClass:"nav-item d-xl-none"},[_c('div',{staticClass:"pr-3 sidenav-toggler",class:{
          active: _vm.$sidebar.showSidebar,
          'sidenav-toggler-dark': _vm.type === 'default',
          'sidenav-toggler-light': _vm.type === 'light',
        },on:{"click":_vm.toggleSidebar}},[_c('div',{staticClass:"sidenav-toggler-inner"},[_c('i',{staticClass:"sidenav-toggler-line"}),_c('i',{staticClass:"sidenav-toggler-line"}),_c('i',{staticClass:"sidenav-toggler-line"})])])])]),_c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item dropdown"},[_c('base-dropdown',{staticClass:"nav-link pr-0",attrs:{"title-tag":"a","has-toggle":false,"menu-on-right":true}},[(!_vm.pageLoading)?_c('div',{staticClass:"media align-items-center",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"avatar avatar-sm rounded-circle"},[_c('img',{attrs:{"alt":"Image placeholder","src":"/img/avatars/user-avatar.png"}})]),_c('div',{staticClass:"media-body ml-2 d-lg-block navbar-user"},[(_vm.firstname && _vm.lastname)?_c('span',{staticClass:"mb-0 text-sm font-weight-bold"},[_vm._v(_vm._s(_vm.firstname)+" "+_vm._s(_vm.lastname))]):_c('span',{staticClass:"mb-0 text-sm font-weight-bold"},[_vm._v(_vm._s(_vm.fullName))]),_c('br'),_c('span',{staticClass:"mb-0 text-sm font-weight-bold"},[_vm._v(_vm._s(_vm.email))])])]):_vm._e(),(_vm.pageLoading)?_c('div',{staticClass:"media align-items-center",attrs:{"slot":"title"},slot:"title"},[_c('LoadingAccountHeader',{staticStyle:{"width":"180px"}})],1):_vm._e(),[_c('div',{staticClass:"dropdown-header noti-title"},[_c('h6',{staticClass:"text-overflow m-0"},[_vm._v("Welcome!")])]),_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/user/profile"}},[_c('i',{staticClass:"ni ni-circle-08"}),_c('span',[_vm._v("My profile")])]),_c('div',{staticClass:"dropdown-divider"}),_c('a',{staticClass:"dropdown-item",on:{"click":_vm.logout}},[_c('i',{staticClass:"fas fa-sign-out-alt"}),_c('span',[_vm._v("Logout")])])]],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }