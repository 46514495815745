const mutations = {
  GET_UNION_LINK_REQUEST: (state) => {
    state.loading = true;
    state.loadedOnce = false;
    state.unionLinkLoadedOnce = false;
    state.success = false;
    state.error = false;
    state.selectedUnionLink = {};
  },
  SET_UNION_LINK_SUCCESS: (state, data) => {
    state.loading = false;
    state.loadedOnce = true;
    state.unionLinkLoadedOnce = true;
    state.success = true;
    state.error = false;
    state.errorUnionLink = false;
    state.unionLinkList = data;
    state.selectedUnionLink = {};
  },
  GET_UNION_LINK_FAILED: (state) => {
    state.loading = false;
    state.unionLinkLoadedOnce = false;
    state.unionLinkList = [];
  },
  GET_UNION_LINK_BY_CP_ID_REQUEST: (state) => {
    state.loading = true;
    state.selectedUnionLinkSuccess = false;
    state.selectedUnionLinkError = false;
  },
  SET_UNION_LINK_BY_ID: (state, data) => {
    state.loading = false;
    state.selectedUnionLinkSuccess = true;
    state.selectedUnionLink = data;
  },
  GET_UNION_LINK_BY_CP_ID_FAILED: (state) => {
    state.loading = false;
    state.selectedUnionLinkError = true;
    state.selectedUnionLink = {};
  },
  UPDATE_UNION_LINK_REQUEST: (state) => {
    state.loading = true;
    state.success = false;
    state.error = false;
  },
  UPDATE_UNION_LINK_SUCCESS: (state) => {
    state.loading = false;
    state.success = true;
    state.error = false;
  },
  UPDATE_UNION_LINK_FAILED: (state) => {
    state.loading = false;
    state.success = false;
    state.error = true;
  },
  GET_LINK_TYPE_BY_CP_ID_REQUEST: (state) => {
    state.linkTypeLoading = true;
    state.linkTypeSuccess = false;
    state.linkTypeError = false;
  },
  SET_LINK_TYPE_BY_CP_ID_SUCCESS: (state, data) => {
    state.linkTypeLoading = false;
    state.linkTypeSuccess = true;
    state.linkTypeError = false;
    state.linkTypes = data;
  },
  SET_LINK_TYPE_BY_CP_ID_FAILED: (state) => {
    state.linkTypeLoading = false;
    state.linkTypeSuccess = false;
    state.linkTypeError = true;
  },
  RESET_DATA: (state) => {
    state.loading = false;
    state.loadedOnce = false;
    state.unionLinkLoadedOnce = false;
    state.success = false;
    state.error = false;
    state.errorUnionLink = false;
    state.unionLinkList = [];
    state.selectedUnionLink = {};
    state.selectedUnionLinkSuccess = false;
    (state.selectedUnionLinkError = false), (state.linkTypeLoading = false);
    state.linkTypeSuccess = false;
    state.linkTypeError = false;
    state.linkTypes = [];
  },
};

export default mutations;
