const mutations = {
  GET_ADMIN_USER_REQUEST: (state) => {
    state.loading = true;
    state.loadedOnce = false;
    state.userloadedOnce = false;
    state.success = false;
    state.error = false;
  },
  SET_ADMIN_USER_SUCCESS: (state, data) => {
    state.loading = false;
    state.loadedOnce = true;
    state.userloadedOnce = true;
    state.success = true;
    state.error = false;
    state.errorUser = false;
    state.userList = data;
    state.selectedUser = {};
  },
  GET_ADMIN_USER_FAILED: (state) => {
    state.loading = false;
    state.userloadedOnce = false;
    state.userList = {};
  },
  GET_ADMIN_USER_BY_ID_REQUEST: (state) => {
    state.loading = true;
    state.selectedUserSuccess = false;
    state.selectedUserError = false;
  },
  SET_ADMIN_USER_BY_ID: (state, data) => {
    state.loading = false;
    state.selectedUserSuccess = true;
    state.selectedUser = data;
  },
  GET_ADMIN_USER_BY_ID_FAILED: (state) => {
    state.loading = false;
    state.selectedUserError = true;
    state.selectedUser = {};
  },
  UPDATE_ADMIN_REQUEST: (state) => {
    state.loading = true;
    state.success = false;
    state.error = false;
  },
  UPDATE_ADMIN_SUCCESS: (state) => {
    state.loading = false;
    state.success = true;
    state.error = false;
  },
  UPDATE_ADMIN_FAILED: (state) => {
    state.loading = false;
    state.success = false;
    state.error = true;
  },
  RESET_DATA: (state) => {
    state.loading = false;
    state.loadedOnce = false;
    state.userloadedOnce = false;
    state.success = false;
    state.error = false;
    state.errorUser = false;
    state.userList = {};
    state.selectedUser = {};
    state.selectedUserSuccess = false;
    state.selectedUserError = false;
  },
};

export default mutations;
