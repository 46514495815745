const mutations = {
  GET_CONTENT_PROVIDER: (state) => {
    state.loading = true;
    state.success = false;
    state.error = false;
  },
  SET_CONTENT_PROVIDER_SUCCESS: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
    state.cpList = data;
  },
  LOAD_CONTENT_PROVIDER_SUCCESS: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
    state.selectedCP = data;
  },
  GET_CONTENT_PROVIDER_FAILED: (state) => {
    state.loading = false;
    state.success = false;
    state.error = true;
  },
  GET_CP_GROUPS_REQUEST: (state) => {
    state.groupLoading = true;
    state.success = false;
    state.error = false;
  },
  SET_CP_GROUPS_SUCCESS: (state, data) => {
    state.groupLoading = false;
    state.success = true;
    state.error = false;
    state.cpGroups = data;
  },
  GET_CP_GROUPS_FAILED: (state) => {
    state.groupLoading = false;
    state.success = false;
    state.error = true;
  },
  LOAD_GROUP_SUCCESS: (state, data) => {
    state.groupLoading = false;
    state.success = true;
    state.error = false;
  },
  GET_CP_CATEGORIES_REQUEST: (state) => {
    state.categoryLoading = true;
    state.success = false;
    state.error = false;
  },
  SET_CP_CATEGORIES_SUCCESS: (state, data) => {
    state.categoryLoading = false;
    state.success = true;
    state.error = false;
    state.cpCategories = data;
  },
  GET_CP_CATEGORIES_FAILED: (state) => {
    state.categoryLoading = false;
    state.success = false;
    state.error = true;
  },
  LOAD_CATEGORY_SUCCESS: (state, data) => {
    state.categoryLoading = false;
    state.success = true;
    state.error = false;
  },
  GET_CP_TYPES_REQUEST: (state) => {
    state.typeLoading = true;
    state.success = false;
    state.error = false;
  },
  SET_CP_TYPES_SUCCESS: (state, data) => {
    state.typeLoading = false;
    state.success = true;
    state.error = false;
    state.cpTypes = data;
  },
  GET_CP_TYPES_FAILED: (state) => {
    state.typeLoading = false;
    state.success = false;
    state.error = true;
  },
  LOAD_TYPE_SUCCESS: (state, data) => {
    state.typeLoading = false;
    state.success = true;
    state.error = false;
  },
  GET_CP_UNIONTYPE_REQUEST: (state) => {
    state.uniontypeLoading = true;
    state.uniontypeSuccess = false;
    state.uniontypeError = false;
  },
  GET_CP_UNIONTYPE_SUCCESS: (state, data) => {
    state.uniontypeLoading = false;
    state.uniontypeSuccess = true;
    state.uniontypeError = false;
    state.unionTypes = data;
  },
  GET_CP_UNIONTYPE_FAILED: (state) => {
    state.uniontypeLoading = false;
    state.uniontypeSuccess = false;
    state.uniontypeError = true;
  },
  LOAD_CP_UNIONTYPE_SUCCESS: (state, data) => {
    state.uniontypeLoading = false;
    state.uniontypeSuccess = true;
    state.uniontypeError = false;
    state.unionType = data;
  },
  SET_DEFAULT_COMPANY_CHANNEL: (state, data) => {
    state.defaultCompany = data;
  },
  RESET_DATA: (state) => {
    state.loading = false;
    state.success = false;
    state.error = false;
    state.errorUser = false;
    state.cpList = {};
    state.categoryLoading = false;
    state.cpCategories = [];
    state.cpTypes = [];
    state.cpGroups = [];
    state.uniontypeLoading = false;
    state.uniontypeSuccess = false;
    state.uniontypeError = false;
    state.unionTypes = [];
    state.selectedCP = {};
    state.uniontypeLoading = false;
    state.uniontypeSuccess = false;
    state.uniontypeError = false;
    state.defaultCompany = {};
  },
};

export default mutations;
