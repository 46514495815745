import Vue from 'vue';
import config from 'config';
import { decoder } from '@/utils/sessions/jwt';
import * as Cookie from 'js-cookie';
import Cookies from 'js-cookie-remove-all';
import errors from '@/utils/errors';
import { getUser, destroySession, getDashboard, getUserProfile, validateUser, validateEmail } from '@/api/request/user';
import { globalStore } from '@/utils/global';

Vue.use(Cookie);

const actions = {
  AUTH_REQUEST: ({ commit, dispatch }, user) => {
    // console.log(user)
    return new Promise((resolve, reject) => {
      commit('AUTH_REQUEST');

      return getUser(user)
        .then(async (resp) => {
          if (resp.data.token) {
            const decode = decoder({ secret: config.secret });

            var payload = decode(resp.data.token);
            // resp.data.isFirstTimeLogin = Number(payload.isFirstTimeLogin)*/
            // console.log(payload)
            Cookie.set('user-token', resp.data.token);
            Cookie.set('menuColor', payload.menuColor || '');
            Cookie.set('brandUrl', payload.brandUrl || '');
            Cookie.set('adminColor', payload.adminColor || '');
            // Cookie.set('firstTime-login', resp.data.isFirstTimeLogin)

            commit('AUTH_SUCCESS', resp.data);
            //commit('dashboard/USER_RESET_STATE_DATA', {}, {root: true})
            // commit('dashboard/USER_RESET_STATE_DATA', {})
            commit('user/USER_RESET_STATE_DATA', {}, { root: true });
            dispatch('user/USER_PROFILE_REQUEST', resp.data.token, { root: true });
            dispatch('user/GET_USER_DASHBOARD', false, { root: true });
            commit('user/USER_UPDATE_CP', payload.providerID, { root: true });
            commit('company/SET_SELECTED_COMPANY', payload.companyID, { root: true });

            globalStore.updateToken();
            resolve(resp.data);
          }
        })
        .catch((err) => {
          Cookies.removeAll();
          commit('AUTH_ERROR', err);
          if (err.response === undefined) {
            reject(errors(err));
          } else {
            if (err.response.data.error === 'account_not_verified') {
              var isEmail = Vue.options.filters.validateEmail(user.email);
              if (!isEmail) {
                user.email = err.response.data.token.email;
              }
              commit('AUTH_USER_NOT_VERIFIED', user);
              reject(err.response.data.error);
            } else if (err.response.data.error === 'account_deactivated') {
              var hasEmail = Vue.options.filters.validateEmail(user.email);
              if (!hasEmail) {
                user.email = err.response.data.token.email;
              }
              commit('AUTH_USER_DEACTIVATED', user);
              reject(err.response.data.error);
            } else {
              reject(errors(err));
            }
          }
        });
    });
  },
  AUTH_LOGOUT: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      // console.log(globalStore.$data.token)
      // return destroySession(globalStore.$data.token)
      const browserWarning = Cookies.get('browser-warning');
      globalStore.resetToken();
      // commit('dashboard/USER_RESET_STATE_DATA', {}, {root: true})
      commit('user/RESET_PROFILE_STATE_DATA', {}, { root: true });
      commit('user/USER_RESET_STATE_DATA', {}, { root: true });
      commit('users/RESET_DATA', {}, { root: true });
      commit('admin/RESET_DATA', {}, { root: true });
      commit('company/RESET_DATA', {}, { root: true });
      commit('contentProvider/RESET_DATA', {}, { root: true });
      commit('messages/RESET_DATA', {}, { root: true });
      commit('packages/RESET_DATA', {}, { root: true });
      commit('roles/RESET_DATA', {}, { root: true });
      commit('settings/RESET_DATA', {}, { root: true });
      commit('unionLinks/RESET_DATA', {}, { root: true });
      commit('waveManagement/RESET_DATA', {}, { root: true });
      commit('RESET_DATA');
      commit('AUTH_LOGOUT');
      Cookies.removeAll();
      Cookies.set('browser-warning', browserWarning, { expires: 3 });
      resolve();
    });
  },
};

export default actions;
