import Vue from 'vue';
import { getPricePlanList, createPricePlan, deletePricePlan, updatePricePlan, getPricePlanById } from '@/api/request/pricePlans';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';

Vue.use(Cookies);

const actions = {
  GET_PRICE_PLAN_BY_ID: ({ commit }, id) => {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      commit('PRICE_PLAN_REQUEST');
      return getPricePlanById(token, id)
        .then(async (result) => {
          if (result) {
            commit('SET_PRICE_PLAN', result.data);
            resolve(result.data);
          }
        })
        .catch((err) => {
          commit('PRICE_PLAN_ERROR', err);
          reject(errors(err));
        });
    });
  },
  GET_PRICE_PLAN_LIST: ({ commit }, company_id) => {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      commit('PRICE_PLAN_LIST_REQUEST');
      return getPricePlanList(token, company_id)
        .then(async (result) => {
          if (result) {
            commit('SET_PRICE_PLAN_LIST', result.data);
            resolve(result.data);
          }
        })
        .catch((err) => {
          commit('PRICE_PLAN_LIST_ERROR', err);
          reject(errors(err));
        });
    });
  },
  CREATE_PRICE_PLAN: ({ commit }, data) => {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      commit('PRICE_PLAN_LIST_REQUEST');
      return createPricePlan(token, data)
        .then((result) => {
          if (result) {
            resolve(result);
          }
        })
        .catch((err) => {
          commit('PRICE_PLAN_LIST_ERROR', err);
          reject(errors(err));
        });
    });
  },
  DELETE_PRICE_PLAN: ({ commit }, companyId) => {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      commit('PRICE_PLAN_LIST_REQUEST');
      return deletePricePlan(token, companyId)
        .then((result) => {
          if (result) {
            resolve(result);
          }
        })
        .catch((err) => {
          commit('PRICE_PLAN_LIST_ERROR', err);
          reject(errors(err));
        });
    });
  },
  UPDATE_PRICE_PLAN: ({ commit }, { plan_id, data }) => {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      commit('PRICE_PLAN_LIST_REQUEST');
      return updatePricePlan(token, plan_id, data)
        .then((result) => {
          if (result) {
            resolve(result);
          }
        })
        .catch((err) => {
          commit('PRICE_PLAN_LIST_ERROR', err);
          reject(errors(err));
        });
    });
  },
};

export default actions;
